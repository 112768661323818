<template>
    <div class="address" :style="{'min-height': minHeight + 'px'}">
        <div class="address-module">
            <div>
                <el-button @click="addBtn">新增</el-button>
            </div>
            
                <el-table class="xh-table" :data="tableData" style="width: 100%" @row-click="rowClick">
                    <el-table-column prop="consigneeName" label="收货人" width="140"></el-table-column>
                    <el-table-column prop="province" label="省" width="120"></el-table-column>
                    <el-table-column prop="city" label="市" width="120"></el-table-column>
                    <el-table-column prop="area" label="区" width="120"></el-table-column>
                    <el-table-column prop="detailAddress" label="详细地址"></el-table-column>
                    <el-table-column prop="consigneeMobile" label="手机号">
                        <template slot-scope="scoped">
                            <div>{{scoped.row.consigneeMobile}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="defaulted" label="是否默认地址">
                        <template slot-scope="scoped">
                            <div>{{scoped.row.defaulted == 1 ? '是':'否'}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="action" label="操作" width="260">
                        <template slot-scope="scoped">
                            <el-button type="primary" plain size="small" @click.stop="editAddress(scoped.row.id)">修改</el-button>
                            <el-button type="primary" plain size="small" @click.stop="delAddress(scoped.row.id, scoped.row.defaulted)">删除</el-button>
                            <el-button v-if="scoped.row.defaulted == 1" type="danger" plain size="small" disabled>默认地址</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            <add-address ref="address" :visible.sync="visibel" @closeq="closeq" @openq="openq" :id="id"></add-address>
        </div>
    </div>
</template>

<script>
import { getAddressList,delAddr } from '@/api'
import AddAddress from './addAddress.vue'
export default {
    components:{AddAddress},
    data(){
        return{
            tableData:[],
            visibel:false,
            id:'',
            memberId:'',
            entryType:'',
            minHeight:''
        }
    },
    created(){
        this.minHeight = this.$store.state.minHeight || ''
        this.memberId = this.$store.state.memberId
        this.getAddressList()
        
    },
    mounted(){
       this.entryType = this.$route.query.type || '' 
    },
    methods:{
        chooseBtn(){

        },
        closeq(){
            this.visibel = false
        },
        openq(){
            this.visibel = true
        },
        getAddressList() {
            let that = this;
            getAddressList(this.memberId).then(res => {
                console.log(res)
                that.tableData = res.data
            }).catch(exp => {
                console.log("异常信息" + exp.msg)
            })
        },
        addBtn(){
            this.id = ''
            this.openq()
        },
        editAddress(id){
            this.id = id
            this.visibel = true
        },
        setDefault(id){
            let that = this;
            // setDefault({
            //     id: id
            // }).then(res => {
            //     if(res.code == 0){
            //         this.$message.success('修改成功')
            //         this.getAddressList()
            //     }
            // }).catch(exp => {
            //     console.log("异常信息" + exp.msg)
            // })
        },
        delAddress(id, defaulted){
            if(defaulted == 1){
                this.$notify({
                    title: '警告',
                    message: '不能删除默认地址',
                    type: 'warning',
                    offset: 100,
                    duration: 2600
                });
            }else{
                this.$confirm('确认删除地址？','提示',{
                    type: 'warning'
                })
                .then(async (response) => {
                    // console.log(response,'kkkkkkk')
                    this.delAddr(id)
                })
                .catch(_ => {});
            }
        },
        delAddr(id){
            delAddr(id).then(res=>{
                if(res.code === '00000'){
                    this.$message.success('删除成功')
                    this.getAddressList()
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        rowClick(row){
            this.$store.commit('setChooseAddr',row)
            this.$router.go(-1); 
        }
    }
}
</script>

<style lang="less" scoped>
.address{
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    &-module{
        max-width: 1190px;
        margin:20px auto 0;
        .xh-table{
            margin-top: 20px;
            /deep/ th {
                background-color: #eee;
                color: #333;
            }
        }
    }
}

</style>