<template>
    <el-dialog
        title="申请开票"
        :visible.sync="dialogVisible"
        width="30%"
        @close="cancel">
        <div class="content">
            <div class="content-reason">
                <span class="content-reason-tit">发票抬头：</span>
                <div>{{ companyName || '' }}</div>
            </div>
            <div class="content-reason">
                <span class="content-reason-tit" >税号：</span>
                <div>{{ taxNumber || '' }}</div>
            </div>
            
            <div class="content-reason">
                <span class="content-reason-tit" >开票金额：</span>
                <div>{{ payAmount | currency }}</div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleOrderRefundApply">确 定</el-button>
        </span>
    </el-dialog>

</template>

<script>

import {invoiceApply} from '@/api'
import { localStorage } from '@/utils/storage';

export default {
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        payAmount:{
            type:String,
            default:'0'
        },
        id:{
            type:String,
            default:''
        }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit('update:visible', val);
            },
        },
    },
    data(){
        return{
            companyName: '',
            taxNumber:''
        }
    },
    mounted(){
      this.companyName = localStorage.get('user').companyName || ''
      this.taxNumber = localStorage.get('user').taxNumber || ''
    },
    methods:{
        handleOrderRefundApply() {
            
            let params = {
					orderId: this.id,
				}
            console.log('pppppppppppp',params)
            invoiceApply(params).then(res => {
               if(res.code == '00000'){
                    this.$message.success('操作成功')
                    this.cancel()
                    this.$emit('refreshBtn')
               }else{
                    this.$message.error(res.msg)
               }
            })

        },
        
        cancel(){
            this.dialogVisible = false
        }
    },
    filters:{
        currency(value){
            if(value){
                return value / 100
            }else return '0.00'
        },
    }
}
</script>

<style lang="less" scoped>
.content{
    padding: 0 20px;
    .content-reason{
        margin: 16px 0;
        display: flex;
        
        &-tit{
            margin-right: 20px;
            width: 100px;
        }
    }
    
}
</style>