<template>
    <div class="register">
        <div class="head">
            <img :src="imgUrl" class="img-logo"/>
        </div>
        <!-- 注册步骤 -->
        <div class="step-style">
            <el-steps :active="active" simple class="step-style-top" finish-status="success" process-status="finish">
                <el-step title="基本信息" icon="el-icon-edit"></el-step>
                <!-- <el-step title="资质认证" icon="el-icon-tickets"></el-step> -->
                <el-step title="注册成功" icon="el-icon-finished"></el-step>
            </el-steps>

            <div class="step-content">
                <!-- 基本信息 -->
                <template v-if="active == 0">
                    <el-form :model="form" :rules="rules" ref="formInfo" label-width="80px" class="step-content-form">
                        
                        <el-form-item label="用户昵称" prop="nickName">
                            <el-input v-model="form.nickName" placeholder="昵称"></el-input>
                        </el-form-item>
                        <el-form-item label="用户性别" prop="gender">
                            <el-radio-group v-model="form.gender">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="手机号" prop="mobile">
                                <el-input v-model="form.mobile" placeholder="手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="code">
                            <div style="display: flex;justify-content: space-between;">
                                <el-input style="width: 220px;" v-model="form.code" placeholder="验证码"></el-input>
                                <el-button  size="mini" type="primary" @click="smsCode">{{isSend ? `${this.titValue==0 ? '':this.titValue} 重新获取`:'获取验证码'}}</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="btn"  type="primary" @click="registerBtn">立即注册</el-button>
                        </el-form-item>
                    </el-form>
                </template>
                
                <!-- 资质认证 -->
                <!-- <template v-if="active === 1">
                    <el-form ref="formZz" :model="formZz" :rules="rulesZz" label-width="280px" class="step-content-form1" >
                        <el-form-item label="用户类型">
                            <el-radio-group v-model="formZz.userType">
                                <el-radio v-for="(item,index) in typeOptions" :key="index" :label="item.id">{{ item.typeLabel }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="身份证信息" prop="reverseUrl" class="form-module-item">
                            <Rboy-upload-sfz :obverse-url="formZz.obverseUrl" :reverse-url="formZz.reverseUrl" @selectChange="sfz_chagne" @del="del_btn"></Rboy-upload-sfz>
                        </el-form-item>
                        <el-form-item label="" prop="idCard" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.idCard" autocomplete="off" placeholder="身份证号"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="personName" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.personName"  placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="personPhone">
                            <el-input class="info-style" v-model="formZz.personPhone"  placeholder="手机号"></el-input>
                        </el-form-item>

                        <el-form-item label="医疗机构执业许可证或中医诊所备案证书" v-if="formZz.userType === 1" prop="yljgzyxkzUrl">
                            <el-upload
                                action="#"
                                list-type="picture-card"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img :src="formZz.yljgzyxkzUrl" v-if="formZz.yljgzyxkzUrl" class="avatar"/>
                                <i v-else class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="药品经营许可证" v-if="formZz.userType === 2 || formZz.userType === 3" prop="ypjyxkzUrl">
                            <el-upload
                                action="#"
                                list-type="picture-card"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload1">
                                <img :src="formZz.ypjyxkzUrl" v-if="formZz.ypjyxkzUrl" class="avatar"/>
                                <i v-else class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="营业执照" v-if="formZz.userType === 2 || formZz.userType === 3" prop="businessCardUrl">
                            <el-upload
                                action="#"
                                list-type="picture-card"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload2">
                                <img :src="formZz.businessCardUrl" v-if="formZz.businessCardUrl" class="avatar"/>
                                <i v-else class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                       
                       
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit" style="width: 100%;">提交</el-button>
                        </el-form-item>
                        
                    </el-form>
                </template> -->

                <!-- 注册成功 -->
                <template v-if="active === 1">
                    <div class="success-style">
                        <div class="el-icon-success success-icon"></div>
                        <div>注册成功，去<span class="login-btns" @click="goLogin"> 登录 </span> </div>
                    </div>
                </template>
            </div>
            
            <!-- <el-button style="margin-top: 12px;" @click="next">下一步</el-button> -->
        </div>
        <!-- 寻回信息 -->
        <!-- <div class="step-style">
            <el-steps :active="1" simple>
                <el-step title="身份认证" icon="el-icon-edit"></el-step>
                <el-step title="密码重置" icon="el-icon-upload"></el-step>
                <el-step title="成功" icon="el-icon-finished"></el-step>
            </el-steps>
        </div> -->
    </div>
</template>

<script>
import RboyUploadSfz from "@/components/Rboy-upload-sfz/Rboy-upload-sfz.vue"
import {updatePic,registerApi,smsCode,uploadQualified,getAuditStatus,getQualified} from '@/api'
import { localStorage } from '@/utils/storage';
export default{
    components:{ RboyUploadSfz },
    data() {
        var validatePass1 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
            // if (this.ruleForm.checkPass !== '') {
            //     this.$refs.ruleForm.validateField('checkPass');
            // }
                callback();
            }
        };
        return {
            imgUrl: require('@/assets/logo_n.png'),
            active: 0,
            typeOptions:[
                // {
                //     id:0,
                //     typeLabel: '未知'
                // },
                {
                    id:1,
                    typeLabel: '医疗机构'
                },
                {
                    id:2,
                    typeLabel: '中医馆'
                },
                {
                    id:3,
                    typeLabel: '批发零售'
                }
            ],
            form:{
                nickName:'',
                avatarUrl:'',
                code:'',
                mobile:'',
                gender:1,//1男，2女
            },
            rules: {
                nickName:[
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                ],
                avatarUrl:[
                    { required: true, message: '请上传头像', trigger: 'blur' },
                ],
                code:[
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
                mobile:[
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                ]
            },
            // formZz:{
            //     userType:1,
            //     // 正面
            //     obverseUrl: "",
            //     // 反面
            //     reverseUrl: "",
            //     yljgzyxkzUrl:'',
            //     ypjyxkzUrl:'',
            //     businessCardUrl:'',
            //     idCard:'',
            //     personName:'',
            //     personPhone:'',
            // },
            // rulesZz:{
            //     reverseUrl:[
            //         { required: true, message: '请上传身份证正反面', trigger: 'blur' },
            //     ],
            //     yljgzyxkzUrl:[
            //         { required: true, message: '请上传医疗机构执业许可证或中医诊所备案证书', trigger: 'blur' },
            //     ],
            //     idCard:[
            //         { required: true, message: '请输入身份证号', trigger: 'blur' },
            //     ],
            //     personName:[
            //         { required: true, message: '请输入姓名', trigger: 'blur' },
            //     ],
            //     personPhone:[
            //         { required: true, message: '请输入手机号', trigger: 'blur' },
            //     ],
            //     ypjyxkzUrl:[
            //         { required: true, message: '请上传药品经营许可证', trigger: 'blur' },
            //     ],
            //     businessCardUrl:[
            //         { required: true, message: '请上传营业执照', trigger: 'blur' },
            //     ]
            // },
          
            urlImg:'',
            // codeToTit:{
            //     1:'资质认证审核中，',
            //     2:'资质认证审核通过，',
            //     3:'资质认证审核拒绝，',
            // },
            // codeValue:''
            titValue:'',
            sid:null,
            isSend:false
        }
    },
    mounted(){
        // if(localStorage.get('userInfo') || localStorage.get('userInfo').memberId){
        //     this.active = 1
        //     this.formZz.personPhone = localStorage.get('userInfo').username
        //     this.getAuditStatus()
        // }
       
    },
    methods:{
        // 倒计时
			countDownNum(){
                this.titValue = 60;
                if(this.titValue == 60){
                    this.sid = setInterval(()=>{
                        this.titValue--;
                        if(this.titValue == 0){
                            this.isSend = false
                            // this.titValue = '重新获取';
                            clearInterval(this.sid);
                            console.log(this.titValue)
                        }
                        console.log(this.titValue,'this.titValue')
                    },1000)
                }
			},
        // 判断资质是否提交或审核通过
        // getAuditStatus(){
        //     getAuditStatus().then(res=>{
        //         if(res.code === '00000'){
        //             //审核状态 0注册成功1审核中2审核通过3审核拒绝
        //             if(res.data == 1 || res.data == 2 || res.data == 3){
        //                 this.active = 3
        //                 this.codeValue = res.data
        //             }else if(res.data == 0){
        //                 this.active = 3
        //             }
        //         }
        //     })
        // },
        // 获取资质信息
        // getQualified(){
        //     getQualified().then(res=>{
                
        //     })
        // },
        // handleAvatarSuccess(type){
        //     this.formZz[type] = this.urlImg
        // },
        // beforeAvatarUpload(file){
        //     const obj = new FormData();
        //     obj.append("file",file)
        //     this.updatePic(obj,'yljgzyxkzUrl')
        // },
        // beforeAvatarUpload2(file){
        //     const obj = new FormData();
        //     obj.append("file",file)
        //     this.updatePic(obj,'ypjyxkzUrl')
        // },
        // beforeAvatarUpload3(file){
        //     const obj = new FormData();
        //     obj.append("file",file)
        //     this.updatePic(obj,'businessCardUrl')
        // },
        registerBtn(){
            this.$refs.formInfo.validate((valid) => {
                if (valid) {
                    this.registerApi()
                    
                } else {
                    return false;
                }
            });
        },
        registerApi(){
            let params = {
                nickName:this.form.nickName,
                avatarUrl:this.form.avatarUrl,
                code:this.form.code,
                mobile:this.form.mobile,
                gender:this.form.gender,
            }
            registerApi(params).then(res=>{
                if(res.code === '00000'){
                    // const { access_token, token_type } = res.data;
                    // const accessToken = token_type + ' ' + access_token;
                    // localStorage.set('token', accessToken);
                    // localStorage.set('userInfo', res.data);
                    // this.next()
                    this.active = 1
                }else{
                    this.$message.warning(res.message)
                }
            })
        },
        // updatePic(obj,type){
        //     updatePic(obj).then(res=>{
        //         if(res.code === '00000'){
        //             this.formZz[type] = res.data
        //         }
        //     })
        // },
        // 获取验证码
        smsCode(){
            if(!this.form.mobile){
                return
            }
            if(this.isSend){
                this.$message.warning('请稍后再试!')
                return
            }
            this.isSend = true
            smsCode({
                phoneNumber: this.form.mobile
            }).then(res=>{
                if(res.code === '00000'){
                    this.countDownNum()
                }
            })
        },
        // handleRemove(file, fileList) {
        //     console.log(file, fileList);
        // },
        // handlePictureCardPreview(file) {
        //     console.log(file,'pppppppppp')
        //     this.dialogImageUrl = file.url;
        //     this.dialogVisible = true;
        // },
        // next(){
        //     if(this.active < 2){
        //         if(this.active == 1)
        //             this.active += 1
        //         else
        //           this.active ++
        //     }
        // },
        // // 身份证
        // // 上传
        // sfz_chagne(e) {
        //     this.formZz[e.type] = e.url
        // },
        // // 删除
        // del_btn(e) {
        //     this.formZz[e.name] = ""
        // },
        // onSubmit(){
        //     this.$refs.formZz.validate((valid) => {
        //         if (valid) {
        //             this.uploadQualified()
        //         } else {
        //             return false;
        //         }
        //     });
           
        // },
        goLogin(){
            this.$router.push({
                path:'/login'
            })
        },
        // uploadQualified(){
        //     let params = {
        //         userType:this.formZz.userType,
        //         personName:this.formZz.personName,
        //         personPhone:this.formZz.personPhone,
        //         obverseUrl:this.formZz.obverseUrl,
        //         reverseUrl:this.formZz.reverseUrl,
        //         idCard: this.formZz.idCard
        //     }
        //     if(this.formZz.userType == 1){
        //         params['yljgzyxkzUrl'] = this.formZz.yljgzyxkzUrl
        //     }else if(this.formZz.userType == 2 || this.formZz.userType == 3){
        //         params['ypjyxkzUrl'] = this.formZz.ypjyxkzUrl
        //         params['businessCardUrl'] = this.formZz.businessCardUrl
        //     }
        //     uploadQualified(params).then(res=>{
        //         if(res.code === '00000'){
        //             this.next()
        //             this.getAuditStatus()
        //         }
        //     })
        // }
    }
}

</script>

<style lang="less" scoped>
.register{
    .head{
        width: 1200px;
        margin: auto;
        padding: 40px 0;
    }
    .img-logo{
        width: 160px;
    }
    .step-style{
        min-height: 650px;
        width: 1200px;
        margin: auto;
        margin-top: 20px;
        border-radius: 4px;
        box-shadow: 0 1px 20px 0 #d4d4d4;
        background-color: #fff;
        margin-bottom: 100px;
        &-top{
            // width: 800px;
            margin: auto;
            padding: 50px 320px;
            border-bottom: 1px dashed #d4d4d4;
        }
        .step-content {
           
            margin: auto;
            padding: 30px 50px 50px 0;
            &-form{
                width: 400px;
                margin: auto;
            }
            &-form1{
                width: 700px;
                margin: auto;
                padding-right: 100px;
            }
        }
        .btn{
            width: 100%;
            margin-top: 200px;
        }
    }
    /deep/ .el-steps--simple{
        background-color: #fff;
    }
    .info-style{
        margin-top: 10px;
    }
    .success-style{
        text-align: center;
        .success-icon{
            font-size: 130px;
            color: #67C23A;
            margin: 50px 0 30px;
            
        }
        .login-btns{
            color: #fa436a;
            cursor: pointer;
            border-bottom: 1px solid #fa436a;
            font-size: 15px;
        }
    }
    .avatar{
        width: 146px;
        height: 146px;
        border-radius: 6px;
    }

    .form-module-item{
        margin-bottom: 6px;
    }
   
}
</style>