<template>
    <div class="order" :style="{'min-height': minHeight + 'px'}" >
        <div class="header">
            <el-page-header class="page-header" @back="handleClick" content="订单详情"></el-page-header>
        </div>
        
        <div class="order-content">
            <goods-info class="order-content-detail" :isOperate="true" :goodsInfo="goodsList" title="">
                <template v-slot="scope" v-if="dataOptions.status == 901 || dataOptions.status == 501 || dataOptions.status == 502">
                    <div v-if="scope.item.commentStatus == 1" style="color: #9E9E9E;" >已评价</div>
                    <el-button type="text" v-else @click="goPj(scope.item)">评价</el-button>
                </template>
            </goods-info>
            <div class="box">
                <div class="box-card">
                    <div class="info">
                        <div class="address-info folat-between">
                            <div class="row-left" style="width: 50%">收获信息:</div>
                            <div class="row-right font-val">
                                {{ dataOptions.orderDeliveryDto.receiverName }},{{ dataOptions.orderDeliveryDto.receiverPhone }},{{ dataOptions.orderDeliveryDto.receiverProvince }}{{ dataOptions.orderDeliveryDto.receiverCity }}{{ dataOptions.orderDeliveryDto.receiverRegion }}{{ dataOptions.orderDeliveryDto.receiverDetailAddress }}
                            </div>
                        </div>

                        <div class="address-info folat-between">
                            <div class="row-left">订单编号:</div>
                            <div class="row-right font-val">{{dataOptions.orderSn}}</div>
                        </div>

                        <div class="address-info folat-between" v-if="dataOptions.status ==3">
                            <div class="row-left">支付交易号:</div>
                            <div class="row-right font-val">{{dataOptions.transactionId}}</div>
                        </div>

                        <div class="address-info folat-between">
                            <div class="row-left">下单时间:</div>
                            <div class="row-right font-val">{{dataOptions.createTime}}</div>
                        </div>

                        <div class="address-info folat-between">
                            <div class="row-left">付款时间:</div>
                            <div class="row-right font-val">{{dataOptions.createTime}}</div>
                        </div>

                        <div class="address-info folat-between"
                            v-if="dataOptions.status == '401' || dataOptions.status == '501' || dataOptions.status == '502' || dataOptions.status == '601' || dataOptions.status == '602' || dataOptions.status == '901'">
                            <div class="row-left">发货时间:</div>
                            <div class="row-right font-val">{{dataOptions.deliveryTime}}</div>
                        </div>

                        <div class="address-info folat-between"
                            v-if="dataOptions.status == '202' || dataOptions.status == '203' || dataOptions.status == '205'">
                            <div class="row-left">申请退款时间:</div>
                            <div class="row-right font-val">{{dataOptions.refundBeginTime}}</div>
                        </div>


                        <div class="address-info folat-between" v-if=" dataOptions.status == '203'">
                            <div class="row-left">退款时间:</div>
                            <div class="row-right font-val">{{dataOptions.refundEndTime}}</div>
                        </div>
                    </div>
                    <div class="info-price">
                        <div class="info-price-total">
                            <div class="info-price-total-title ">商品总价</div>
                            <div class="info-price-total-val " style="color:#333">￥{{ dataOptions.totalAmount  | currency }}</div>
                        </div>
                        <div class="info-price-total">
                            <div class="info-price-total-title ">订单状态</div>
                            <div class="info-price-total-val " style="color: #fa436a;">
                                {{ dataOptions.status | orderStatusMapFilter }}</div>
                        </div>
                        <div class="info-price-total">
                            <div class="info-price-total-title ">运费</div>
                            <div class="info-price-total-val " style="color:#333">￥20.00</div>
                        </div>

                        <div class="info-price-total"
                            v-if="dataOptions.status == '202' || dataOptions.status == '203' || dataOptions.status == '204' || dataOptions.status == '205' || dataOptions.status == '206'">
                            <div class="info-price-total-title ">退款原因</div>
                            <div class="info-price-total-val ">
                                <span>{{ dataOptions.refundReason }} </span>
                            </div>
                        </div>

                        <div class="info-price-total"
                            v-if="dataOptions.status == '202' || dataOptions.status == '203' || dataOptions.status == '204' || dataOptions.status == '205' || dataOptions.status == '206'">
                            <div class="info-price-total-title ">退款原因备注</div>
                            <div class="info-price-total-val ">
                                <span>{{ dataOptions.refundExplain }} </span>
                            </div>
                        </div>

                        <div class="info-price-total" v-if="dataOptions.status == '206'">
                            <div class="info-price-total-title ">拒绝退款原因</div>
                            <div class="info-price-total-val ">
                                <span>{{ dataOptions.note }} </span>
                            </div>
                        </div>

                        <div class="info-price-total">
                            <div class="info-price-total-title ">支付方式</div>
                            <div class="info-price-total-val ">
                                <span>{{ dataOptions.payType | payTypeFilter }} </span>
                            </div>
                        </div>
                        <div class="info-price-total">
                            <div class="info-price-total-title ">实付款</div>
                            <div class="info-price-total-val " style="color: #fa436a;">
                                ￥{{ dataOptions.payAmount  | currency }}</div>
                        </div>
                    </div>
                </div>
                <div class="bottom-but">
                    <div class="but">
                        <div v-if="dataOptions.status == '401' || dataOptions.status == '501' || dataOptions.status == '502' || dataOptions.status == '601' || dataOptions.status == '602' || dataOptions.status == '603' || dataOptions.status == '901'"><el-button class="action-btn" round @click="logisticsNavigateTo()">查看物流</el-button></div>
                        <div v-if="dataOptions.status == '101'"><el-button class="action-btn " round 
                                @click="doPay(dataOptions)">立即支付</el-button></div>
                        <div v-if="dataOptions.status == '101'"><el-button class="action-btn" round 
                                @click="cancelOrder(dataOptions)">取消订单</el-button></div>
                        <div v-if="dataOptions.status == '201' || dataOptions.status == '206' || dataOptions.status == '301' || dataOptions.status == '401'">
                            <el-button class="action-btn" round @click="refund(dataOptions)">申请退款</el-button></div>
                        <div v-if="dataOptions.status == '202'"><el-button class="action-btn " round 
                                @click="refund(dataOptions)">修改退货</el-button></div>
                        <div v-if="dataOptions.status == '204'"><el-button class="action-btn " round 
                                @click="returnGoods(dataOptions.id)">我要退货</el-button></div>
                        <div v-if="dataOptions.status == '501' || dataOptions.status == '501'"><el-button class="action-btn " round 
                                @click="invoice(dataOptions)">申请开票</el-button></div>
                        <div v-if="dataOptions.status == '401' "><el-button class="action-btn " round @click="sureSh(dataOptions)">确认收货</el-button></div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- 物流信息 -->
        <logistics :visible.sync="visible" :id="orderId"></logistics>
        <!-- 申请退款 -->
        <order-refund :visible.sync="visibleRefund" @refreshBtn="refreshBtn" :dataValue="dataOptions.orderItems" :payAmount="dataOptions.payAmount" :id="dataOptions.id"></order-refund>
        <!-- 申请开票 -->
        <invoice :visible.sync="visibleInvoice" @refreshBtn="refreshBtn" :payAmount="dataOptions.payAmount" :id="dataOptions.id"></invoice>
        <!-- 新增评价 -->
        <addPj :visible.sync="visiblePj" :spuId="spuId" :orderSn="dataOptions.orderSn" @refreshBtn="refreshBtn"></addPj>
        <!-- visible -->
    </div>
</template>

<script>
import { cancelOrder,getOrderDetail,orderStatus } from '@/api'
import goodsInfo from '../confirmOrder/components/goodsInfo.vue'
import Logistics from './components/logistics.vue'
import OrderRefund from './components/orderRefund.vue'
import Invoice from './components/invoice.vue'
import addPj from './components/addPj.vue'
    const payTypes = {
        0: '待付款',
        1: '微信支付',
        2: '支付宝',
        3: '余额',
        4: '微信app'
    }
    const orderStatusMap = {
        101: '待付款',
        102: '订单关闭',
        103: '系统取消',
        201: '已付款',
        202: '申请退款',
        203: '已退款',
        204: '待退货',
        205: '待退款',
        206: '拒绝退款',
        301: '待发货',
        401: '已发货',
        501: '已收货',
        502: '系统收货',
        601: '申请开票',
        602: '开票-未寄出',
        603: '开票-已寄出',
        901: '已完成'
    }
export default {
    components:{goodsInfo,Logistics,OrderRefund,Invoice,addPj},
    data(){
        return{
            keyword:"",
            activeName: '0',
            goodsList:[],
            dataOptions:{
                orderDeliveryDto:{},
                orderItems:[]
            },
            currentId: 0,
            totalamount: 0,
            minHeight:'',
            orderId:'',
            visible:false,
            visibleRefund:false,
            visibleInvoice:false,
            visiblePj:false,
            spuId:'',
        }
    },
    created() {
        this.minHeight = this.$store.state.minHeight || ''

        // this.goodsList = this.$route.params.data.orderItems || [];
        this.orderId = this.$route.query.id || ''
        console.log(this.orderId,'this.dataOptions',this.$route)
        // this.currentId = this.$route.query.id;
        this.getOrderDetail()
    },
    methods:{
        refreshBtn(){
            this.getOrderDetail()
        },
        // 获取订单详情
        getOrderDetail(){
            getOrderDetail(this.orderId).then(res=>{
                if(res.code === '00000'){
                    this.dataOptions = res.data
                    this.goodsList = res.data.orderItems
                }
            })
        },
        // 查看物流
        logisticsNavigateTo(){
            this.visible = true
        },
        // 立即支付
        doPay(){
            this.$router.replace({
                path: '/pay?orderSn=' + this.dataOptions.orderSn
            })
        },
        // 取消订单
        cancelOrder(){
            this.$confirm('确定取消该订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.cancelOrderApi()
            }).catch((e) => {
                this.$message.info('已取消删除')
            })
            
        },
        // 取消订单api
        cancelOrderApi(){
            let id = this.dataOptions ? this.dataOptions.id : ''
            cancelOrder(id).then(res=>{
                if(res.code === '00000'){
                    this.$message.success('操作成功')
                    setTimeout(()=>{
                        this.$router.go(-1);
                    },1000)
                }else{
                    this.$message.error('操作失败')
                }
            })
        },
        // 申请退款
        refund(){
            this.visibleRefund = true
        },
        // 我要退货
        returnGoods(){

        },
        // 申请开票
        invoice(){
            this.visibleInvoice = true
        },
        // 确认收货
        sureSh(){
            this.$confirm('您确定收货吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    
                    orderStatus({
                        orderId:this.dataOptions.id,
                        status: '501',
                        orderEx:false
                    }).then(res=>{
                        if(res.code === '00000'){
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getOrderDetail()
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
            });
           
        },
        handleClick(val,event){
            //this.qureyOrderList()
            this.$router.go(-1);
        },
     
        productDetail(id) {
            this.$router.push({
                path: '/product?productId=' + id
            })
        },
        goPj(item){
            this.spuId = item.spuId
            console.log(item,'pppppppppppp')
            
            this.visiblePj = true
        },
    },
    filters:{
        currency(value){
            if(value){
                return value / 100
            }else return '0.00'
        },
        payTypeFilter(payType) {
            return payTypes[payType]
        },
        orderStatusMapFilter(status) {
            return orderStatusMap[status]
        }
    }
}
</script>

<style lang="less" scoped>
html{
        background-color: #eee;
    }
.order{
    // max-width: 1000px;
    margin: auto;
    padding-bottom: 50px;
    .box {
        background-color: #fff;
        border-radius: 20px;
        padding: 20px 0;
        margin-top: 10px;
    }
    .box-card{
        width: 1000px;
        margin:5px auto;
        display: flex;
        align-items: center;
        border-radius: 20px;
        .info-price,.info{
            flex: 1;
            padding: 0 36px;
        }
        .info-price{
            
            &-total{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }
        }
        .info{
            border-right: 1px dashed #d8d8d8;
            .address-info{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                .row-right{
                    color: #909399;
                }
            }
        }
    }
    .bottom-but{
        border-top: 1px solid #eee;
        margin-top: 20px;
        text-align: right;
        padding: 20px;
        .but{
            display: flex;
            justify-content: end;
            .action-btn{
                margin-left: 12px;
            }
        }
    }
    .header{
        background-color: #fff;
        margin-bottom: 10px;
    }
    &-content{
        width: 1000px;
        margin: auto;
        &-detail{
            padding: 0 20px 20px 20px;
            border-radius: 20px;
            background-color: #fff;
            box-sizing: border-box;
        }
    }
    .page-header {
        max-width: 980px;
        margin:auto;
        display: flex;
        align-items: center;
        padding: 20px 0;
    }
    
}
</style>
