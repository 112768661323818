<template>
    <div class="container" :style="{'min-height': minHeight + 'px'}">
      <div v-if="orderDetails" class="order_finish">
        <div class="left">
          应付总额：<span>{{ orderAmount | currency }}</span>
        </div>
        <div class="right">
          <i class="icon iconfont icon-queren2"></i>
          <div class="count_down">
            <div class="name"><i class="el-icon-success success-style"></i><span>订单提交成功！请尽快完成支付。</span></div>
          </div>
        </div>
      </div>
      <div class="chioce_pay">
        <div class="pay_mode">选择以下支付方式付款</div>
        <div class="pay_plat">
          <div class="plat">支付平台</div>
          <a class="btn wx" :class="{'active-style': activePay == 5}" data-v-d4010372="" href="javascript:" ></a>
        
        </div>
        <div style="text-align: right;padding: 0 30px 30px 0;">
          <el-button type="primary" @click="goPay(5)">立即支付</el-button>
        </div>
      </div>
      <el-dialog :visible.sync="dialogTableVisible" title="支付" width="500px" @close="closeBtn">
        <div class="main">
          <div class="left" v-if="tradeState != 'SUCCESS' && tradeState != 'PAYERROR'">
            <template v-if="payType === 5">
              <dl>
                <dd style="text-align: center;margin-bottom: 10px;">请进行扫码支付</dd>
              </dl>
              <div id="qrcode" ref="qrcode"></div>
  
            </template>
            <template v-else>
              <img alt="" src="@/assets/images/load.gif">
            </template>
          </div>
  
          <div class="right" >
            <template v-if="tradeState == 'SUCCESS'">
              <div class="status-style"><i class="el-icon-success"></i></div>
              <dl class="right-item" >
                <dd>支付成功：</dd>
                <dt>
                  <div @click="navPage">查看订单详情</div>
                </dt>
              </dl>
            </template>
            <template v-else-if="tradeState == 'PAYERROR'">
              <div class="status-style"><i class="el-icon-error" style="font-size: 100px;"></i></div>
              <dl class="xia" >
                <dd>支付失败</dd>
                <!-- <dt>
                  <p>查看相关疑问</p>
                </dt> -->
              </dl>
            </template>
            
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import {getListPage,orderPay,queryPayStatus} from "@/api";
  import QRCode from 'qrcodejs2'
//   import {toWxPay} from "@/api/pay";
  
  export default {
    data() {
      return {
        orderDetails: true,
        countdown: '',
        payUrl: '',
        dialogTableVisible: false,
        wxPayQrcode: '',
        payType: 5,
        orderSn:'',
        orderAmount:'',
        minHeight:'',
        activePay:5,
        time:null,
        tradeState:'',
        wxPayCode:''
      }
    },
    created() {
        this.orderSn = this.$route.query.orderSn
        this.getListPage()
        this.minHeight = this.$store.state.minHeight || ''
        // this.getCode()
    },
    mounted() {
      this.time = null
      this.payUrl = this.aliPayUrl + this.redirect
    },
    methods: {
      navPage(){
        this.$router.replace({
          path: '/order'
        })
      },
      queryPayStatus(){
        queryPayStatus({
          orderSn: this.orderSn
        }).then(res=>{
          // "NOTPAY"
          this.tradeState = res.data.tradeState || ''
          if(this.tradeState == 'SUCCESS' || this.tradeState == 'PAYERROR'){
            clearInterval(this.time)
            this.time = null
          }
        })
      },
      getCode(text){
        if(this.$refs.qrcode){
          this.$refs.qrcode.innerHTML = ""
        }
        new QRCode('qrcode',{
          width:200,
          height:200,
          text:text,
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })
      },
//       WX_JSAPI(1, "微信JSAPI支付"),
// ALIPAY(2, "支付宝支付"),
// BALANCE(3, "会员余额支付"),
// WX_APP(4, "微信APP支付"),
// WX_NATIVE(5,"微信二维码支付");
     
      // 根据订单编号获取详情
      getListPage(){
          getListPage({
              orderSn: this.orderSn,
              pageNum: 1,
              pageSize: 30,
          }).then(res=>{
              if(res.code === '00000'){
                  this.orderAmount = res.data.list.list[0].payAmount
              }
          })
      },
      closeBtn(){
        this.dialogTableVisible = false
        clearInterval(this.time)
        this.time = null
      },
      goPay(type) {
        if(this.wxPayCode){
          this.dialogTableVisible = true
          this.getCode(this.wxPayCode)
          this.time = setInterval(()=>{
            this.queryPayStatus()
          },10000)
          return
        }
        let that = this;
        //type 5=微信支付
        if (type == 5) {
          that.activePay = type
          let param = {
            orderSn: this.orderSn,
            payType: this.payType
          }
  
          orderPay(param).then((res) => {
            if (res.code == '00000') {
              that.dialogTableVisible = true
              this.wxPayCode = res.data
              this.$nextTick(()=>{
                this.getCode(res.data)
                this.time = setInterval(()=>{
                  this.queryPayStatus()
                },10000)
                
              })
              // that.wxPayQrcode = res.msg
            } else {
              this.$message.warning(res.msg)
            }
          })
  
        }
      },
     
      // 生成倒计时的分和秒
      getMinSec() {
        const min = Math.trunc(this.orderDetails.countdown / 60)
        const sec = this.orderDetails.countdown % 60
        this.countdown = min + '分' + sec + '秒'
      }
  
    },
    beforeDestroy(){
      clearInterval(this.time)
      this.time = null
    },
    filters:{
      currency(value){
        if(value){
          return value / 100
        }else return '0.00'
        
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  @import "@/assets/styles/mixins.less";
  @import "@/assets/styles/variables.less";
  .container{
    max-width: 1190px;
    margin: 20px auto 0;
    padding-bottom: 30px;
  }
  .now_position {
    padding: 30px 0;
  
    /deep/ .el-breadcrumb__inner {
      color: #333;
    }
  
    /deep/ .is-link {
      color: #666;
      font-weight: 400;
  
      &:hover {
        color: @xtxColor;
      }
    }
  }
  
  .order_finish {
    background: #fff;
    padding: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .right {
      display: flex;
      text-align: center;
      .icon {
        font-size: 80px;
        color: #1dc779;
      }
  
      .count_down {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
  
        .name {
          font-size: 20px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
        }
  
        .time {
          color: #999;
          font-size: 16px;
        }
      }
    }
  
    .left {
      font-size: 16px;
      color: #999;
      
      span {
        color: #cf4444;
        font-size: 20px;
      }
    }
  }
  
  .chioce_pay {
    margin-top: 20px;
    background: #fff;
  
    .pay_mode {
      line-height: 70px;
      border-bottom: 1px solid #f5f5f5;
      padding-left: 30px;
    }
  
    .pay_plat {
      padding: 20px 30px;
      
      .plat {
        width: 100%;
        line-height: 20px;
        margin-bottom: 20px;
      }
  
      .btn {
        width: 150px;
        height: 50px;
        border: 1px solid #e4e4e4;
        text-align: center;
        line-height: 48px;
        margin-left: 30px;
        margin-bottom: 20px;
        color: #666666;
        display: inline-block;
  
        &.active,
        &:hover {
          border-color: @xtxColor;
        }
  
        &.alipay {
          background: url(../../assets/images/alipay.png) no-repeat center / contain;
        }
  
        &.wx {
          background: url(../../assets/images/wxpay.jpg) no-repeat center / contain;
        }
      }
      .active-style{
        border: 1px solid #1dc779;
      }
  
      .bank {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
  
        div {
          width: 150px;
          height: 50px;
          line-height: 50px;
          border: 1px solid #e4e4e4;
          margin-right: 30px;
          text-align: center;
          margin-bottom: 20px;
  
          &:hover {
            border: 1px solid #27ba9b;
          }
        }
      }
    }
  }
  
  /deep/ .el-dialog__header {
    border-bottom: 1px solid #f5f5f5;
  }
  
  .main {
    // display: flex;
    // justify-content: space-between;
    // padding: 30px;
    img {
      width: 180px;
      height: 180px;
    }
    .code-img{
      width: 300px !important;
      height: 300px !important;
    }
    .right {
      // width: 110px;
      // height: 180px;
      // display: flex;
      // flex-direction: column;
      justify-content: space-around;
      color: #999;
      .right-item{
        display: flex;
        justify-content: center;
      }
      .status-style{
        text-align: center;
        margin-bottom: 40px;
        .el-icon-success{
          color: #1dc779;
          font-size: 100px;
        }
      }
      a {
        color: #27ba9b;
        font-size: 16px;
      }
  
      .xia {
        display: flex;
        margin-bottom: 15px;
        justify-content: center;
      }
    }
    .left{
      width: 50%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  .success-style{
    color: rgb(11, 200, 11);
    font-size: 30px;
    margin-right: 10px;
  }
  </style>
  