<template>
    <div class="login">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
            <el-form-item  >
                <img :src="imgUrl" class="login-img"/>
            </el-form-item>
            <el-form-item   prop="phoneNumber">
                <!-- <div style="display: flex;"> -->
                    <el-input v-model="ruleForm.phoneNumber" autocomplete="off" placeholder="手机号">
                        <template slot="prepend" ><div class="el-icon-s-custom"></div></template>
                    </el-input>
                    <!-- <el-button type="primary" @click="smsCode" size="mini">获取验证码</el-button> -->
                <!-- </div> -->
                
            </el-form-item>
            <el-form-item  prop="pass">
                <div style="display: flex;">
                    <input hidden/>  
                    <el-input style="width: 260px;margin-right: 2px; " v-model="ruleForm.pass" autocomplete="off" placeholder="验证码" @keyup.enter.native="submitForm"></el-input>
                    <el-button type="primary" @click="smsCode" size="mini">{{isSend ? `${this.titValue==0 ? '':this.titValue} 重新获取`:'获取验证码'}}</el-button>
                </div>

            </el-form-item>
            <el-form-item>
                <!-- <div style="text-align: right;">忘记密码</div> -->
                <el-button type="primary" style="width: 100%;margin-top: 25px;" @click="submitForm">登录</el-button>
                <div @click="goRegister" style="text-align: right;cursor: pointer;">还没有账号？去 <span style="color:#0f597c">注册</span></div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {smsCode,oauthToken} from '@/api'
    import { localStorage } from '@/utils/storage';
    import {mapActions} from "vuex"
    export default {
        data() {
            // var validatePass = (rule, value, callback) => {
            //     if (value === '') {
            //     callback(new Error('请输入密码'));
            //     } else {
            //     if (this.ruleForm.checkPass !== '') {
            //         this.$refs.ruleForm.validateField('checkPass');
            //     }
            //     callback();
            //     }
            // };
            return {
                imgUrl: require('@/assets/logo_n.png'),
                ruleForm: {
                    pass: '',
                    phoneNumber:''
                },
                rules: {
                    phoneNumber:[
                        { required: true, message: '请输入账号', trigger: 'blur' },
                    ],
                    pass: [
                        // { required: true,validator: validatePass, trigger: 'blur' }
                        { required: true, message: '请输入账号', trigger: 'blur'}
                    ],
                  
                },
                sid:null,
                titValue:0,
                isSend:false,
                user:''
            }
        },
        
        mounted() {

        },
        methods: {
            ...mapActions(['getUserInfo']),
            getUserInfo1(){
                console.log('kkkkkkkk')
                this.getUserInfo().then(res=>{
                    if(res.code === '00000'){
                        this.user = res.data
                        this.$store.commit('setUser',res.data)
                        this.$store.commit('setToken',localStorage.get('token') || '')
                        this.$store.commit('setMemberId',res.data.id)
                    }
                    this.$router.push({
                            path: '/home'
                        })
                })
            },
            // 倒计时
			countDownNum(){
				// if(this.titValue == '获取验证码' || this.titValue == '重新获取'){
					this.titValue = 60;
					if(this.titValue == 60){
						this.sid = setInterval(()=>{
							this.titValue--;
							if(this.titValue == 0){
								this.isSend = false
								// this.titValue = '重新获取';
								clearInterval(this.sid);
								console.log(this.titValue)
							}
							console.log(this.titValue,'this.titValue')
						},1000)
					}
				// }
			},
            // 获取验证码
            smsCode(){
                if(!this.ruleForm.phoneNumber){
                    return
                }
                if(this.isSend){
                    this.$message.warning('请稍后再试!')
                    return
                }
                this.isSend = true
                smsCode({
                    phoneNumber: this.ruleForm.phoneNumber
                }).then(res=>{
                    if(res.code === '00000'){
                        this.countDownNum()
                    }
                })
            },
            // 登录
            loginApi(){
                oauthToken({
                    mobile: this.ruleForm.phoneNumber,
                    code:this.ruleForm.pass,
                    grant_type:'sms_code'
                }).then(res=>{
                    clearInterval(this.sid);
                    if(res.code === '00000'){
                        const { access_token, token_type } = res.data;
                        const accessToken = token_type + ' ' + access_token;
                        localStorage.set('token', accessToken);
                        localStorage.set('userInfo', res.data);
                        this.getUserInfo1()
                        
                    }
                })
            },
            goRegister(){
                this.$router.push({
                    path: '/register'
                })
            },
            submitForm(){
                
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.loginApi()
                       
                    } else {
                        return false;
                    }
                });
            },
            // keyHandel(e){
            //     console.log('ppppppp',e)
            // },
            resetForm(){

            }
        },
        
    }
</script>

<style lang="less" scoped>
    .login{
        background-color: #fff;
        .login-img{
            width: 180px;
        }
        .demo-ruleForm{
            width: 360px;
            margin-top: 10%;
            margin-left: 18%;
        }
    }
</style>