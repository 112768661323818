<template>
	<div>
		<div class="Rboy-box">
			<div class="Rboy-obverse">
				
				<el-upload
					action="#"
					list-type="picture-card"
					:show-file-list="false"
					:on-success="handleAvatarSuccess"
					:before-upload="beforeAvatarUpload">
					<img class="obverseimg" :src="obverseUrl ? obverseUrl : obverse" />
				</el-upload>
				<el-image class="del_btn" :src="del" v-if="obverseUrl"  @click="del_btn('obverseUrl')"></el-image>
				<div class="bottom">身份证正面照</div>
			</div>
			<div class="Rboy-reverse">
				<el-upload
					action="#"
					list-type="picture-card"
					:show-file-list="false"
					:on-success="handleAvatarSuccess"
					:before-upload="beforeAvatarUpload1">
					<img class="reverseimg" :src="reverseUrl ? reverseUrl : reverse" />
				</el-upload>
				<el-image class="del_btn" :src="del" mode="" v-if="reverseUrl" @click="del_btn('reverseUrl')"></el-image>

				<div class="bottom">身份证反面照</div>
			</div>
		</div>
	</div>
</template>

<script>
	import file from "./file.js"
	import {updatePic} from '@/api'
	export default {
		name: "Rboy-upload-sfz",
		props: {
			// 正面
			obverseUrl: {
				type: String,
				default: ""
			},
			// 反面
			reverseUrl: {
				type: String,
				default: ""
			},
			// album 从相册选图，camera 使用相机，默认二者都有。如需直接开相机或直接选相册，请只使用一个选项
			sourceType: {
				type: Array || Object,
				default: () => {
					return ['album', 'camera']
				}
			}
		},
		data() {
			return {
				obverse: file.obverse,
				reverse: file.reverse,
				del: file.del,
				urlImg:''
			}
		},
		created() {

		},
		methods: {
			handleAvatarSuccess(){
				
			},
			beforeAvatarUpload(file){
				console.log(file,'ppppppppp')
				const obj = new FormData();
				obj.append("file",file)
				this.updatePic(obj,'obverseUrl')
			},
			beforeAvatarUpload1(file){
				const obj = new FormData();
				obj.append("file",file)
				this.updatePic(obj,'reverseUrl')
			},
			updatePic(obj,type){
				updatePic(obj).then(res=>{
					if(res.code === '00000'){
						this[type] = res.data
						this.$emit("selectChange",{
							type:type,
							url:res.data
						} )
					}
				})
			},
			
			// 上传
			select_change(name) {
				// uni.chooseImage({
				// 	count: 1, //默认9
				// 	sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
				// 	sourceType: this.sourceType, // 从相册选择
				// 	success: (res) => {
				// 		this.$emit("selectChange", {
				// 			msg: `${name}Image:ok`,
				// 			name,
				// 			tempFilePath: res.tempFilePaths[0],
				// 			tempFile: res.tempFiles[0]
				// 		})
				// 	}
				// });
			},
			// 预览
			previewImage(current = 0) {
				// uni.previewImage({
				// 	current,
				// 	urls: [this.obverseUrl, this.reverseUrl],
				// });
			},
			// 删除
			del_btn(name) {
				this.$emit("del", {
					name,
				})
			}
		}
	}
</script>

<style scoped lang="less">
	@imgWidth: 200px;
	@imgheight: 140px;
	@boxheight: 180px;

	.Rboy-box {
		display: flex;
		justify-content: space-between;
		// align-items: center;

		.Rboy-obverse {
			border-radius: 20px;
			width: @imgWidth;
			height: @boxheight;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			position: relative;
			/deep/.el-upload--picture-card{
				width: 200px;
    			height: 140px;
				border: none;
			}
			.obverseimg {
				width: @imgWidth;
				height: @imgheight;
			}

			.bottom {
				text-align: center;
				height: calc(@boxheight - @imgheight);
				background-color: #B7D7FF;
				color: #3B5FD2;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.del_btn {
				position: absolute;
				top: 5px;
				right: 5px;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				z-index: 20;
			}
		}

		.Rboy-reverse {
			border-radius: 10px;
			width: @imgWidth;
			height: @boxheight;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			position: relative;

			.reverseimg {
				width: @imgWidth;
				height: @imgheight;
			}

			/deep/.el-upload--picture-card{
				width: 200px;
    			height: 140px;
				border: none;
			}
			
			.bottom {
				text-align: center;
				height: calc(@boxheight - @imgheight);
				background-color: #B7D7FF;
				color: #3B5FD2;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.del_btn {
				position: absolute;
				top: 5px;
				right: 5px;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				z-index: 20;
			}
		}
	}
</style>
