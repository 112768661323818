<template>
  <div>
    <div class="address_title">{{ title }}</div>
    <div class="goods_info">
      <el-table ref="multipleTable"
            :data="goodsInfo" align="center" 
            class="goods-table" size="mini"
            stripe
            style="width: 100%;border: 1px solid #eee;"
            tooltip-effect="dark"
            >
            <el-table-column align="center" label="商品信息" min-width="110">
                <template v-slot="scope">
                  <div class="img_name">
                    <img :src="scope.row.picUrl" alt=""/>
                    <div class="name">
                      <p>{{ scope.row.spuName }}</p>
                    </div>
                  </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="商品库存名称" prop="skuName" min-width="120"></el-table-column>
            <el-table-column align="center" header-align="center" label="单价（元）" prop="price" >
              <template v-slot="scope">
                  <div>{{ scope.row.price | currency}}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="数量" prop="count" min-width="50"></el-table-column>
            <el-table-column align="center" header-align="center" label="小计（元）" >
                <template v-slot="scope">
                    <div>{{ scope.row.price | currency }}</div>
                </template>
            </el-table-column>
            <el-table-column align="center" header-align="center" label="实付（元）" >
                <template v-slot="scope">
                    <div>{{ (scope.row.price * scope.row.count) | currency }}</div>
                </template>
            </el-table-column>
            <el-table-column align="center" v-if="isOperate" header-align="center" label="操作" width="100">
                <template v-slot="scope">
                    <el-button type="text" @click="goDetail(scope.row)">产品详情</el-button>
                    <slot :item="scope.row"></slot>
                </template>
            </el-table-column>
        </el-table>
      
    </div>
  </div>

</template>

<script>
export default {
  props: {
    goodsInfo: {
      type: Array
    },
    title: {
      type: String
    },
    isOperate:{
      type: Boolean,
      default:false
    },
    
  },
  methods:{
    goDetail(row){
      const {href} = this.$router.resolve({
        path:'/product',
        query:{
          productId: row.spuId
        }
      })
      window.open(href,'_blank');
    },
   
  },
  filters:{
    currency(value){
      if(value){
        return value / 100
      }else return '0.00'
      
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/styles/mixins.less";
@import "@/assets/styles/variables.less";

.address_title {
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  line-height: 40px;
  border-bottom: 1px solid #f5f5f5;
}

.goods_info th, .goods_info td {
  text-align: center;
  vertical-align: middle !important;
}

.goods_info {
  padding: 20px 0;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #f5f5f5;
    width: 100%;
    thead {
      background: #f5f5f5;
      height: 60px;

      tr {
        th {
          font-weight: normal;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #f5f5f5;

        td {
          text-align: center;
          padding: 10px 0;

          .img_name {
            display: flex;
            align-items: center;
            padding-left: 20px;

            img {
              width: 70px;
              height: 70px;
            }

            .name {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 20px;
              text-align: left;

              .attrs {
                color: #999;
              }
            }
          }
        }
      }
    }
  }
}

</style>
