import axios from 'axios';
import {Message,MessageBox} from 'element-ui';
import { localStorage } from '@/utils/storage';
import useStore from '@/store';
import router from "@/router";

// 创建 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 150000,
    headers: { 'Content-Type': 'application/json;charset=utf-8'},
    withCredentials: false, // 跨域请求是否需要携带 cookie
  });
  
  // 请求拦截器
  service.interceptors.request.use(
    (config) => {
      if (!config.headers) {
        throw new Error(
          `Expected 'config' and 'config.headers' not to be undefined`
        );
      }
      if(localStorage.get('token')){
        config.headers.Authorization = `${localStorage.get('token')}`;
      }else{
        config.headers.Authorization = 'Basic bWFsbC1hcHA6MTIzNDU2';
      }
      // const { user } = useStore();
      // if (user.token) {
      //   config.headers.Authorization = `${localStorage.get('token')}`;
      // }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  // 响应拦截器
  service.interceptors.response.use(
    (response) => {
      console.log(response,'response')
      const { code, msg } = response.data;
      if (code === '00000') {
        return response.data;
      } else {
        // 响应数据为二进制流处理(Excel导出)
        if (response.data instanceof ArrayBuffer) {
          return response;
        }
  
        Message({
          message: msg || '系统出错',
          type: 'error',
        });
        return Promise.reject(new Error(msg || 'Error'));
      }
    },
    (error) => {
      const { code, msg } = error.response.data;
      if (code === 'A0230') {
        // token 过期
        localStorage.clear(); // 清除浏览器全部缓存
        // window.location.href = '/login'; // 跳转登录页
       
        Message.warning('当前页面已失效，请重新登录');
        router.push({
            path: '/login'
        })
      } else if (code == 'B0210') {
        // 系统限流
        return error.response.data;
      } else {
        Message({
          message: msg || '系统出错',
          type: 'error',
        });
      }
      return Promise.reject(new Error(msg || 'Error'));
    }
  );
  
  // 导出 axios 实例
  export default service;