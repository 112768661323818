<template>
  <div class="home " >
    <!-- header -->
    <div id="header" class="header">
      <div class="header-top">
        <ul class="header-top-nav" v-if="memberId">
          <li class="header-top-nav-li">{{ mobile || '' }}</li>
          <li class="line"></li>
          <li class="header-top-nav-li" @click="handleClick">退出</li>
        </ul>
        <ul class="header-top-nav" v-else>
          <li class="header-top-nav-li" @click="goLogin('/login')">
            <a >登录</a>
          </li>
          <li class="line"></li>
          <li class="header-top-nav-li" @click="goLogin('/register')">
            <a >注册</a>
          </li>
        </ul>
      </div>
      
      <ul class="header-list">
        <li><img :src="imgUrl" class="logo-style" @click="goHome"/></li>
        <li class="search-module">
          <el-input placeholder="请输入商品名称" v-model="searchTxt">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button class="search-btn" type='primary' @click="searchBtn">搜索</el-button>
        </li>
        <li class="header-right">
          <el-button  class="shop-car" type='primary' @click="goList">
            <i class="el-icon-shopping-cart-2 icon-cart"></i> 购物车
          </el-button>
          <el-popover placement="bottom" trigger="hover">
            <el-image
                slot="reference"
                :src="xcxJpg"
                fit="cover"
                class="xcx-jpg"
            />
            <el-image
                :src="xcxJpg"
                fit="cover"
                style="width: 220px; height: 220px"
            />
          </el-popover>
          <!-- <img class="xcx-jpg" :src="xcxJpg" /> -->
        </li>
        <!-- <li v-for="item in headData" :key="item.value" :class="{'active-tab': activeValue == item.value}"
            class="header-label" @click="goPage(item)">{{ item.label }}
        </li> -->
      </ul>
      <div  class="header-classify">
        <div class="header-classify-maintit">全部分类 <i class="el-icon-arrow-down dowm-icon"></i></div>
        <ul class="header-select">
          <li v-for="(item,index) in headData" :key="index" :class="{'active-tab': activeValue == item.value}"
              class="header-select-label" @click="goPage(item)">{{ item.label }}
          </li>
        </ul>
      </div>
      <div class="login-btns">

      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <router-view></router-view>
    </div>
    <!-- footer -->
    <div id="footer" class="footer">
      <div class="footer-content">
        <div class="footer-content-con">
          <ul class="footer-content-con-ul">
            <li>安徽亳药福泰药业有限公司</li>
            <li>地址：安徽省毫州市高新区银杏路3号</li>
            <li>电话：0558-5580999 / 0558-5808555</li>
          </ul>
          
          <div class="imgs">
            
            <div class="imgs-module" v-for="(item,index) in imgLists" :key="index" >
              <!-- <img class="imgs-style" :src="item.pic" /> -->
              <el-popover placement="top" trigger="hover">
                <el-image
                    slot="reference"
                    :src="item.pic"
                    fit="cover"
                    class="imgs-style"
                />
                <el-image
                    :src="item.pic"
                    fit="cover"
                    style="width: 220px; height: 220px"
                />
              </el-popover>
              <div>{{ item.tit }}</div>
            </div>
            
          </div>
        </div>
        <div class="footer-content-d">
          <span>CopyRight © 2021 All rights reserved. 亳药福泰 版权所有</span>
          <img src="@/assets/nationalEmblem.png" />
          <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=" class="bah-style">皖ICP备2021009242号-3</a>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { localStorage } from '@/utils/storage';

export default {
  data() {
    return {
      headData: [
        {
          label: '首页',
          value: 'home'
        },
        {
          label: '热销商品',
          value: 'hot'
        },
        {
          label: '推荐商品',
          value: 'tj'
        },
        {
          label: '药食同源',
          value: 'ysty'
        },
      ],
      activeValue: 'home',
      imgUrl: require('@/assets/logo_n.png'),
      showTop: true,
      visibleDetail: false,
      xcxJpg: require('@/assets/xcx.jpg'),
      searchTxt:'',
      imgLists:[
        {
          pic:require('@/assets/wx_code.png'),
          tit:'微信公众号'
        },
        {
          pic:require('@/assets/xcx.jpg'),
          tit:'小程序'
        }
      ],
      screenHeight:'',
      headerHeight:'',
      footerHeight:'',
      minHeight:'',
      memberId:'',
      mobile:'',
      auditStatusTit:{
        0:'请先去资质认证',
        1:'资质认证审核中'
      }
    }
  },
  computed: {
    loginName() {
      let item = JSON.parse(window.localStorage.getItem("user")) || ''
      if(!item){
        this.showLogin()
      }
      return item ? item.loginName : ''
    }  
  },
  mounted() {
    // console.log(this.$route.name)
    this.activeValue = this.$route.query.type || (this.$route.name == 'home' ? this.$route.name : '')
    // console.log(this.activeValue,'value')
    // this.showTop = this.$route.name == 'Login' || this.$route.name == 'Register' ? false : true
    this.searchTxt = this.$route.query.keywords || ''
    this.screenHeight = document.documentElement.clientHeight || document.body.clientHeight
    this.headerHeight = document.getElementById("header").scrollHeight
    this.footerHeight = document.getElementById("footer").scrollHeight
    this.minHeight = this.screenHeight - this.headerHeight - this.footerHeight || 560
    localStorage.set('minHeight',this.minHeight)
    this.$nextTick(()=>{
      this.memberId = localStorage.get('userInfo') ? localStorage.get('userInfo').memberId : ''
      // console.log(this.memberId,'lllllll',localStorage.get('user').mobile,'ppp',this.$store.state.user)
      if(this.memberId){
        this.mobile = localStorage.get('user') ? localStorage.get('user').mobile:''
      }
    })
  },
  methods: {
    searchBtn(){
      if(!this.searchTxt){
        return
      }
      this.$router.push({
        path:`/productList?keywords=${this.searchTxt}`
      })
    },
    goLogin(path){
      this.$router.push({
        path: path
      })
    },
    goHome() {
      this.$router.push({
        path: '/'
      })
    },
    goList() {
      // if(this.$store.state.user.auditStatus != 2){
      //   this.$message.warning(this.auditStatusTit[this.$store.state.user.auditStatus]||'请先登录')
      //   return
      // }
      this.$router.push({
        path: '/shopCart'
      })
    },
    showLogin() {
      this.visibleDetail = true
    },
    goPage(val) {
      // if(val.value == 'ysty'){
      //   this.$message.warning('开发中')
      //   return
      // }
      this.activeValue = val.value
     
      if (val.value == 'home') {
        this.$router.push({
          name: val.value
        })
      }else if(val.value == 'ysty'){
        this.$router.push({
          path: `/ysty?type=${val.value}`
        })
      }else {
        this.$router.push({
          path: `/productList?type=${val.value}`
        })
      }
     
    },
    handleClick() {
      localStorage.remove('token');
      this.$message.success("退出成功")
      localStorage.clear();
      this.$router.push({
            path: '/login'
        })
      // this.loginOut()
    },
    loginOut() {
      this.$store.commit('LOGOUT')
      this.$message.success("退出成功")
      location.reload();
    }
  },
  watch: {
    $route: {
      handler(val) {
        if(val.name == 'home'){
          this.activeValue = val.name
          this.searchTxt = ''
        }else{
          this.activeValue = val.query.type || ''
          
        }
        this.memberId = this.$store.state.memberId || ''
        this.mobile = this.$store.state.user ? this.$store.state.user.mobile:''
      },
      deep: true
    },
    '$store.state.memberId'(val){
      // handler(val) {
        console.log(val,'val')
        this.memberId = val
        this.mobile = this.$store.state.user ? this.$store.state.user.mobile:''
      // },
      // deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  // height: 100%;

  .header {
    width: 100%;
    // height: 151px;
    margin: auto;
    background-color: #fff;

    &-top{
      background-color: #f1f1f1;
      color: #333;
      font-size: 12px;
      &-nav{
        max-width: 1190px;
        margin: auto;
        line-height: 30px;
        display: flex;
        justify-content: right;
        align-items: center;
        &-li{
          padding: 0 10px;
          text-align: center;
          cursor: pointer;
        }
        .line{
          border-left: 1px solid #c4c3c3;
          height: 12px;
        }
      }
    }

    &-list {
      max-width: 1190px;
      display: flex;
      align-items: center;
      height: 100px;
      margin: auto;

      .logo-style {
        width: 180px;
        // height: 60px;
        display: block;
      }

      .logo-tit {
        font-size: 28px;
        margin: 0 30px 0 6px;
        color: #0f597c;
        font-weight: bold;
      }

     


      .search-module {
        width: 40%;
        display: flex;
        margin: auto;
        text-align: center;

        /deep/ .el-input{
          font-size: 16px;
        }
        /deep/ .el-input__inner {
          // border: none;
          border: 2px solid #fa436a;
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-radius: 2px;
          height: fit-content;
        }

        .search-btn {
          // width: 100px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          font-size: 16px;
        }
       
      }
      .header-right{
        display:flex;
        align-items: center;
        .shop-car{
          margin-right:30px;
          font-size: 16px;
        }
        .xcx-jpg{
          width: 62px;
        }
      }


    }

    &-classify {
      display: flex;
      max-width: 1190px;
      margin: auto;
      align-items: center;
      // margin-top: 20px;

      &-maintit {
        width: 225px;
        text-align: center;
        background: linear-gradient(to right, #ffac30, #fa436a, #f56c6c);
        color: #fff;
        padding: 10px 0;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        .dowm-icon {
          background: #fff;
          color: #fa436a;
          border-radius: 50%;
          font-weight: 900;
          padding: 2px;
          font-size: 13px;
          margin-left: 4px;
        }
      }
    }
   

    &-select {
      display: flex;
      
      &-label {
        padding: 10px 20px;
        cursor: pointer;
      }

      .active-tab {
        color: #fa436a;
      }
    }

    .login-btns {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .content {
    // padding: 20px 0;
    min-height: calc(100% - 141px);
    background-color: #edeeef;
  }

  .footer {
    background: #333;
    padding: 20px 0 6px;
    // position: absolute;
    // bottom: 0;
    width: 100%;
    color: #fff;
    &-content {
      max-width: 1190px;
      margin: auto;
      &-con{
        display: flex;
        margin-bottom: 16px;
        &-ul{
          font-size: 12px;
          color: #cdc8c8;
          li:first-child{
            font-size: 16px;
            margin-bottom: 8px;
            color: #fff;
          }
        }
      }
      &-d{
        text-align: center;
        font-size: 12px;
        line-height: 36px;
        border-top: 1px solid #cdc8c8;
        color: #cdc8c8;
        span:first-child{
          border-right: 1px solid #807f7f;
          margin-right: 20px;
          padding-right: 20px;
        }
        img{
          margin-bottom: -4px;
          margin-right: 2px;
        }
        .bah-style{
          cursor: pointer;
          color: #cdc8c8;
        }
        .bah-style:hover{
          color: rgb(15, 89, 124);
        }
      }
      .imgs{
        margin-left: auto;
        display: flex;
        font-size: 14px;
        &-module{
          width: 90px;
          text-align: center;
        }
        &-style{
          height: 70px;
        }
      }
      
    }
  }
}
</style>
