import { render, staticRenderFns } from "./couponBox.vue?vue&type=template&id=503a6668&scoped=true&"
import script from "./couponBox.vue?vue&type=script&lang=js&"
export * from "./couponBox.vue?vue&type=script&lang=js&"
import style0 from "./couponBox.vue?vue&type=style&index=0&id=503a6668&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "503a6668",
  null
  
)

export default component.exports