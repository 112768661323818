<template>
    <div class="register">
        <div class="head">
            <img :src="imgUrl" class="img-logo"/>
        </div>
        <!-- 注册步骤 -->
        <div class="step-style">
            <el-steps :active="active" simple class="step-style-top" finish-status="success" process-status="finish">
                <el-step title="资质认证" icon="el-icon-tickets"></el-step>
                <el-step title="认证结果" icon="el-icon-finished"></el-step>
            </el-steps>

            <div class="step-content">
                <!-- 资质认证 -->
                <template v-if="active === 0">
                    <el-form ref="formZz" :model="formZz" :rules="rulesZz" label-width="280px" class="step-content-form1" >
                        <el-form-item label="用户类型">
                            <el-radio-group v-model="formZz.userType" :disabled="formZz.auditStatus != 0">
                                <el-radio v-for="(item,index) in typeOptions" :key="index" :label="item.id">{{ item.typeLabel }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="采购员身份证信息" prop="reverseUrl" class="form-module-item">
                            <Rboy-upload-sfz v-if="formZz.auditStatus == 0" :obverse-url="formZz.obverseUrl" :reverse-url="formZz.reverseUrl" @selectChange="sfz_chagne" @del="del_btn"></Rboy-upload-sfz>
                            <div v-else>
                                <img style="width: 120px;height: 160px;" :src="formZz.obverseUrl" />
                                <img style="width: 120px;height: 160px;" :src="formZz.reverseUrl" />
                            </div>
                        </el-form-item>
                        <el-form-item label="" prop="companyName" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.companyName" :disabled="formZz.auditStatus != 0" placeholder="请输入公司名称"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="legalRepresentative" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.legalRepresentative" :disabled="formZz.auditStatus != 0" placeholder="请输入法定代表人"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="agent" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.agent" :disabled="formZz.auditStatus != 0" placeholder="请输入委托代理人"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="taxNumber" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.taxNumber" maxlength="20" :disabled="formZz.auditStatus != 0" placeholder="请输入税号"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="personName" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.personName" :disabled="formZz.auditStatus != 0" placeholder="请输入负责人姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="idCard" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.idCard" autocomplete="off" :disabled="formZz.auditStatus != 0" maxlength="18" placeholder="请输入身份证号"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="personPhone" class="form-module-item">
                            <el-input class="info-style" v-model="formZz.personPhone" maxlength="11" :disabled="formZz.auditStatus != 0" placeholder="请输入手机号" ></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="jobNo" v-if="formZz.auditStatus == 0">
                            <el-input class="info-style" v-model="formZz.jobNo"  placeholder="请输入销售员工号"></el-input>
                        </el-form-item>

                        <el-form-item label="医疗机构执业许可证或中医诊所备案证书" v-if="formZz.userType === 1" prop="yljgzyxkzUrl">
                            <el-upload
                                :disabled="formZz.auditStatus != 0"
                                action="#"
                                list-type="picture-card"
                                :show-file-list="false"
                                :before-upload="beforeAvatarUpload">
                                <img :src="formZz.yljgzyxkzUrl" v-if="formZz.yljgzyxkzUrl" class="avatar"/>
                                <i v-else class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="药品经营许可证" v-if="formZz.userType === 2 || formZz.userType === 3" prop="ypjyxkzUrl">
                            <el-upload
                                :disabled="formZz.auditStatus != 0"
                                action="#"
                                list-type="picture-card"
                                :show-file-list="false"
                                :before-upload="beforeAvatarUpload2">
                                <img :src="formZz.ypjyxkzUrl" v-if="formZz.ypjyxkzUrl" class="avatar"/>
                                <i v-else class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="营业执照" v-if="formZz.userType === 2 || formZz.userType === 3" prop="businessCardUrl">
                            <el-upload
                                :disabled="formZz.auditStatus != 0"
                                action="#"
                                list-type="picture-card"
                                :show-file-list="false"
                                :before-upload="beforeAvatarUpload3">
                                <img :src="formZz.businessCardUrl" v-if="formZz.businessCardUrl" class="avatar"/>
                                <i v-else class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="法人委托授权书" prop="appendsUrl">
                            <el-upload
                                v-if="formZz.auditStatus == 0"
                                class="upload-demo"
                                action="#"
                                :limit="1"
                                :show-file-list="true"
                                :on-change="handleChange"
                                :on-remove="handleRemove"
                                :file-list="formZz.appendsUrl">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                            <div v-else>
                                {{ formZz.appendsUrl[0].name || '' }}
                            </div>
                            <span class="downMb" v-if="formZz.auditStatus == 0" @click="clickDownloadTemplate">下载采购委托书模板</span>
                        </el-form-item>
                        
                        <el-form-item v-if="formZz.auditStatus == 0">
                            <div>
                                <el-checkbox v-model="checked"></el-checkbox> 我已阅读并同意
                                <span class="xy-style" @click="showXy">购销协议</span>
                            </div>
                        </el-form-item>
                       
                        <el-form-item v-if="formZz.auditStatus == 0">
                            <el-button type="primary" @click="onSubmit" style="width: 100%;">提交</el-button>
                        </el-form-item>
                        
                    </el-form>
                </template>

                <!-- 注册成功 -->
                <template v-if="active === 1">
                    <div class="success-style">
                        <div class="el-icon-success success-icon"></div>
                        <div>{{codeToTit[codeValue] || ''}}去<span class="login-btns" @click="goLogin"> 首页 </span> </div>
                    </div>
                </template>
            </div>
            
            <!-- <el-button style="margin-top: 12px;" @click="next">下一步</el-button> -->
        </div>
        <!-- 寻回信息 -->
        <!-- <div class="step-style">
            <el-steps :active="1" simple>
                <el-step title="身份认证" icon="el-icon-edit"></el-step>
                <el-step title="密码重置" icon="el-icon-upload"></el-step>
                <el-step title="成功" icon="el-icon-finished"></el-step>
            </el-steps>
        </div> -->
        <!-- 购销协议 -->
        <el-dialog
            title="购销协议"
            :visible.sync="dialogVisible"
            width="820px"
            @close="cancel">
            <div class="content">
                <div class="tip-xy overflow">
                    <div class="tip-xy-tit">购销合同</div>
                    <div class="tip-xy-topRight">合同编号：【2023年】      号   </div>
                    <div class="tip-xy-value">甲方（购方）：{{formZz.companyName || ''}}</div>
                    <div class="tip-xy-value">乙方（供方）：安徽省亳州市中西药有限公司</div>
                    <div class="tip-xy-value sj-style">甲乙双方本着平等自愿、诚实信用的原则，根据《中华人民共和国民法典》、《中华人民共和国药品管理法》、《药品经营质量管理规范》等相关法律、法规、规章规定，经双方协商一致，就有关事项达成如下具体协议：</div>
                    <div class="tip-xy-title sj-style"><text>第一条</text> 中药饮片品种、规格、数量及单价</div>
                    <div class="tip-xy-value sj-style">1.采购中药饮片品种：在合同有效期内根据甲方实际需求甲方向乙方提供所采购的中药饮片品名、数量、规格、等详见每次提供的中药饮片采购清单，或乙方根据自己的库存和销售需要经甲方同意入库的中药饮片； </div>
                    <div class="tip-xy-value sj-style">2.中药饮片的价格：在合同有效期内乙方根据甲方提交中药饮片的采购清单报价给甲方，经甲方确认后，按实际交付数量和价格开具销货清单给甲方，甲方根据销售清单对中药饮片的数量、规格、产地、价格等相关规定进行验收入库，3个工作日内无异议后生效。</div>
                    <div class="tip-xy-title sj-style"><text>第二条</text> 质量标准</div>
                    <div class="tip-xy-value sj-style">乙方向甲方交付的中药饮片必须是企业合法生产的，必须符合现行的国家相关质量标准，即符合现行版《中华人民共和国药典》规定以及国务院、相关部委和地方颁布的标准，中药饮片应为正品，包装上应有品名、规格、生产企业、生产日期、生产批次，并符合相关规定。有效期不得少于24个月；特殊品种双方另行协商。</div>
                    <div class="tip-xy-title sj-style"><text>第三条</text> 配送服务</div>
                    <div class="tip-xy-value sj-style">配送由乙方负责。乙方按甲方在商城订单要求为甲方提供配送及相关服务，原则上在乙方收到供货通知后36个小时内完成出货并交由快递公司配送。甲方收到货物之后及时验收。</div>
                    <div class="tip-xy-title sj-style"><text>第四条</text> 验收方式</div>
                    <div class="tip-xy-value sj-style">乙方货物送达甲方指定地点后，甲方应48小时内进行数量和外观验收，乙方配送中药饮片时出具销售清单和质量检测报告书。甲方验收员按照法定标准《药品经营质量管理规范》规定的质量条款对购进的中药饮片进行逐批验收，对特殊管理的中药饮片，应实行双人验收制度。</div>
                    <div class="tip-xy-title sj-style"><text>第五条</text> 违约责任</div>
                    <div class="tip-xy-value sj-style">1.乙方提供的中药饮片不符合合同约定质量标准、期限等要求，包含甲方供应单位在使用过程中，因受抽检等检查出现的质量问题，给甲方和甲方供应单位造成纠纷或者经济损失，乙方赔偿甲方造成的经济损失，甲方可以单方面解除合同，因甲方或甲方供应单位储存不当等其他非因乙方原因造成的质量问题除外。</div>
                    <div class="tip-xy-value sj-style">2.乙方不履行本合同或未按合同约定的时间、地点配送中药饮片或提供伴随服务，甲方和乙方协商后可解除合同；</div>
                    <div class="tip-xy-value sj-style">3.乙方应对验收时发现的破损、有效期少于24个月或不符合特殊约定期限的中药饮片及其他不合格包装中药饮片免费更换，时间不得超过15个工作日。</div>
                    <div class="tip-xy-value sj-style">4.乙方应保证供应中药饮片时，不存在该中药饮片专利权、商标权或保护期等知识产权方面的争议，如产生争议由乙方自行处理和承担责任。</div>
                    <div class="tip-xy-title sj-style"><text>第六条</text> 退换货</div>
                    <div class="tip-xy-value sj-style">中药饮片为特殊商品，其质量易受外部环境因素影响，甲方签收后应妥善存放，如无质量问题概不退货；未拆包中药饮片，按快递签收日期算起，如无质量问题超过15天不可换货；如拆开包装后发现确有质量问题，当日联系乙方可以协调换货。</div>
                    <div class="tip-xy-title sj-style"><text>第七条</text> 合同生效及合同有效期</div>
                    <div class="tip-xy-value sj-style">1.本合同自双方签字盖章之日起生效。合同有效期自   年   月   日至    年     月      日。</div>
                    <div class="tip-xy-value sj-style">2.本合同履行期满30日前，一方当事人就续约一事提出书面异议的，本合同履行期满终止。双方均未提出异议的，本合同自动续约一年。</div>
                    <div class="tip-xy-title sj-style"><text>第八条</text> 争议解决</div>
                    <div class="tip-xy-value sj-style">甲乙双方因履行本合同发生争议，应首先协商解决；协商不成时，应选择双方所在地人民法院。</div>
                    <div class="tip-xy-title sj-style"><text>第九条</text> 需要双方明确的其他事项</div>
                    <div class="tip-xy-value sj-style">1.甲乙双方已相互提示就本合同各条款作全面、准确的理解，并应对方要求作了相应的说明，签约双方对本合同的认识已达成完全的一致。</div>
                    <div class="tip-xy-value sj-style">2.合同一方通信地址的变更，须以书面形式通知对方。合同一方按本合同规定向对方发出的通知或其它信函，应以书面形式作出，并经专人、速递或传真按本合同中注明的地址向对方发出。送达时间以下列规定为准：</div>
                    <div class="tip-xy-value sj-style">（1）专人交付之日视为送达之日；</div>
                    <div class="tip-xy-value sj-style">（2）速递在发送后第三天被视为送达；</div>
                    <div class="tip-xy-value sj-style">（3）微信短信传真方式以顺利发出当天后的第一个工作日视为送达。</div>
                    <div class="tip-xy-title sj-style"><text>第十条</text> 本协议一式 2 份，甲方 1  份，乙方 1  份，原件扫描件具有同等法律效力，经甲乙双方签字并盖章后成立。</div>
                    <div class="tip-xy-title sj-style"><text>第十一条</text> 本合同未尽事项，由甲、乙双方另行议定并签订补充协议。补充协议与本合同具有同等效力。</div>
                    <div class="tip-xy-footer">
                        <div class="tip-xy-footer-item">
                            <div>甲方（购方）：盖章 </div>
                            <div>法定代表人：{{formZz.legalRepresentative || ''}}</div>
                            <div>委托代理人：{{formZz.agent || ''}}</div>
                            <div>开  户  行：</div>
                            <div>账      号：</div>
                            <div>地      址：</div>
                            <div>签订日期：  </div>
                        </div>
                        <div class="tip-xy-footer-item">
                            <div>乙方（销方）：<el-image class="imgs-style" :src="dzqz"></el-image> </div>
                            <div>法定代表人：  </div>
                            <div>委托代理人：    </div>
                            <div>开  户  行： </div>
                            <div>账      号：  </div>
                            <div>地      址：</div>
                            <div>签订日期：   </div>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="cancel">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import RboyUploadSfz from "@/components/Rboy-upload-sfz/Rboy-upload-sfz.vue"
import {updatePic,registerApi,smsCode,uploadQualified,getAuditStatus,getQualified} from '@/api'
export default{
    components:{ RboyUploadSfz },
    data() {
        var validatePass1 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
            // if (this.ruleForm.checkPass !== '') {
            //     this.$refs.ruleForm.validateField('checkPass');
            // }
                callback();
            }
        };
        return {
            dzqz: require('@/assets/dzqz.png'),
            imgUrl: require('@/assets/logo_n.png'),
            active: 0,
            typeOptions:[
                // {
                //     id:0,
                //     typeLabel: '未知'
                // },
                {
                    id:1,
                    typeLabel: '医疗机构'
                },
                {
                    id:2,
                    typeLabel: '中医馆'
                },
                {
                    id:3,
                    typeLabel: '批发零售'
                }
            ],
            // form:{
            //     nickName:'',
            //     avatarUrl:'',
            //     code:'',
            //     mobile:'',
            //     gender:1,//1男，2女
            // },
            // rules: {
            //     nickName:[
            //         { required: true, message: '请输入昵称', trigger: 'blur' },
            //     ],
            //     avatarUrl:[
            //         { required: true, message: '请上传头像', trigger: 'blur' },
            //     ],
            //     code:[
            //         { required: true, message: '请输入验证码', trigger: 'blur' },
            //     ],
            //     mobile:[
            //         { required: true, message: '请输入手机号', trigger: 'blur' },
            //     ]
            // },
            formZz:{
                userType:1,
                // 正面
                obverseUrl: "",
                // 反面
                reverseUrl: "",
                yljgzyxkzUrl:'',
                ypjyxkzUrl:'',
                businessCardUrl:'',
                idCard:'',
                personName:'',
                personPhone:'',
                companyName:'',
                taxNumber:'',
                appendsUrl:[],//法人委托授权书
                cgwtsUrl:null,//采购委托书
                legalRepresentative:"",
                agent:'',
                jobNo:"",
                auditStatus:''
            },
            rulesZz:{
                companyName:[
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                taxNumber:[
                    { required: true, message: '请输入税号', trigger: 'blur' },
                ],
                reverseUrl:[
                    { required: true, message: '请上传身份证正反面', trigger: 'blur' },
                ],
                yljgzyxkzUrl:[
                    { required: true, message: '请上传医疗机构执业许可证或中医诊所备案证书', trigger: 'blur' },
                ],
                idCard:[
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                ],
                personName:[
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                personPhone:[
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                ],
                ypjyxkzUrl:[
                    { required: true, message: '请上传药品经营许可证', trigger: 'blur' },
                ],
                businessCardUrl:[
                    { required: true, message: '请上传营业执照', trigger: 'blur' },
                ],
                appendsUrl:[
                    {required: true, message: '请上传法人委托授权书', trigger: 'blur'}
                ],
                legalRepresentative:[
                    {required: true, message: '请输入法定代表人', trigger: 'change'}
                ],
                agent:[
                    {required: true, message: '请输入委托代理人', trigger: 'change'}
                ],
                jobNo:[
                    {required: true, message: '请输入销售员工号', trigger: 'change'}
                ]
            },
          
            urlImg:'',
            codeToTit:{
                1:'资质认证审核中，',
                2:'资质认证审核通过，',
                3:'资质认证审核拒绝，',
            },
            codeValue:'',
            fileOption:'',
            checked:false,
            dialogVisible:false
        }
    },
    mounted(){
        // if(localStorage.get('userInfo') || localStorage.get('userInfo').memberId){
            // this.active = 1
            // this.formZz.personPhone = localStorage.get('userInfo').username
            this.getQualified()
            // this.getAuditStatus()
        // }
       
    },
    methods:{
        cancel(){
            this.dialogVisible = false
        },
        showXy(){
            this.dialogVisible = true
        },
        handleRemove(file){
            this.formZz.appendsUrl = []
        },
        handleChange(file) {
           const obj = new FormData();
           this.fileOption = file.raw
            obj.append("file",file.raw)
            this.updatePic(obj,'appendsUrl')
        },
        clickDownloadTemplate(){
            let a = document.createElement("a");
            a.href = "https://www.boyaojt.com/%E6%8E%88%E6%9D%83%E5%A7%94%E6%89%98%E4%B9%A6%E6%A8%A1%E6%9D%BF.doc";
            a.download = "采购委托书.doc";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
        // 判断资质是否提交或审核通过
        getAuditStatus(){
            getAuditStatus().then(res=>{
                if(res.code === '00000'){
                    //审核状态 0注册成功1审核中2审核通过3审核拒绝
                   if(res.data == 1 || res.data == 2 || res.data == 3){
                        this.active = 1
                        this.codeValue = res.data
                    }
                }
            })
        },
        // 获取资质信息
        getQualified(){
            getQualified().then(res=>{
                if(res.code === '00000'){
                    if(res.data.idCard){
                        this.formZz = {
                            userType:res.data.userType * 1,
                            // 正面
                            obverseUrl: res.data.obverseUrl,
                            // 反面
                            reverseUrl: res.data.reverseUrl,
                            yljgzyxkzUrl: res.data.yljgzyxkzIUrl,
                            ypjyxkzUrl: res.data.ypjyxkzUrl,
                            businessCardUrl: res.data.businessCardUrl,
                            idCard:res.data.idCard,
                            personName:res.data.personName,
                            personPhone:res.data.personPhone,
                            companyName:res.data.companyName,
                            taxNumber:res.data.taxNumber,
                            legalRepresentative:res.data.legalRepresentative,
                            agent:res.data.agent,
                            jobNo:res.data.jobNo,
                            auditStatus: res.data.auditStatus
                        }
                        this.formZz['appendsUrl'] = [{
                            name:res.data.cgwtsUrl,
                            url:res.data.appendsUrl,
                        }]
                    }else{
                        this.formZz.personPhone = res.data.personPhone
                    }
                }
            })
        },
        // handleAvatarSuccess(type){
        //     this.formZz[type] = this.urlImg
        // },
        beforeAvatarUpload(file){
            const obj = new FormData();
            obj.append("file",file)
            this.updatePic(obj,'yljgzyxkzUrl')
        },
        beforeAvatarUpload2(file){
            console.log(file,'pllllll')
            const obj = new FormData();
            obj.append("file",file)
            this.updatePic(obj,'ypjyxkzUrl')
        },
        beforeAvatarUpload3(file){
            const obj = new FormData();
            obj.append("file",file)
            this.updatePic(obj,'businessCardUrl')
        },
       
        updatePic(obj,type){
            updatePic(obj).then(res=>{
                if(res.code === '00000'){
                    if(type == 'appendsUrl'){
                        this.formZz['appendsUrl'] = [{
                            name:this.fileOption.name,
                            url:res.data
                        }]
                        // this.formZz.appendsUrl = []
                        // this.formZz.appendsUrl.push({
                        //         name: this.fileOption.name,
                        //         url: res.data
                        //     })
                        this.$forceUpdate()
                        return
                    }
                    this.formZz[type] = res.data
                }
            })
        },
        // // 获取验证码
        // smsCode(){
        //     if(!this.form.mobile){
        //         return
        //     }
        //     smsCode({
        //         phoneNumber: this.form.mobile
        //     }).then(res=>{

        //     })
        // },
        // handleRemove(file, fileList) {
        //     console.log(file, fileList);
        // },
        // handlePictureCardPreview(file) {
        //     console.log(file,'pppppppppp')
        //     this.dialogImageUrl = file.url;
        //     this.dialogVisible = true;
        // },
        // next(){
        //     if(this.active < 2){
        //         if(this.active == 1)
        //             this.active += 1
        //         else
        //           this.active ++
        //     }
        // },
        // 身份证
        // 上传
        sfz_chagne(e) {
            this.formZz[e.type] = e.url
        },
        // 删除
        del_btn(e) {
            this.formZz[e.name] = ""
        },
        onSubmit(){
            this.$refs.formZz.validate((valid) => {
                if (valid) {
                    if(!this.checked){
                        this.$message.warning('请先同意协议')
                        return
                    }
                    this.uploadQualified()
                } else {
                    return false;
                }
            });
           
        },
        goLogin(){
            this.$router.push({
                path:'/home'
            })
        },
        uploadQualified(){
            console.log(this.formZz.taxNumber,'ppppp',this.formZz.taxNumber.length)
            if(this.formZz.taxNumber.length != 15 && this.formZz.taxNumber.length != 17 && this.formZz.taxNumber.length != 18 && this.formZz.taxNumber.length != 20){
                this.$message.warning('请输入正确的税号')
                return
            }
            let params = {
                userType:this.formZz.userType,
                personName:this.formZz.personName,
                personPhone:this.formZz.personPhone,
                obverseUrl:this.formZz.obverseUrl,
                reverseUrl:this.formZz.reverseUrl,
                idCard: this.formZz.idCard,
                companyName: this.formZz.companyName,
                taxNumber: this.formZz.taxNumber,
                appendsUrl: this.formZz.appendsUrl && this.formZz.appendsUrl.length ? this.formZz.appendsUrl[0].url : '',
                cgwtsUrl: this.formZz.appendsUrl && this.formZz.appendsUrl.length ? this.formZz.appendsUrl[0].name : '',
                legalRepresentative:this.formZz.legalRepresentative,
                agent:this.formZz.agent,
                jobNo:this.formZz.jobNo
            }
            if(this.formZz.userType == 1){
                params['yljgzyxkzUrl'] = this.formZz.yljgzyxkzUrl
            }else if(this.formZz.userType == 2 || this.formZz.userType == 3){
                params['ypjyxkzUrl'] = this.formZz.ypjyxkzUrl
                params['businessCardUrl'] = this.formZz.businessCardUrl
            }
            uploadQualified(params).then(res=>{
                if(res.code === '00000'){
                    this.active = 1
                    this.getAuditStatus()
                }
            })
        }
    }
}

</script>

<style lang="less" scoped>
.register{
    .head{
        width: 1200px;
        margin: auto;
        padding: 40px 0;
    }
    .img-logo{
        width: 160px;
    }
    .step-style{
        min-height: 650px;
        width: 1200px;
        margin: auto;
        margin-top: 20px;
        border-radius: 4px;
        box-shadow: 0 1px 20px 0 #d4d4d4;
        background-color: #fff;
        margin-bottom: 100px;
        &-top{
            // width: 800px;
            margin: auto;
            padding: 50px 320px;
            border-bottom: 1px dashed #d4d4d4;
        }
        .step-content {
           
            margin: auto;
            padding: 30px 50px 50px 0;
            &-form{
                width: 400px;
                margin: auto;
            }
            &-form1{
                width: 700px;
                margin: auto;
                padding-right: 100px;
                .xy-style{
                    border-bottom: 1px solid #409EFF;
                    padding-bottom: 1px;
                }
                .xy-style:hover{
                    color: #409EFF;
                    cursor: pointer;
                    
                }
            }
        }
        .btn{
            width: 100%;
            margin-top: 200px;
        }
    }
    /deep/ .el-steps--simple{
        background-color: #fff;
    }
    .info-style{
        margin-top: 10px;
    }
    .success-style{
        text-align: center;
        .success-icon{
            font-size: 130px;
            color: #67C23A;
            margin: 50px 0 30px;
            
        }
        .login-btns{
            color: #0f597c;
           
            cursor: pointer;
        }
    }
    .avatar{
        width: 146px;
        height: 146px;
        border-radius: 6px;
    }

    .form-module-item{
        margin-bottom: 6px;
    }
    .downMb{
		font-size: 14px;
		color: #3B5FD2;
        cursor: pointer;
	}
    .tip-xy{
		font-size: 12px;
		line-height: 20px;
        max-height: 500px;
        overflow-y: auto;
		&-tit{
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 17px;
		}
		&-topRight{
			text-align: right;
			margin-bottom: 18px;
		}
		&-value{
			
		}
		&-title{
			text{
				font-weight: bold;
				margin-right: 6px;
			}
		}
		.sj-style{
			text-indent: 2em
		}
		&-footer{
			display: flex;
			margin: 40px 0 30px;
			&-item{
				flex: 1;
				position: relative;
				.imgs-style{
					width: 80px;
					height: 80rpx;
					position: absolute;
					margin-top: -23px;
				}
			}
		}
		
	}
}
</style>