<template>
  <div class="order" :style="{'min-height': minHeight + 'px'}">
    <div class="tabs">
      <el-tabs v-model="activeName" class="tabs-list" @tab-click="handleClick">
        <el-tab-pane label="全部订单" name="0"></el-tab-pane>
        <el-tab-pane label="待付款" name="101"></el-tab-pane>
        <el-tab-pane label="待发货" name="301"></el-tab-pane>
        <el-tab-pane label="已发货" name="401"></el-tab-pane>
        <el-tab-pane label="已收货" name="501"></el-tab-pane>
      </el-tabs>
    </div>

    <div>
      <template v-if="dataList.length ? true : false">
        <!-- <div v-for="(item,index) in dataList" :key="index" class="card-module" @click="item.checked = !item.checked">
          <div :class="{'card-active': item.checked}" class="card" @click="detailBtn(item.id)">
            <img class="card-image" src="../../assets/sp.png"/>
            <div class="card-con">
              <div class="card-con-tit" style="color:#14639e;">订单编号：{{ item.orderNumber }}</div>
              <div class="card-con-gg" style="font-size: 18px">联系人：{{ item.userName }} 联系电话：{{ item.userTel }}</div>
              <div class="card-con-price">
                <span class="card-con-price-value">订单金额：{{ item.orderAmount | currency('￥')}}元</span>
                <span class="number">{{ item.userAddr }}</span>
              </div>
            </div>
            <div class="card-btns">
              <el-button round type="primary" @click="goPay(item)">付款</el-button>
              <el-button round @click="detailBtn(item.id)">订单详情</el-button>
            </div>
          </div>
        </div> -->
        <el-table
          :data="dataList"
          size="mini"
          style="width: 1000px;margin:50px auto;" border >
          
          <el-table-column label="订单编号" prop="orderSn" min-width="120"></el-table-column>
            <!-- <el-table-column label="商品名称" prop="spuName">

            </el-table-column> -->
            <el-table-column label="收货人姓名" prop="orderDeliveryDto.receiverName">
            </el-table-column>
            <el-table-column
                prop="orderDeliveryDto.receiverPhone"
                label="收货人联系电话"
                width="120">
              </el-table-column>
              <!-- <el-table-column
                prop="orderDeliveryDto.receiverDetailAddress"
                label="收货人地址"
                width="120">
              </el-table-column> -->
              <el-table-column
                prop="status"
                label="订单状态"
                width="120">
                <template slot-scope="scope">
                  <el-button :type="statusColor[scope.row.status]" size="mini" plain>{{ statusTit[scope.row.status] || '' }}</el-button>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalAmount"
                label="订单金额（元）" align="center"
                width="120">
                <template slot-scope="scope">{{ scope.row.totalAmount | currency }}</template>
              </el-table-column>
              <el-table-column
                prop="totalAmount"
                label="运费（元）" align="center"
                >
                <template slot-scope="scope">{{ (scope.row.payAmount - scope.row.totalAmount) | currency }}</template>
              </el-table-column>
              <el-table-column
                prop="payAmount"
                label="支付金额（元）" align="center"
                width="120">
                <template slot-scope="scope">{{ scope.row.payAmount | currency }}</template>
              </el-table-column>
              <el-table-column
                prop="zip"
                label="操作"
                min-width="120">
                <template slot-scope="scope">
                  <!-- <el-button
                    type="text"
                    size="small" v-if="scope.row.status == 101">
                    付款
                  </el-button> -->
                  <el-button
                    type="text"
                    size="small" @click="detailBtn(scope.row)">
                    订单详情
                  </el-button>
                  <el-button type="text" size="small" v-if="scope.row.status == '102' || scope.row.status == '103'" @click="deleteBtn(scope.row)">删除</el-button>
                </template>
              </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <el-empty  description="没有数据" style="margin:50px auto 0"></el-empty>
      </template>
      <el-pagination
          v-if="dataList.length ? true : false"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :total="pagination.total"
          class="pagination-style"
          layout="total, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getListPage,deleteOrder} from '@/api'

export default {
  data() {
    return {
      keyword: "",
      pagination: {
        total: 0,
        size: 10,
        page: 0
      },
      activeName: '0',
      dataList: [],
      form: {
        orderSn: '',
        beginDate: '',
        endDate:'',
        pageNum: 1,
        pageSize: 10,
        status:'',
      },
      statusTit:{
        101:'待付款',
        102:'用户取消',
        103:'系统取消',
        201:'已付款',
        202:'申请退款',
        203:'已退款',
        204: '待退货',
        205: '待退款',
		    206: '拒绝退款' ,
        301:'待发货',
        401:'已发货',
        501: '已收货',
        502: '系统收货',
        601: '申请开票',
        602: '开票-未寄出',
        603: '开票-已寄出',
        901: '已完成'
      },
      statusColor:{
        101:'primary',
        102:'info',
        103:'info',
        201:'primary',
        202:'warning',
        203:'info',
        204: 'primary',
        205: 'primary',
		    206: 'warning' ,
        301:'warning',
        401:'warning',
        501: 'success',
        502: 'success',
        601: 'primary',
        602: 'warning',
        603: 'warning',
        901: 'success'
      },
      minHeight:''
      
    }
  },
  created() {
    this.getListPage()
    this.minHeight = this.$store.state.minHeight || ''
  },
  methods: {
    deleteBtn(row){
      this.$confirm('此操作将删除该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.deleteOrder(row.id)
      }).catch((e) => {
        this.$message.info('已取消删除')
      })
      
    },
    // 删除订单
    deleteOrder(id){
      deleteOrder(id).then(res=>{
        if(res.code === '00000'){
          this.$message.success('操作成功')
          this.form.pageNum = 1
          this.getListPage()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    detailBtn(row) {
      this.$router.push({
        path:'orderDetail',
        query:{
            id: row.id,
        }
      })
    },
    goPay(item) {
      this.$router.push({
        path: '/pay?orderno=' + item.orderNumber
      })
    },
    handleSizeChange(val) {
      this.pagination.size = val
      this.pagination.page = 1
      this.form.pageSize = val
      this.getListPage();
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.form.pageNum = val
      this.getListPage();
    },
    handleClick(val, event) {
      console.log(val,'ppppppp')
      this.form.status = parseInt(val.name)
      this.getListPage()
    },
    getListPage() {
      getListPage({
        orderSn: '',
        beginDate: '',
        endDate:'',
        pageNum: this.form.pageNum,
        pageSize: this.form.pageSize,
        status:this.form.status
      }).then(res => {
        this.dataList = res.data.list ? res.data.list.list : []
        this.pagination.total = res.data.total;
      })
    }
  },
  filters:{
      currency(value){
        if(value){
          return (value / 100).toFixed(2)
        }else return '0.00'
        
      }
    }
}
</script>

<style lang="less" scoped>
.order {
  // max-width: 1000px;
  margin: auto;
  background-color: #fff;
  .tabs {
    background-color: #fff;
    margin-top: 30px;
    .tabs-list {
      max-width: 1000px;
      margin: auto;
      padding: 0 20px;
    }

    /deep/ .el-tabs__header {
      margin-bottom: 0;
    }

    /deep/ .el-tabs__item.is-active {
      color: #fa436a
    }

    /deep/ .el-tabs__item:hover {
      color: #fa436a
    }

    /deep/ .el-tabs__active-bar {
      background-color: #fa436a
    }
  }

  .card-module {
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 1000px;
    margin: 20px auto 0;

    .card {
      display: flex;
      background-color: #fff;
      width: 100%;
      margin-left: 20px;
      border-radius: 8px;
      overflow: hidden;
      padding: 2px 20px;
      border: 1px solid #fff;

      &-image {
        height: 140px;
        margin-right: 20px;
      }

      &-con {
        flex: 1;
        padding: 10px 0;

        &-tit {
          font-size: 20px;
          margin-bottom: 10px;
        }

        &-price {
          margin-top: 20px;
          display: flex;
          align-items: center;

          &-value {
            color: red;
            font-size: 18px;
          }

          .number {
            margin-left: 100px;
            font-size: 16px;
          }
        }

        .shop-detail-item-btns {
          display: flex;
          color: #333 !important;
          align-items: center;
          border: 1px solid #eee;
          border-radius: 8px;
          text-align: center;
          overflow: hidden;

          .btn-j {
            font-size: 20px;
            width: 40px;
            cursor: pointer;
            height: 32px;
            line-height: 32px;
          }

          .btn-j:hover {
            background: #eee;
          }

          .input-value {
            width: 60px;
            text-align: center;
          }

        }
      }

      &-btns {
        display: flex;
        align-items: center;
      }
    }

    .card:hover {
      box-shadow: 0 0 20px rgba(20, 99, 158, .2);
    }

    .card-active {
      box-shadow: 0 0 20px rgba(20, 99, 158, .2);
    }

  }

  /deep/ .el-checkbox__inner {
    width: 22px;
    height: 22px;
  }

  /deep/ .el-input__inner {
    border: 1px solid #eee;
    // border-left: 1px solid #eee;
    // border-right: 1px solid #eee;
    text-align: center;
  }

  /deep/ .el-checkbox__inner::after {
    height: 8px;
    left: 8px;
    top: 4px;
  }

  .pagination-style {
    text-align: right;
    width: 1000px;
    margin: 50px auto;
  }
}
</style>
