import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/index.vue'
import Home from '@/views/home/index.vue'
import Login from '@/views/login.vue'
import Register from '@/views/info/register.vue'
import Qualification from '@/views/info/qualification.vue'
import Product from '@/views/product/index.vue'
import ProductList from '@/views/product/list.vue'
import ShopCart from '@/views/product/shopCart.vue'
import Address from '@/views/address/index.vue'
import Order from '@/views/order/index.vue'
import OrderDetail from '@/views/order/detail.vue'
import ConfirmOrder from '@/views/confirmOrder/index.vue'
import Pay from '@/views/order/pay.vue'
import Coupon from '@/views/coupon/index.vue'
import Ysty from '@/views/ysty/index.vue'
// import Address from '@/views/address/index.vue'
// import Zzrz from '@/views/zzrz.vue'
// import Pay from "@/views/pay";
// import ConfirmOrder from "@/views/confirmOrder";

Vue.use(VueRouter)


export const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path:'product',
    name:'Product',
    component: Product
  },
  {
    path:'productList',
    name:'ProductList',
    component:ProductList
  },
  {
    path:'shopCart',
    name:'ShopCart',
    component: ShopCart
  },
  {
    path:'address',
    name:'Address',
    component: Address
  },
  {
    path:'order',
    name:'Order',
    component: Order
  },
  {
    path:'orderDetail',
    name:'OrderDetail',
    component:OrderDetail
  },
  {
    path:'confirmOrder',
    name:'ConfirmOrder',
    component:ConfirmOrder
  },
  {
    path:'pay',
    name:'Pay',
    component:Pay
  },
  {
    path:'coupon',
    name:'Coupon',
    component:Coupon
  },
  {
    path:'ysty',
    name:'Ysty',
    component:Ysty
  }
 
]

const router = new VueRouter({
  routes:[
    {
      path:'/',
      redirect:'/home',
      name:'Main',
      meta:{
        title:'pc商城'
      },
      component: Layout,
      children: routes
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component:Register
    },
    {
      path:'/qualification',
      name:'Qualification',
      component:Qualification
    }
  ]
})

export default router
