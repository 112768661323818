<template>
    <div class="product-list" :style="{'min-height': minHeight + 'px'}">
        <div class="product-list1">
            <!-- 规格 -->
            <div>

            </div>
            <ul class="select-module">
                <li :class="{'active-style': activeId === 'zh'}" @click="zhSortBtn">综合排序</li>
                <li :class="{'active-style': activeId === 'xl'}" @click="salesBtn">销量</li>
                <li  class="select-price" >
                    <span :style="{color: activeId === 'price' ? '#fa436a':'#333'}">价格</span>
                    <div class="select-price-btn">
                        <div class="el-icon-caret-top" :style="{color: (activeId === 'price' && sort == 'desc') || activeId != 'price' ? '#333':'#fa436a'}" @click="sortBtn('desc')"></div>
                        <div class="el-icon-caret-bottom" :style="{color: (activeId === 'price' && sort == 'asc') || activeId != 'price' ? '#333':'#fa436a'}" @click="sortBtn('asc')"></div>
                    </div>
                </li>
                <li>共 {{ paginationConfig.total }} 种商品</li>
            </ul>
            <ul class="select-con">
                <li v-for="(item,index) in dataList" :key="index" class="select-con-item" @click="goDetail(item)">
                    <div class="select-con-item-imgs">
                        <img :src="item.picUrl" class="imgs-url"/>
                    </div>
                    <div class="select-con-item-title">{{ item.name }}</div>
                    <div class="select-con-footer">
                        <div class="select-con-footer-price" v-if="$store.state.user.auditStatus == 2 || item.type == 1"><span>￥</span> {{ item.price |currency }}</div>
                        <div class="select-con-footer-price" v-else>认证可见</div>
                        <div class="select-con-footer-sales">销量：{{ item.sales }}</div>
                    </div>
                </li>
            </ul>
            <el-pagination
                v-if="dataList.length"
                class="pagination"
                @current-change="handleCurrentChange"
                :current-page="paginationConfig.page"
                :page-size="30"
                layout="total, prev, pager, next, jumper"
                :total="paginationConfig.total">
            </el-pagination>
        </div>
        
    </div>
</template>

<script>
import { spuList,hotSell, recommended } from '@/api'
    export default{
        data(){
            return{
                total:0,
                dataList:[],
                paginationConfig:{
                    total:0,
                    page:1,
                },
                id:'',
                type:'',
                keywords:'',
                minHeight:'',
                sortField:'',//price
                sort:'',
                activeId:''
            }
        },
        mounted(){
            this.paginationConfig.total = this.dataList.length
            console.log(this.$route.query.id)
            this.id = this.$route.query.id || ''
            this.type = this.$route.query.type || ''
            this.keywords = this.$route.query.keywords || ''
            if(this.type == 'hot'){
                this.hotSell()
            }else if(this.type == 'tj'){
                this.recommended()
            }else{
                this.sort = 'desc'
                this.activeId = 'zh'
                this.spuList()
            }
            this.minHeight = this.$store.state.minHeight || ''
        },
        methods:{
            sortBtn(type){
                if(this.type != 'hot' && this.type != 'tj'){
                    this.activeId = 'price'
                    this.sort = type
                    this.sortField = 'price'
                    this.paginationConfig.page = 1
                    this.spuList()
                }
            },
            salesBtn(){
                if(this.type != 'hot' && this.type != 'tj'){
                    this.activeId = 'xl'
                    this.sort = 'desc'
                    this.sortField = 'sales'
                    this.paginationConfig.page = 1
                    this.spuList()
                }
                
            },
            zhSortBtn(){
                if(this.type != 'hot' && this.type != 'tj'){
                    this.activeId = 'zh'
                    this.sort = 'desc'
                    this.sortField = ''
                    this.paginationConfig.page = 1
                    this.spuList()
                }
                
            },
            // 热销商品
            hotSell(){
                hotSell().then(res=>{
                    if(res.code === '00000'){
                        this.dataList = res.data || []
                        this.paginationConfig.total = this.dataList.length
                    }
                })
            },
            // 推荐商品
            recommended(){
                recommended().then(res=>{
                    if(res.code === '00000'){
                        this.dataList = res.data || []
                        this.paginationConfig.total = this.dataList.length
                    }
                })
            },
            // 获取商品列表
            spuList(){
                let params = {
                    page: this.paginationConfig.page,
                    pageSize: 30,
                    categoryId: this.id || '',
                    keyword: this.keywords,
                    // sortField: this.sortField,
                    sort: this.sort
                }
                if(this.sortField){
                    params['sortField'] = this.sortField
                }
                spuList(params).then(res=>{
                    if(res.code === '00000'){
                        this.dataList = res.data.list ? res.data.list.list : []
                        this.paginationConfig.total = res.data.total
                    }
                })
            },
            goDetail(item){
                this.$router.push({
                    path:`/product?productId=${item.id}`
                })
            },
            handleSizeChange(){
                
            },
            handleCurrentChange(val){
                this.paginationConfig.page = val
                this.spuList()
            },
            currentPage(){
                    
            }
        },
        filters:{
            currency(value){
            if(value){
                return value / 100
            }else return '0.00'
            
            }
        },
        watch:{
            $route: {
                handler(val) {
                    this.keywords = this.$route.query.keywords || ''
                    if(val.query.type == 'tj'){
                        this.recommended()
                    }else if(val.query.type == 'hot'){
                        this.hotSell()
                    }else{
                        this.spuList()
                    }
                },
                deep: true
            },
        }
    }
</script>

<style lang="less" scoped>
.product-list{
    background-color: #fff;
}
.product-list1{
    max-width: 1190px;
    margin: auto;
    .select-module{
        display: flex;
        background-color: #edeeef;
        line-height: 50px;
        margin-top: 20px;
        li{
            padding: 0 30px;
            cursor: pointer;
        }
        li:last-child{
            margin-left: auto;
        }
        // li:hover{
        //     color: #fa436a;
        // }
        .select-price{
            display: flex;
            align-items: center;
            &-btn{
                display: flex;
                width: 20px;
                flex-wrap: wrap;
            }
            // .active-style1{
            //     color: #fa436a !important;
            // }
        }
    }
    .select-con{
        display: flex;
        flex-wrap: wrap;
        &-item{
            width: 20%;
            box-sizing: border-box;
            border: 1px solid #eee;
            padding: 15px 20px 25px;
            cursor: pointer;
            &-imgs{
                width: 100%;
                padding: 20px 0;
                text-align: center;
                .imgs-url{
                    height:110px;
                    margin: auto;
                }
            }
            &-title{
                font-weight: bold;
                font-size: 16px;
                margin-top: 10px;
            }
           
            .select-con-footer{
                display: flex;
                margin-top: 15px;
                justify-content: space-between;
                &-price{
                    color: red;
                    span{
                        font-size: 12px;
                    }
                }
                &-sales{
                    font-size: 12px;
                    color: #b4b4b4;
                }
            }
        }
        &-item:hover{
            // border-color: #0f597c;
            border-color: rgba(217, 60, 93, 0.72);
        }
    }
    .pagination{
        text-align: right;
        margin: 30px 0;
    }
}
.active-style{
    color: #fa436a;
}
</style>