import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/common.less';
import '@/assets/base.less';

import '@/assets/iconfont/iconfont.js'
import '@/assets/iconfont/iconfont.css'

import Element from 'element-ui';




Vue.use(Element)

Vue.config.productionTip = false
Vue.prototype.$store = store

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
