<template>
  <el-dialog
    :title="title"
    :visible.sync="visibleDetail"
    width="500px"
    class="quoted-price"
  >
    <div class="form">
      <el-form :model="formInfo" :rules="rules" ref="formInfo" label-width="100px">
          <el-form-item label="收货人" prop="consigneeName">
            <div class="price-module">
              <el-input class="value-module" placeholder="请输入姓名" v-model="formInfo.consigneeName"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="手机号" prop="consigneeMobile">
            <el-input class="value-module" placeholder="请输入手机号" maxlength="11" v-model="formInfo.consigneeMobile"></el-input>
          </el-form-item>
          <el-form-item label="所在地区" prop="selectedOptions">
            <el-cascader class="value-module" size="large" :options="options" v-model="formInfo.selectedOptions" @change="handleChange"></el-cascader>
            <!-- <el-cascader class="value-module" v-model="formInfo.area" :options="areaOptions" :props="{ expandTrigger: 'hover' }" ></el-cascader> -->
          </el-form-item>
          <el-form-item label="详细地址" prop="detailAddress">
            <el-input class="value-module" v-model="formInfo.detailAddress" type="textarea" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="是否默认">
            <el-radio v-model="formInfo.defaulted" :label="1">是</el-radio>
            <el-radio v-model="formInfo.defaulted" :label="0">否</el-radio>
          </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="flex-jac">
      <el-button @click="cancelBtn">取消</el-button>
      <el-button type="primary" @click="addAddressBtn">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addAddress,editAddress,getAddressDetail } from '@/api'
// import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import {lotusAddressJson} from './lotusAddress.js'
import {lotusAddressCode} from './lotusAddressCode.js'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String || Number,
      default: '',
    }
  },
  computed: {
    visibleDetail: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
  name:'quotedPrice',
  data() {
    return {
      options: lotusAddressJson,
      formInfo:{
        consigneeName:'',
        consigneeMobile:'',
        detailAddress:'',
        province:'',
        city:'',
        area:'',
        defaulted:0,
        selectedOptions: [],
      },
      rules: {
        consigneeName:[
            { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        consigneeMobile:[
            { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        selectedOptions: [
            { required: true,message: '请选择地区', trigger: 'change' }
        ],
        detailAddress: [
            { required: true,message: '请输入详细地址', trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      fileList:[],
      areaOption:[],
      title:'',
    };
  },
  watch:{
    id(val,valOld){
      console.log(val,'kl',valOld)
      if(this.id){
        this.getAddressDetail()
      }
    }
  },
  mounted(){
    this.title = this.id ? '编辑地址':'新增地址'
    lotusAddressJson.forEach(v=>{
      
    })
  },
  methods: {
      getAddressDetail(){
        getAddressDetail(this.id).then(res=>{
          if(res.code === '00000'){
            this.formInfo = {
              consigneeName:res.data.consigneeName,
              consigneeMobile:res.data.consigneeMobile,
              detailAddress:res.data.detailAddress,
              province:res.data.province,
              city: res.data.city,
              area: res.data.area,
              defaulted:res.data.defaulted,
            }
            
            let provinceCode = this.textToCode(res.data.province).value
            let cityCode = res.data.city ? this.textToCode(res.data.province,res.data.city).value : ''
            let areaCode = res.data.area ? this.textToCode(res.data.province,res.data.city,res.data.area).value : ''
            this.formInfo['selectedOptions'] = areaCode ? [provinceCode,cityCode,areaCode] : [provinceCode,cityCode]
            console.log( this.formInfo.selectedOptions)
          }
        })
      },
      // textToCode
      textToCode(province,city,area){
        let dataValue = {}
        lotusAddressJson.some(v=>{
          if(v.label == province){
            if(city){
              v.children && v.children.some(v1=>{
                if(v1.label == city){
                  if(area){
                    v1.children && v1.children.forEach(v2=>{
                      if(v2.label == area){
                        dataValue = v2
                        return false
                      }
                    })
                  }else{
                    dataValue = v1
                    return false
                  }
                  
                }
              })
            }else{
              dataValue = v
              return false
            }
          }
        })
        return dataValue
      },
      handleCommand(val){
        console.log(val,'val')
        this.formInfo.unit = `元/${val}`
      },
      handleRemove(file, fileList) {
        console.log(file, 'ppppppppppppppp',fileList);

      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleChange(e) {
        // var loc = "";
        // for (let i = 0; i < this.formInfo.selectedOptions.length; i++) {
        //   loc += CodeToText[this.formInfo.selectedOptions[i]];
        // }
        console.log(e,'e')
        this.formInfo.province = lotusAddressCode[this.formInfo.selectedOptions[0]]
        this.formInfo.city = lotusAddressCode[this.formInfo.selectedOptions[1]]
        this.formInfo.area = this.formInfo.selectedOptions.length > 2 ? lotusAddressCode[this.formInfo.selectedOptions[2]] : ''
        // console.log(loc);
      },
      addAddressBtn() {
        this.$refs.formInfo.validate((valid) => {
            if (valid) {
              let params = {
                consigneeName: this.formInfo.consigneeName,
                consigneeMobile : this.formInfo.consigneeMobile,
                detailAddress : this.formInfo.detailAddress,
                province : this.formInfo.province,
                city : this.formInfo.city,
                area : this.formInfo.area,
                defaulted : this.formInfo.defaulted
              }
              if(this.id){
                params['id'] = this.id
                this.editAddress(params)
              }else{
                this.addAddress(params)
              }
            } else {
                return false;
            }
        });
      },
      // 添加地址
      addAddress(params){
        addAddress(params).then(res=>{
          if(res.code === '00000'){
            this.$message.success('新增成功')
            this.cancelBtn()
            this.$parent.getAddressList()
          }else{
            this.$message.error(res.message)
          }
        })
      },
      // 编辑地址
      editAddress(params){
        editAddress(params).then(res=>{
          if(res.code === '00000'){
            this.$message.success('编辑成功')
            this.cancelBtn()
            this.$parent.getAddressList()
          }else{
            this.$message.error(res.message)
          }
        })
      },
      cancelBtn(){
        this.$emit('closeq')
        this.$refs.formInfo.resetFields();
        this.formInfo.province = ''
        this.formInfo.city = ''
        this.formInfo.area = ''
      },
      getAddressById(id){
        const that = this;
        // getAddressById({
        //       id: id
        // }).then(res=>{
        //     if(res.code == 0){
        //       that.formInfo.deliveryname = res.data.deliveryname!=null?res.data.deliveryname:'';
        //       that.formInfo.deliverytel = res.data.deliverytel!=null?res.data.deliverytel:'';
        //       that.formInfo.detailaddr = res.data.detailaddr!=null?res.data.detailaddr:'';
        //       that.formInfo.province = res.data.province!=null?res.data.province:'';
        //       that.formInfo.city = res.data.city!=null?res.data.city:'';
        //       that.formInfo.counties = res.data.counties!=null?res.data.counties:'';
        //       that.formInfo.ismoren = res.data.ismoren!=null?res.data.ismoren:2;

        //       let prcode = TextToCode[res.data.province].code
        //       let citycode = TextToCode[res.data.province][res.data.city].code
        //       let adcode = TextToCode[res.data.province][res.data.city][res.data.counties].code
        //       that.selectedOptions = [ prcode,citycode,adcode ]//省市区回显,不要push,输入、里面级联可直接回显

        //       that.$emit("openq")
        //     }
        // })
      }
  },
};
</script>

<style lang="less" scoped>
.quoted-price{
  .form{
    padding: 0 20px;
    .value-module{
        width: 90%;
    }
   
  }
   .flex-jac{
        text-align: center;
        width: 100%;
    }
}
</style>
