<template>
    <el-dialog
        title="仅退款/退货退款"
        :visible.sync="dialogVisible"
        width="30%"
        @close="cancel">
        <div class="content">
           <div class="content-list" v-for="(item,index) in dataValue" :key="index">
                <el-image class="img-style" :src="item.picUrl" fit="contain"></el-image>
                <div>
                    <div class="content-list-name">
                        <span>{{ item.spuName }}</span>
                        <span class="content-list-price">{{ item.price | currency }} <span class="price-item">* {{ item.count }}</span></span>
                    </div>
                    <div class="content-list-subtit">{{ item.skuName }}</div>
                </div>
           </div>
           <div style="border-top: 1px solid #DCDFE6;padding-top: 16px;">
                <el-radio-group v-model="refundValue">
                    <el-radio :label="item.value" v-for="item in refundTypeArr" :key="item.value">{{ item.name }}</el-radio>
                </el-radio-group>
                <div class="content-reason">
                    <span class="content-reason-tit">退款原因</span>
                    <el-select v-model="refundReason" placeholder="请选择">
                        <el-option
                        v-for="item in optionsCon"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="content-reason">
                    <span class="content-reason-tit" style="width: 130px;">退货/退款理由</span>
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="宝贝不满足您的期待吗？请填写一下您的退货/退款理由吧"
                        v-model="textarea">
                    </el-input>
                </div>
                
                <div class="content-reason">
                    <span class="content-reason-tit" >退款金额</span>
                    {{ payAmount | currency }}
                </div>
                <div class="tip">成功退款后，该订单的金额会退回至原支付账户</div>
           </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleOrderRefundApply">确 定</el-button>
        </span>
    </el-dialog>

</template>

<script>
import {refundApply} from '@/api'
export default {
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        dataValue:{
            type:Array,
            default:[]
        },
        payAmount:{
            type:String,
            default:''
        },
        id:{
            type:String,
            default:''
        }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit('update:visible', val);
            },
        },
    },
    data(){
        return{
            refundValue: 2,
            refundTypeArr: [
                {
                    value: 1,
                    name: '仅退款'
                },
                {
                    value: 2,
                    name: '退货退款'
                }
            ],
            optionsCon:[
                {
                    value: 0,
                    label: '商品信息描述不符'
                },
                {
                    value: 1,
                    label: '质量问题'
                },
                {
                    value: 2,
                    label: '包装/商品损坏/污渍'
                },
                {
                    value: 3,
                    label: '未按约定时间发货'
                },
                {
                    value: 4,
                    label: '卖家发错货'
                },
                {
                    value: 5,
                    label: '其他'
                },
            ],
            textarea:'',
            refundReason:''
        }
    },
    mounted(){
      
    },
    methods:{
        handleOrderRefundApply() {
            
            if (this.refundReason == '') {
                this.$message.warning('请选择退款原因')
                return
            }

            let params = {
                id: this.id,
                refundReason: this.optionsCon[this.refundReason*1].label,
                refundExplain: this.textarea,
                refundType: this.refundValue
            }
            console.log('pppppppppppp',params)
            refundApply(params).then(res => {
               if(res.code == '00000'){
                    this.$message.success('操作成功')
                    this.cancel()
                    this.$emit('refreshBtn')
               }else{
                    this.$message.error(res.msg)
               }
            })

        },
        
        cancel(){
            this.dialogVisible = false
        }
    },
    filters:{
        currency(value){
            if(value){
                return value / 100
            }else return '0.00'
        },
    }
}
</script>

<style lang="less" scoped>
.content{
    padding: 0 20px;
    .content-list{
        display: flex;
        margin-bottom: 10px;
        
        &-name{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 12px;
            display: flex;
            .content-list-price{
                margin-left: auto;
                color: red;
                .price-item{
                    font-weight: 400;
                    font-size: 12px;
                    color: #333;
                }
            }
        }
        
        .img-style{
            width: 100px;
            height: 80px;
            margin-right: 16px;
        }
    }
    .content-reason{
        margin: 16px 0;
        display: flex;
        
        &-tit{
            margin-right: 20px;
            width: 100px;
        }
    }
    .tip{
        font-size: 12px;
        color: #979696;
        border-top: 1px solid #DCDFE6;
        padding-top: 6px;
    }
}
</style>