<template>
    <div class="order" :style="{'min-height': minHeight + 'px'}">
      <div class="tabs">
        <el-tabs v-model="activeName" class="tabs-list" @tab-click="handleClick">
          <el-tab-pane label="未使用" name="0"></el-tab-pane>
          <el-tab-pane label="已使用" name="1"></el-tab-pane>
          <el-tab-pane label="已过期" name="3"></el-tab-pane>
        </el-tabs>
      </div>
  
      <div>
        <template v-if="dataList.length ? true : false">
            <div class="coupon">
                
				<div class="coupon-item" v-for="(dataItem,index) in dataList" :key="index">
                    <div class="coupon-item-left " :class="{'gq-color':dataItem.status != 0}">
                        <div><text class="unit">￥</text>{{dataItem.usedAmount | currency}}</div>
                        <div>满{{dataItem.withAmount | currency}}元使用</div>
                    </div>
                    <div class="line"></div>
                    <div class="coupon-item-center">
                        <div :class="{'color1':dataItem.status != 0 }">{{dataItem.title}}</div>
                        <div>{{typeToTit[dataItem.type]}}</div>
                        <div class="coupon-item-center-date">{{dataItem.startTime | dateFormatter}}-{{dataItem.endTime | dateFormatter}}</div>
                    </div>
                    <div class="coupon-item-btn" v-if="dataItem.status == 0" @click="goUse">去使用</div>
                    <div class="color1 no-info" v-else>
                        <div class="no-info-item">{{statusConfig[dataItem.status]}}</div>
                    </div>
                </div>
			</div>
        </template>
        <template v-else>
          <el-empty  description="暂无优惠券" style="margin:50px auto 0"></el-empty>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  import {myCouponList} from '@/api'
  
  export default {
    data() {
      return {
        keyword: "",
        activeName: '0',
        dataList: [],
        
        minHeight:'',
        statusConfig:{
            0:'去使用',
            1:'已使用',
            3:'已过期',
        },
        typeToTit:{
            1:'满减券' ,
            2:'叠加满减券',
            3:'无门槛券'
        },
        status:'0'
      }
    },
    created() {
      this.getListPage()
      this.minHeight = this.$store.state.minHeight || ''
    },
    methods: {
      
      handleClick(val, event) {
        console.log(val.name,'ppppppp')
        this.status = val.name
        this.getListPage()
      },
      getListPage() {
        myCouponList(this.status).then(res => {
          this.dataList = res.data.list || []
          console.log(this.dataList,'this.dataList')
        })
      },
      goUse(){
        this.$router.push({
            path:'/home'
        })
       }
    },
    filters:{
        currency(value){
            if(value){
                return parseFloat((value / 100).toFixed(2))
            }else return '0.00'
        },
        dateFormatter(time) {
            return time ? time.substring(0,11) : ''
        }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .order {
    // max-width: 1000px;
    margin: auto;
    background-color: #fff;
    .tabs {
      background-color: #fff;
      margin-top: 30px;
      .tabs-list {
        max-width: 1000px;
        margin: auto;
        padding: 0 20px;
      }
  
      /deep/ .el-tabs__header {
        margin-bottom: 0;
      }
  
      /deep/ .el-tabs__item.is-active {
        color: #fa436a
      }
  
      /deep/ .el-tabs__item:hover {
        color: #fa436a
      }
  
      /deep/ .el-tabs__active-bar {
        background-color: #fa436a
      }
    }
  
    .coupon{
        padding: 20px 50px;
        width: 1000px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        .coupon-item{
            display: flex;
            width: 48%;
            background-color: #fff;
            margin-bottom: 20px;
            border-radius: 6px;
            overflow: hidden;
            align-items: center;
            box-shadow: 0 0 10px #ddd;
            margin-right: 4%;
            position: relative;
            &-left{
                width: 32%;
                border-left: 6px solid rgb(240,50,50);
                text-align: center;
                padding: 20px 0;
                font-size: 12px;
                color: rgb(240,50,50);
                div:first-child{
                    margin-bottom: 8px;
                    font-size: 30px;
                    font-weight: bold;
                }
                .unit{
                    font-size: 14px;
                    width: 40px;
                    
                }
            }
            .line{
                border-right: 1px dashed rgb(153,153,153);
                height: 75px;
            }
            &-center{
                flex: 1;
                font-size: 12px;
                padding-left: 13px;
                color: rgb(148, 148, 148);
                view:first-child{
                    font-size: 16px;
                    font-weight: bold;
                    color: #333;
                    margin-bottom: 6px;
                }
                &-date{
                    margin-top: 16px;
                }
                
            }
            &-btn{
                background: linear-gradient(to right, #fd8f59, #f03232);
                color: #fff;
                width: 66px;
                border-radius: 4px;
                font-size: 14px;
                text-align: center;
                line-height: 32px;
                margin: 0 30px;
                box-shadow: 0 0px 8px #ff7d7e;
                cursor: pointer;
            }
            .gq-color{
                color: rgb(148, 148, 148);
                border-left: 6px solid rgb(148, 148, 148);
            }
            .no-info{
                position: absolute;
                width: 75px;
                border: 1px solid rgb(148,148,148);
                text-align: center;
                height: 75px;
                border-radius: 50%;
                right: 9%;
                transform: rotate(-45deg);
                &-item{
                    border: 1px solid rgb(148,148,148);
                    margin-top: 25px;
                    font-weight: bold;
                    width: 90px;
                    margin-left: -10px;
                    background-color: #fff;
                }
            }
            .color1{
                color: rgb(148, 148, 148) !important;
            }
        }
        .coupon-item:nth-child(2n){
            margin-right: 0;
        }
        .empty-item{
            text-align: center;
            padding: 80px 0;
            color: rgb(148, 148, 148);
        }
    }
  
    /deep/ .el-checkbox__inner {
      width: 22px;
      height: 22px;
    }
  
    /deep/ .el-input__inner {
      border: 1px solid #eee;
      // border-left: 1px solid #eee;
      // border-right: 1px solid #eee;
      text-align: center;
    }
  
    /deep/ .el-checkbox__inner::after {
      height: 8px;
      left: 8px;
      top: 4px;
    }
  
    .pagination-style {
      text-align: right;
      width: 1000px;
      margin: 50px auto;
    }
  }
  </style>
  