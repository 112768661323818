<template>
    <div class="shop-cart" :style="{'min-height': minHeight + 'px'}">
        <div v-if="cartList.length" class="shop-cart-module">
            <el-table ref="multipleTable"
                :data="cartList" align="center" border
                class=""
                stripe
                style="width: 100%"
                tooltip-effect="dark"
               
                >
                
                <el-table-column align="center" label="" width="80">
                    <template v-slot="scope">
                      <el-checkbox v-model="scope.row.checked" @change="changeBtn(scope.row)"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="图片" width="100">
                    <template v-slot="scope">
                        <el-popover
                            placement="right"
                            trigger="hover"
                        >
                        <el-image
                            slot="reference"
                            :src="scope.row.picUrl"
                            fit="cover"
                            style="width: 50px; height: 50px"
                        />
                        <el-image
                            :src="scope.row.picUrl"
                            fit="cover"
                            style="width: 220px; height: 220px"
                        />
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="商品名称" prop="title">
                    <template v-slot="scope">
                        <a @click="toGoodsDetail(scope.row.id)">{{ scope.row.spuName }}</a>
                    </template>
                </el-table-column>
                
                <el-table-column label="商品库存名称" prop="skuName" min-width="160"></el-table-column>
                <el-table-column align="right" header-align="center" label="单价（元）" prop="price" >
                <template v-slot="scope">
                    <div>{{ scope.row.price | currency}}</div>
                </template>
                </el-table-column>
                <el-table-column label="优惠券金额" prop="coupon" />
                <el-table-column label="数量" prop="count" min-width="100">
                    <template v-slot="scope">
                        <div class="shop-detail-item-btns">
                        <div class="btn-j" @click="subtractAmountCount(scope.row)"> -</div>
                        <el-input v-model="scope.row.count" class="input-value" size="small" @input="cartGoodsAmountChange($event,scope.row)"></el-input>
                        <div class="btn-j" @click="addAmountCount(scope.row)"> +</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="right" header-align="center" label="小计（元）" >
                    <template v-slot="scope">
                        <div>{{ (scope.row.price * scope.row.count) | currency }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="80">
                <template v-slot="scope">
                    <el-tooltip class="item" content="删除" effect="dark" placement="top">
                    <el-button
                        icon="el-icon-delete"
                        size="mini"
                        type="danger"
                        @click="deleteCartGoods(scope)"/>
                    </el-tooltip>
                </template>
                </el-table-column>
            </el-table>
        
            <div class="cart-action">
                <span>共</span><span class="total-num">{{ cartList.length }}</span>件商品
                <!-- <span>已选择</span><span class="total-num">{{ selectGoodsList.length }}</span>件商品 -->
                <span style="margin-left: 20px">合计<span class="total-num"> {{ cartTotalPrice | currency}}</span>元</span>
                <el-button style="margin-left: 40px" type="danger" @click="checkout">去结算</el-button>
            </div>
        </div>
        <div v-else class="shop-cart-module empty-cart">
            <img :src="cartImg" class="cart-img"/>
            <div class="empty-cart-value">空空如也 <span @click="goHome">随便逛逛》</span></div>
            <!-- emptyCart.jpg -->
        </div>
    </div>
  
  </template>
  
  <script>
  import {getCartsList,deleteProduct,cartsSkuId,cancelSkuId } from "@/api";
  
  export default {
    data() {
      return {
        cartImg: require('@/assets/emptyCart.jpg'),
        cartList: [],
        selectGoodsList: [],
        freightTemplate: {},
        minHeight:''
      }
    },
    mounted() {
      this.getCartsList();
      this.minHeight = this.$store.state.minHeight || ''
    
    },
  
    computed: {
      cartTotalPrice() {
        let sum = 0
        this.cartList.forEach(item => {
          sum += item.price * item.count
        })
        // this.selectGoodsList.forEach(item => {
        //   sum += item.price * item.count
        // })
        // 因为要保留小数点后面两位，所以要toFixed(2)
        let result = sum.toFixed(2);
  
        return result;
      },
      
    },
    methods: {
      changeBtn(row){
        this.cancelSkuId(row)
      },
      // 取消选择
      cancelSkuId(v){
        console.log(v,'pppppp')
        cancelSkuId({
          checked: v.checked,
          skuId: v.skuId
        }).then(res=>{
          if(res.code == '00000'){
            this.getCartsList()
          }
        })
      },
      // 修改数量
      cartsSkuId(orderInfo){
        let params = {
          count: orderInfo.count,
          skuId: orderInfo.skuId
        }
        cartsSkuId(params).then(res=>{
          if(res.code == '00000'){
            this.getCartsList()
          }
        })
      },
     
      // 获取购物车列表
      getCartsList(){
        getCartsList().then(res=>{
          if(res.code == '00000'){
            this.cartList = res.data || []
            this.selectGoodsList  = this.cartList
          
          }
        })
      },
      
      checkout() {
        //判断是否勾选
        
        this.$router.push({
          path: '/confirmOrder'
        })
      },
      
      // 商品数量文本框改变事件
      cartGoodsAmountChange(e, orderInfo) {
        console.log(e,'pppppppp-')
        if (e <= 0) {
          orderInfo.count = 1
          return this.$message.error('商品数量不能小于0')
        }else if(e > orderInfo.stock){
          orderInfo.count = 1
          this.$message.error(`商品数量不能大于库存数${orderInfo.stock}`)
          return
        }
      },
      // 商品数量加1
      addAmountCount(orderInfo) {
        if(orderInfo.count < orderInfo.stock){
          ++orderInfo.count
          this.cartsSkuId(orderInfo)
        }else{
          this.$message.error(`商品数量不能大于库存数${orderInfo.stock}`)
        }
      },
      // 商品数量减1
      subtractAmountCount(orderInfo) {
        if (orderInfo.count <= 1) {
          return this.$message.error('商品数量不能小于0')
        }
        --orderInfo.count
        this.cartsSkuId(orderInfo)
      },
      deleteProduct(item){
        deleteProduct(item.row.skuId).then(res=>{
          if (res.code === '00000') {
            this.cartList.splice(item.$index, 1);
            this.$message.success("购物车商品删除成功")
          } else {
            this.$message.warning(res.msg)
          }
        })
      },
  // 单个删除购物车商品
      deleteCartGoods(item) {
        let that = this;
        this.$confirm('此操作将移除购物车的商品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.deleteProduct(item)
      
        }).catch((e) => {
          console.log(e)
          this.$message.info('已取消删除')
        })
      },
  // 批量删除购物车商品
      batchDeleteCartGoods() {
        let that = this;
        if (this.selectGoodsList.length <= 0) {
          this.$message.error('请勾选要移除购物车的商品')
          return;
        }
        this.$confirm('此操作将移除勾选的购物车的商品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
      
          // 这里调用接口删除的方法
        }).catch((e) => {
          console.log(e)
          this.$message.info('已取消删除')
        })
      },
      
      toGoodsDetail(productId) {
        // this.$router.push({
        //   path: '/detail?productId=' + productId
        // })
      },
      getFreightTemplate() {
        let that = this
       
      },
      goHome(){
        this.$router.push({
            path:'/home'
        })
      },
     
    },
    filters:{
      currency(value){
        if(value){
          return value / 100
        }else return '0.00'
        
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  
  .shop-cart {
    margin-top: 20px;
    background-color: #fff;
    &-module{
        max-width: 1190px;
        margin: auto;
        .cart-img{
            margin-top: 150px;
        }
    }
    .empty-cart{
        text-align: center;
        &-value{
            color: #999999;
            span{
                color: #ff3433;
                cursor: pointer;
            }
        }
    }
  }
  
  .amount-warp {
    display: flex;
  
    input {
      width: 31px;
      height: 31px;
      line-height: 30px;
      padding-left: 10px;
      outline: none;
    }
  
    .quick-button {
      i {
        display: block;
        width: 30px;
        height: 16px;
        border-left: none;
        text-align: center;
        line-height: 16px;
        cursor: pointer;
      }
  
      .el-icon-arrow-up {
        border-bottom: none;
      }
  
      .el-icon-arrow-down {
      }
    }
  
    .quick-button i:hover {
      color: #EF001C;
    }
  }
  
  .cart-option {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #999999;
  
    .delete {
      margin-left: 20px;
      cursor: pointer;
    }
  
    .common {
      margin-right: 30px;
  
      .total-num {
        margin: 0 5px;
        color: #ff3433;
      }
  
      .total-price {
        font-size: 24px;
        font-weight: 700;
        vertical-align: bottom;
      }
    }
  }
  
  .cart-action {
    margin: 20px auto;
    height: 80px;
    line-height: 80px;
    //background: #ecf2f9;
    position: relative;
    text-align: right;
    font-size: 14px;
    padding-right: 15px;
    box-sizing: border-box;
  
    .clear-cart {
      position: absolute;
      height: 30px;
      line-height: 30px;
      top: 25px;
      left: 15px;
      display: inline-block;
      padding: 0 20px;
    }
  
    .clear-cart:hover {
      border: 1px solid #198EFF;
      background: #fff;
    }
  
  
    .total-num {
      margin: 0 5px;
      color: #ff3433;
    }
  
    .total-price {
      font-size: 24px;
      font-weight: 700;
      vertical-align: bottom;
    }
  
  }
  
  .red-span-text {
    color: red;
    font-weight: bold;
    font-size: 26 rpx;
  }
  
  .shop-detail-item-btns {
        display: flex;
        color: #333 !important;
        align-items: center;
        border: 1px solid #eee;
        border-radius: 8px;
        text-align: center;
        overflow: hidden;

        .btn-j {
            font-size: 20px;
            width: 40px;
            cursor: pointer;
            height: 32px;
            line-height: 32px;
        }

        .btn-j:hover {
            background: #eee;
        }

        .input-value {
            width: 80px;
            text-align: center;
        }

        /deep/ .el-input__inner {
            border: none;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
            text-align: center;
        }
    }
  </style>
  