<template>
    <el-dialog
        title="领取优惠券"
        :visible.sync="dialogVisible"
        width="30%"
        @close="cancel">
        <!-- <div class="content"> -->
            <div class="coupon">
				<div class="coupon-item" v-for="(dataItem,index) in dataLists" :key="index">
					<div class="coupon-item-left ">
						<div><span class="unit">￥</span>{{dataItem.usedAmount | currency}}</div>
						<div v-if="dataItem.withAmount">满{{dataItem.withAmount | currency}}元使用</div>
					</div>
					<div class="line"></div>
					<div class="coupon-item-center">
						<div>{{dataItem.title}}</div>
						<div>{{typeTotit[dataItem.type]}}</div>
						<div v-if="dataItem.validType == 2" class="coupon-item-center-date">领取后{{dataItem.validDays}}内有效</div>
						<div v-else class="coupon-item-center-date">{{dataItem.validStartTime | dateFormatter}}-{{dataItem.validEndTime | dateFormatter}}</div>
					</div>
					<div class="coupon-item-btn" :class="{'color1': dataItem.receiveStatus == 2}" @click="receiveCoupon(dataItem,index)">{{dataItem.receiveStatus == 2 ? '已领取':'领 取'}}</div>
				</div>
				<div v-if="!dataLists.length" class="empty-item">
					暂无优惠券
				</div>
				
			</div>
        <!-- </div> -->
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="cancel">关 闭</el-button>
        </span>
    </el-dialog>

</template>

<script>

import {couponLists,receiveCoupon} from '@/api'
import { localStorage } from '@/utils/storage';

export default {
    props:{
        visible:{
            type:Boolean,
            default:false
        }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit('update:visible', val);
            },
        },
    },
    data(){
        return{
            dataLists:[],
            typeTotit:{
                1:'满减券',
                2:'叠加满减券',
                3:'无门槛',
            },
        }
    },
    mounted(){
        if(this.$store.state.token){
            this.couponLists()
        }
      
    },
    methods:{
        receiveCoupon(dataItem,index) {
            
            if(dataItem.receiveStatus == 2){
                this.$message.warning('请勿重复领取')
                
                return
            }
            receiveCoupon(dataItem.id).then(res=>{
                if(res.code == '00000'){
                    this.dataLists[index].receiveStatus = 2
                }else{
                    this.$message.error(res.msg || '领取失败')
                }
            })
        },
        
        cancel(){
            this.dialogVisible = false
        },

        couponLists(){
            couponLists({
                status:1
            }).then(res=>{
                if(res.code == '00000'){
                    this.dataLists = res.data.list ? res.data.list : []
                }
            })
            
        }
    },

    filters:{
        currency(value){
            if(value){
                return parseFloat((value / 100).toFixed(2))
            }else return '0.00'
        },
        dateFormatter(time) {
            return time ? time.substring(0,11) : ''
        }
    }
}
</script>

<style lang="less" scoped>
    .coupon{
        padding: 0 50px;
        .coupon-item{
            display: flex;
            background-color: #fff;
            margin-bottom: 10px;
            border-radius: 6px;
            overflow: hidden;
            align-items: center;
            box-shadow: 0 0 10px #ddd;
            .coupon-item-left{
                width: 32%;
                border-left: 6px solid rgb(240,50,50);
                text-align: center;
                padding: 16px 0;
                font-size: 12px;
                color: rgb(240,50,50);
                div:first-child{
                    margin-bottom: 8px;
                    font-size: 26px;
                    font-weight: bold;
                }
                .unit{
                    font-size: 14px;
                    width: 40px;
                        
                }
            }
            .line{
                border-right: 1px dashed rgb(153,153,153);
                height: 50px;
            }
            &-center{
                flex: 1;
                font-size: 12px;
                padding-left: 13px;
                color: rgb(148, 148, 148);
                view:first-child{
                    font-size: 14px;
                    font-weight: bold;
                    color: #333;
                    margin-bottom: 4px;
                }
                &-date{
                    margin-top: 6px;
                }
                
            }
            &-btn{
                background: linear-gradient(to right, #fd8f59, #f03232);
                color: #fff;
                width: 50px;
                font-size: 13px;
                text-align: center;
                line-height: 50px;
                margin: 0 24px;
                box-shadow: 0 0px 8px #ff7d7e;
                border-radius: 50%;
                cursor: pointer;
            }
            .color1{
				background: rgb(148, 148, 148) !important;
				box-shadow: 0 0px 8px rgb(148, 148, 148);
			}
            
            
        }
        .empty-item{
            text-align: center;
            padding: 80px 0;
            color: rgb(148, 148, 148);
        }
    }
    
</style>