<template>
    <el-dialog
        title="物流信息"
        :visible.sync="dialogVisible"
        width="30%"
        @close="cancel">
        <div class="content">
            <img class="content-img" :src="imgs" />
            <div class="content-value">
                <div>{{ dataOptions.deliveryCompany || '' }}</div>
                <div>{{ dataOptions.deliverySn || '' }}</div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="cancel">确 定</el-button>
        </span>
    </el-dialog>

</template>

<script>
import {getWLinfo} from '@/api'
export default {
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        id:{
            type:String,
            default:''
        }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit('update:visible', val);
            },
        },
    },
    data(){
        return{
            imgs: require('@/assets/qt.jpg'),
            dataOptions:{
                deliveryCompany:'',
                deliverySn:''
            },
            statusToTit:{
                "401":0,
                '501': 0,
                '502': 0,
                '601': 0,
                '602': 0,
                '603': 0,
                '901': 0
            },
        }
    },
    mounted(){
        if(this.id){
            this.getWLinfo()
        }
    },
    watch:{
        id(val){
            if(!val){
                return
            }
            this.getWLinfo()
        }
    },
    methods:{
        getWLinfo(){
            getWLinfo({
                orderId:this.id,
                type:0,//0商品发货，1退货，2发票'
            }).then(res=>{
                if(res.code === '00000'){
                    this.dataOptions = res.data
                }
            })
        },
        
        cancel(){
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="less" scoped>
.content{
    display: flex;
    padding: 0 20px;
    &-img{
        width: 70px;
        border-radius: 50%;
        height: 70px;
        margin-right: 20px;
    }
    &-value{
        div:first-child{
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 16px;
        }
    }
}
</style>