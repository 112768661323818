import Vue from "vue";
import Vuex from "vuex";
import { localStorage } from '@/utils/storage';
// import { login, logout } from '@/api/login';
import { membersMe } from '@/api';
import { resetRouter } from '@/router';

Vue.use(Vuex);

export default function(){
   const Store = new Vuex.Store({
      state: {
        token: localStorage.get('token') || '',
        nickname: '',
        avatar: '',
        roles: [],
        perms: [],
        memberId:localStorage.get('userInfo') && localStorage.get('userInfo').memberId ? localStorage.get('userInfo').memberId : '',
        cartGoods:[],
        chooseAddr:'',
        minHeight: localStorage.get('minHeight') || '',
        user: localStorage.get('user') || ''
      },
      getters: {
        getCartGoods(state) {
            return state.cartGoods
        },
        getChooseAddr(state) {
          return state.chooseAddr
        },
        getUser(state){
          return state.user
        },
        getToken(state){
          return state.token
        },
        getMemberId(state){
          return state.memberId
        }
      },
      mutations:{
        setCartGoods(state, cartGoods) {
          state.cartGoods = cartGoods;
        },
        setChooseAddr(state, chooseAddr){
          state.chooseAddr = chooseAddr;
        },
        setUser(state,user){
          state.user = user
        },
        setToken(state,token){
          state.token = token
        },
        setMemberId(state,memberId){
          state.memberId = memberId
        }
      },
      actions: {
        async RESET_STATE() {
          this.$reset();
        },
        /**
         * 登录
         */
        // login(loginData) {
        //   const { username, password, code, uuid } = loginData;
        //   return new Promise((resolve, reject) => {
        //     login({
        //       username: username.trim(),
        //       password: password,
        //       grant_type: 'captcha',
        //       code: code,
        //       uuid: uuid,
        //     })
        //       .then((response) => {
        //         const { access_token, token_type } = response.data;
        //         const accessToken = token_type + ' ' + access_token;
        //         localStorage.set('token', accessToken);
        //         this.token = accessToken;
        //         resolve(access_token);
        //       })
        //       .catch((error) => {
        //         reject(error);
        //       });
        //   });
        // },
        /**
         *  获取用户信息（昵称、头像、角色集合、权限集合）
         */
        getUserInfo() {
          return new Promise((resolve, reject) => {
            membersMe()
              .then(res => {
                console.log(res,'uuuuuu')
                if (!res) {
                  return reject('Verification failed, please Login again.');
                }
                localStorage.set('user', res.data);
                this.commit('setUser',res.data)
               
                
                resolve(res);
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
    
        /**
         *  注销
         */
        logout() {
          return new Promise((resolve, reject) => {
            logout()
              .then(() => {
                localStorage.remove('token');
                this.RESET_STATE();
                resetRouter();
                resolve(null);
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
    
        /**
         * 清除 Token
         */
        resetToken() {
          return new Promise((resolve) => {
            localStorage.remove('token');
            this.RESET_STATE();
            resolve(null);
          });
        },
      },
   })

   return Store
};


