<template>
    <el-dialog
        title="评价"
        :visible.sync="dialogVisible"
        width="660px"
        @close="cancel">
        <div class="content">
            <div class="content-reason">
                <span class="content-reason-tit">评分</span>
                <div class="pj-item">
                    <img class="pj-img" v-for="(item,index) in pf" :key="index" :src="item ? imgPjAct : imgPj" @click="changePf(item,index)" />
                    <span class="pj-item-value">{{pfValue}}</span>
                </div>
            </div>
            <div class="content-reason">
                <span class="content-reason-tit" >评论内容</span>
                <el-input class="textarea" type="textarea" v-model="formData.comments" :maxlength="300" placeholder="请输入评论内容..."></el-input>
            </div>
            
            <div class="content-reason">
                <span class="content-reason-tit" >评论图片</span>
                <el-upload  action="#"  list-type="picture-card"  :on-remove="removeBtn" multiple :file-list="formData.commentImg"  :before-upload="beforeAvatarUpload">
                    <i slot="default" class="el-icon-plus"></i>
                   
                </el-upload>
            </div>
           
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>

</template>

<script>

import {updatePic,evaluationAdd} from '@/api'
import { localStorage } from '@/utils/storage';

export default {
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        spuId:{
            type:String,
            default:''
        },
        orderSn:{
            type:String,
            default:''
        }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit('update:visible', val);
            },
        },
    },
    data(){
        return{
            pf:[false,false,false,false,false],
            pfConfig:{
                0:'',
                1:'非常差',
                2:'差',
                3:'一般',
                4:'好',
                5:'非常好',
            },
            formData:{
                commentScore:'',
                commentImg:[],
                comments:'',
                spuId:'',
            },
            imgPjAct:require('@/assets/pf-active.png'),
            imgPj:require('@/assets/pf.png'),
            pfValue:'',
            disabled: false
        }
    },
    mounted(){
      this.companyName = localStorage.get('user').companyName || ''
      this.taxNumber = localStorage.get('user').taxNumber || ''
    },
    methods:{
        removeBtn(file,fileList) {
            console.log(file,'ppppppp',fileList);
            this.formData.commentImg = fileList
        },
        // handlePictureCardPreview(file) {
        //     this.dialogImageUrl = file.url;
        //     this.dialogVisible = true;
        // },
        changePf(item,index){
            this.pf.forEach((v,cindex)=>{
                if(cindex > index){
                    this.pf.splice(cindex, 1, false);
                }else{
                    this.pf.splice(cindex, 1, true);
                }
            })
            this.pfValue = this.pfConfig[index + 1]
        },
        submit() {
            if(!this.formData.comments){
                this.$message.warning('请填写内容！')
                return
            }
            let commentScore = 0
            this.pf.forEach(v=>{
                if(v){
                    commentScore += 1
                }
            })
            if(commentScore == 0){
                this.$message.warning('请先评分！')
                return
            }
            let commentImg = []
            this.formData.commentImg.length && this.formData.commentImg.forEach((v=>{
                commentImg.push(v.url)
            }))
            let params = {
                commentImg: commentImg,
                commentScore: commentScore,
                comments:this.formData.comments,
                spuId: this.spuId,
                orderSn: this.orderSn
            }
            console.log('pppppppppppp',params)
            evaluationAdd(params).then(res => {
               if(res.code == '00000'){
                    this.$message.success('操作成功')
                    this.cancel()
                    this.$emit('refreshBtn')
               }else{
                    this.$message.error(res.msg)
               }
            })

        },
        beforeAvatarUpload(file){
            console.log(file,'file')
            const obj = new FormData();
            obj.append("file",file)
            this.updatePic(obj)
        },
        updatePic(obj){
            updatePic(obj).then(res=>{
                if(res.code === '00000'){
                    this.formData.commentImg.push({
                        url:res.data
                    })
                    console.log(this.formData.commentImg,'this.formData.commentImg')
                }
            })
        },
        cancel(){
            this.dialogVisible = false
        }
    },
    filters:{
        currency(value){
            if(value){
                return value / 100
            }else return '0.00'
        },
    }
}
</script>

<style lang="less" scoped>
.content{
    padding: 0 20px;
    .content-reason{
        margin: 16px 0;
        display: flex;
        
        &-tit{
            margin-right: 20px;
            width: 80px;
        }
    }
    .content-reason:first-child{
        align-items: center;
    }
    .pj-img{
		width: 40px;
        height: 40px;
        margin: 0 10px;
        cursor: pointer;
    }
    .pj-item{
        display: flex;
        align-items: center;
    }
    .textarea{
        flex: 1;
        border-radius: 4px;
    }
    .pj-item-value{
        margin-left: 5px;
        color: #F44336;
    }
    .avatar{
        width: 146px;
        height: 146px;
        border-radius: 6px;
    }
    .img-lists{
        width: 146px;
        height: 146px;
        border-radius: 6px;
        border: 1px solid #eee;
    }
}
</style>