import request from '../utils/request'
import { localStorage } from '@/utils/storage';

const base = '/mall-business'

// 获取验证码
export function smsCode(data){
    return request({
        url: `/byft-auth/sms-code?phoneNumber=${data.phoneNumber}`,
        method: 'post',
    });
}

// 注册
export function  registerApi(data){
    return request({
        url:`/byft-auth/oauth/member/register`,
        method:'post',
        data
    })
}

// 登录
export function oauthToken(data){
    return request({
        url: `/byft-auth/oauth/token?mobile=${data.mobile}&code=${data.code}&grant_type=${data.grant_type}`,
        method: 'post',
    });
}

// 获取当前用户登录信息
export function membersMe(){
    return request({
        url: `/mall-ums/app-api/v1/members/me`,
        method: 'get',
    });
}

// 查询轮播图
export function getBanner(){
    // https://www.boyaojt.com/prod-api/mall-business/app-api/v1/banner/list/2
    return request({
        url: base + '/app-api/v1/banner/list/2',
        method: 'get',
        params:''
    });
}

// 获取商品分类
export function categories(){
    return request({
        url: base + '/boot/v1/categories',
        method:'get'
    })
}

// 获取商品列表
export function spuList(data){
    return request({
        url: base + '/app-api/v1/spu/list',
        method:'get',
        params:data
    })
}

// 推荐商品接口
// 
export function recommended(){
    return request({
        url: base + '/boot/v1/spu/recommended',
        method:'get',
    })
}

// 热销商品
export function hotSell(){
    return request({
        url:base + '/boot/v1/spu/hotSell',
        method:'get',
    })
}

// 每日热销
export function hotSellByDay(){
    return request({
        url: base + '/boot/v1/spu/hotSellByDay',
        method:'get'
    })
}

// 商品详情
export function spuDetail(spuId){
    return request({
        url: base + `/app-api/v1/spu/${spuId}`,
        method:'get'
    })
}

// 查询购物车
export function getCartsList(){
    return request({
        url: base + `/app-api/v1/carts/getList`,
        method:'get'
    })
}

// 添加购物车
export function addCarts(data){
    return request({
        url: base +'/app-api/v1/carts/add',
        method:'post',
        data
    })
}

// 删除购物车商品
export function deleteProduct(skuId){
    return request({
        url: base + `/app-api/v1/carts/skuId/${skuId}`,
        method:'delete'
    })
}


// 查询地址列表
export function getAddressList(memberId){
    return request({
        url: `/mall-ums/app-api/v1/addresses?memberId=${memberId}`,
        method:'get'
    })
}

// 新增地址
export function addAddress(data){
    return request({
        url: `/mall-ums/app-api/v1/addresses`,
        method:'post',
        data
    })
}

// 修改地址
export function editAddress(data){
    return request({
        url: `/mall-ums/app-api/v1/addresses/${data.id}`,
        method:'put',
        data
    })
}

// 获取地址详情
export function getAddressDetail(id){
    return request({
        url: `/mall-ums/app-api/v1/addresses/${id}?addressId=${id}`,
        method:'get',
    })
}

// 删除地址
export function delAddr(id){
    return request({
        url: `/mall-ums/app-api/v1/addresses/${id}`,
        method:'delete',
    })
}

// 上传图片
export function updatePic(data){
    console.log(localStorage.get('token'),"localStorage.get('token')")
    return request({
        url: `/byft-admin/api/v1/files`,
        method:'post',
        headers:{
            'content-type': 'multipart/form-data',
            'Authorization': localStorage.get('token')
        },
        data
    })
}

// 上传资质
export function uploadQualified(data){
    return request({
        url: `/mall-ums/app-api/v1/members/uploadQualified`,
        method:'post',
        data
    })
}

// 获取资质信息
export function getQualified(){
    return request({
        url: `/mall-ums/app-api/v1/members/getQualified`,
        method:'get'
    })
}

// 判断资质认证是否提交或审核通过
export function getAuditStatus(){
    return request({
        url: `/mall-ums/app-api/v1/members/getAuditStatus`,
        method:'get',
    })
}

// 获取订单列表
export function getListPage(params){
    return request({
        url:'/mall-business/app-api/v1/order/listPage',
        method:'get',
        params:params
    })
}

// 删除订单
export function deleteOrder(id){
    return request({
        url:`/mall-business/app-api/v1/order/delete/${id}`,
        method:'delete',
    })
}

// 取消订单
export function cancelOrder(orderId) {
	return request({
		url: '/mall-business/app-api/v1/order/cancel',
		method: 'put',
		params: {
			id: orderId
		}
	})
}


// 下单流程
export function confirmOrder(data){
    return request({
        url:'/mall-business/app-api/v1/order/_confirm',
        method:'post',
        data
    })
}

// 订单提交
export function submitOrder(data){
    return request({
        url:'/mall-business/app-api/v1/order/_submit',
        method:'post',
        data
    })
}

// 获取物流信息
export function getWLinfo(data) {
	return request({
		url: `/mall-business/app-api/v1/delivery/${data.orderId}/${data.type}`,
		method: 'get',
	})
}

// 获取订单详情
export function getOrderDetail(orderId) {
	return request({
		url: `/mall-business/app-api/v1/order/${orderId}/info`,
		method: 'get',
	})
}


// 提交订单
export function orderPay(data){
    return request({
        url:'/mall-business/app-api/v1/order/_pay',
        method:'post',
        data
    })
}

// 修改产品数量
export function cartsSkuId(data){
    return request({
        url:`/mall-business/app-api/v1/carts/skuId/${data.skuId}`,
        method:'put',
        data:data
    })
}

// 购物车取消产品选择
export function cancelSkuId(data){
    return request({
        url:`/mall-business/app-api/v1/carts/skuId/${data.skuId}`,
        method:'put',
        data:{
            checked: data.checked
        }
    })
}

// 查询订单状态
export function queryPayStatus(data){
    return request({
        url:`/mall-business/app-api/v1/order/queryPayStatus/${data.orderSn}`,
        method:'get',
        data:data
    })
}

// 申请退款
export function refundApply(data) {
	return request({
		url: '/mall-business/app-api/v1/orders/refundApply',
		method: 'post',
		data: data,
	})
}

// 确认收货
export function orderStatus(data) {
	return request({
		url: `/mall-business/app-api/v1/order/${data.orderId}/status`,
		method: 'put',
		params:data
	})
}

/**
 * 申请开票
 * @param {Object} data
 */
export function invoiceApply(data) {
	return request({
		url: '/mall-business/app-api/v1/order/invoiceApply',
		method: 'post',
		data: data,
	})
}

// 获取优惠券列表
export function couponLists(params) {
	return request({
		url:'/mall-business/app-api/v1/coupon/list',
		method: 'get',
		params:params
	})
}

// 领取优惠券
export function receiveCoupon(couponId) {
	return request({
		url: `/mall-business/app-api/v1/coupon/receiveCoupon/${couponId}`,
		method: 'get',
	})
}

// 获取我的优惠券列表
export function myCouponList(status) {
	return request({
		url: `/mall-business/app-api/v1/couponRecord/list/${status}`,
		method: 'get',
	})
}

// 添加评论
export function evaluationAdd(data) {
	return request({
		url:`/mall-business/app-api/v1/evaluation/add`,
		method: 'post',
		data,
	})
}
// 获取商品评论列表
export function evaluationList(params) {
	return request({
		url:`/mall-business/app-api/v1/evaluation/list`,
		method: 'get',
		params,
	})
}