<template>
  <div class="container">
    <div class="checkout">
      <div class="address_title">收货地址</div>
      <div class="checkout_address">
        <div v-if="addressList.length" class="add_address_item">
          <div class="item">
            <div class="name">
              <span>收件人：</span>{{ addressData.consigneeName || '' }}
            </div>
            <div class="name">
              <span>联系方式：</span>{{ addressData.consigneeMobile || '' }}
            </div>
            <div class="name">
              <span>收货地址：</span>{{ addressData.province || '' }}{{ addressData.city || '' }}{{
                addressData.area || '' 
              }}{{ addressData.detailAddress || '' }}
            </div>
          </div>
          <div>
            <!-- <p class="update_address" @click="editAddress(addressData.id)">修改地址</p> -->
            <p class="update_address" @click="goAddress(addressData.id)" >修改地址</p>

          </div>

        </div>
        <div v-else class="add_address_conent">
          您需要先添加收货地址才可提交订单，<span class="add-adress" @click="goAddrPage">去添加地址</span>
        </div>
        <!-- <div class="add_address">
          <add-address :id="addressId" ref="address" :visible.sync="visibel" @closeq="closeq" @openq="openq"></add-address>
        </div> -->
      </div>
      <goods-info :goodsInfo="goodsList" title="商品信息"/>

      <div class="address_title">金额明细</div>
      <div class="money_detail">
        <div class="detail">
          <dl>
            <dt>商品件数:</dt>
            <dd>{{ summary.goodsCount }}件</dd>
          </dl>
          <dl>
            <dt>商品总价:</dt>
            <dd>{{ summary.totalPrice | currency }}</dd>
          </dl>
          <dl>
            <dt>优惠券:</dt>
            <dd>
              <el-select size="small" v-model="couponId" placeholder="请选择可使用的优惠券" @change="changeCoupon">
                <el-option
                  v-for="item in couponList"
                  :key="item.couponId"
                  :label="item.title"
                  :value="item.couponId"
                  :disabled="!(summary.totalPrice*1 > item.withAmount*1)"
                >
                </el-option>
              </el-select>
            </dd>
          </dl>
          <dl>
            <dt>运<i></i>费:</dt>
            <dd>{{ summary.postFee | currency}}</dd>
          </dl>
          <dl class="tip-style"><span>（商品总价小于300元，将收取运费20元）</span></dl>

          <dl>
            <dt>应付总额:</dt>
            <dd class="price">
              <span v-if="summary.totalPayPrice < 30000">{{ (summary.totalPayPrice + 2000) | currency }}</span>
              <span v-else>{{ summary.totalPayPrice | currency }}</span>
            </dd>
          </dl>
        </div>
      </div>
      <div class="sub_order">
        <el-button v-loading="submiting" @click="sumOrder">提交订单</el-button>
      </div>

      <!-- 地址弹框 -->
      <el-dialog title="地址列表" :visible.sync="visibleAddress" width="820px">
        <div class="main">
          <el-table class="xh-table" :data="addressList" style="width: 100%" @row-click="rowClick">
              <el-table-column prop="consigneeName" label="收货人" width="100"></el-table-column>
              <el-table-column prop="province" label="省" width="120"></el-table-column>
              <el-table-column prop="city" label="市" width="120"></el-table-column>
              <el-table-column prop="area" label="区" width="120"></el-table-column>
              <el-table-column prop="detailAddress" label="详细地址"></el-table-column>
              <el-table-column prop="consigneeMobile" label="手机号">
                  <template slot-scope="scoped">
                      <div>{{scoped.row.consigneeMobile}}</div>
                  </template>
              </el-table-column>
              <el-table-column prop="defaulted" label="是否默认地址">
                  <template slot-scope="scoped">
                      <div>{{scoped.row.defaulted == 1 ? '是':'否'}}</div>
                  </template>
              </el-table-column>
          </el-table>
        </div>
        <!-- <div slot="footer" class="dialog_footer">
          <el-button class="remove" @click="tabAddress = false">取消</el-button>
          <el-button class="confirm" @click="confirmDddress()">确认</el-button>
        </div> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import addAddress from '../address/addAddress.vue'
import goodsInfo from './components/goodsInfo.vue'
import {getAddressList,submitOrder,confirmOrder,myCouponList} from '@/api'

export default {
  components: {
    goodsInfo,
    // addAddress

  },
  data() {
    return {
      addressData: '',
      submiting: false,
      goodsList: [],
      summary: {
        goodsCount: 0.00,
        totalPrice: 0.00,
        totalPayPrice: 0.00,
        postFee: 0.00
      },
      orderForm: {
        dhOrderDetailList: [],
        invoiceInfo: '不开发票',
        invoiceType: '1',
        deliverydate: '',
        userAddr: '',
        userName: '',
        userTel: '',
        productid: '',
        freightAmount: 0.0,
        orderAmount: 0,
        totalweight: 0,
        orderprice: '',
        ordercount: '',
        orderunit: '',
        userRemark: '',
        orderStatus: 1,
        payStatus: 1,
        orderFlag: 1,
        status: 10,
        dhOrderReceipt: {
          fptt: '',
          nsrsbh: '',
          fpcontent: '',
          zcaddr: '',
          zctel: '',
          khaccount: '',
          accountNumber: ''
        },
      },
      isFahuo: false,
      reqParams: {
        goodsList: [],
        addressId: '',
        deliveryTimeType: 1,
        payType: 1,
        payChannel: 1,
        buyerMessage: ''
      },
      visibel: false,
      addressId: 0,
      orderToken:'',
      visibleAddress:false,
      addressList:[],
      couponList:[],
      couponId:'',
      couponAmount:'0'
    }
  },
  mounted() {
    // if(this.$store.state.chooseAddr){
    //   this.addressData = this.$store.state.chooseAddr
    // }else{
    //   this.getDefaultAddress()
    // }
    this.confirmOrder()
    this.myCouponList()
    // this.initOrderSummer();
  },
  methods: {
    goAddrPage(){
      this.$router.push({
        path:'/address'
      })
    },
    // 计算实际支付金额
    calcPayAmount() {
      this.summary.totalPayPrice = this.summary.totalPrice*1 - this.couponAmount*1;
    },
    changeCoupon(val){
      this.couponId = val
      this.couponList.some(v=>{
        if(v.couponId == val){
          this.couponAmount = v.usedAmount
          return
        }
      })
      this.calcPayAmount()
    },
    myCouponList(){
      myCouponList(0).then(res=>{
					this.couponList = res.data.list || []
			})
    },
    rowClick(row){
      this.addressData = row
      this.visibleAddress = false
    },
     // 下单
    confirmOrder(){
      confirmOrder({
        skuId:null,
        num:null
      }).then(res=>{
        if(res.code === '00000'){
          this.orderToken = res.data.orderToken
          this.addressData = {}
          this.goodsList = res.data.orderItems || []

          this.initOrderSummer(res.data.orderItems)
          this.addressList = res.data.addresses
          if(this.$store.state.chooseAddr){
            this.addressData = this.$store.state.chooseAddr
            return
          }
          res.data.addresses.some(v=>{
            if(v.defaulted === 1){
              this.addressData = v
              return false
            }
          })
        }
      })
    },
    closeq() {
      this.visibel = false
    },
    openq() {
      this.visibel = true
    },
    initOrderSummer(cartGoodsInfo) {

      let goodsCount = 0.0;
      let totalPrice = 0.0;
      let totalPayPrice = 0.0;

      cartGoodsInfo.forEach((item) => {
        goodsCount += item.count
        totalPrice += item.count * item.price 
        totalPayPrice += item.count * item.price

      })

      this.summary = {
        goodsCount: goodsCount,
        totalPrice: totalPrice,
        totalPayPrice: totalPayPrice,
        postFee: (totalPrice / 100 )<300 ? '2000':'0'
      }

    },
    getDefaultAddress() {
      let that = this
      if(!this.$store.state.memberId){
        return
      }
      getAddressList(this.$store.state.memberId).then(res => {
        if (res.code === '00000') {
          let list = ''
          res.data.some(v=>{
            if(v.defaulted === 1){
              list = v
              return false
            }
          })
          that.addressData = list
          console.log(that.addressData,'add')
        } else {
          this.$message.warning(res.msg)
        }

      })
    },
    goAddress() {
      this.visibleAddress = true
      // this.$router.push({
      //   path: '/address',
      //   query:{
      //     type: 'confirmOrder',
      //   }
      // })
    },
    getFreightData() {
      let that = this
      getFreight().then(res => {
        if (res.data === -101) {
          that.$message.warning("当前地区不在发货区域内，请重新选择收货地址！")
          that.isFahuo = false
        } else {
          that.orderForm.freightAmount = res.data
          that.isFahuo = true
        }

      }).catch(exp => {
        console.log("异常信息" + exp.msg)
        // this.visibleDetail = false
      })
    },
    editAddress(id) {
      this.addressId = id
      this.visibel = true
    },

    // 提交订单
    sumOrder() {
      let that = this
      
     
      if (!this.addressData.id) {
        this.$message.warning('收货地址不能为空！');
        return;
      }

      if(this.summary.totalPayPrice < 499){
        this.$message.warning('支付金额不能小于499元');
        return;
      }

      that.submiting = true
      let orderForm = this.orderForm;

      // that.submiting = true
      submitOrder({
        orderToken:this.orderToken,
        totalAmount:this.summary.totalPrice,//订单总金额
        payAmount:this.summary.totalPayPrice,//支付金额
        orderItems:this.goodsList,//订单的商品明细
        remark:'',
        couponId:this.couponId,//优惠券id
        deliveryAddress:this.addressData,//收货地址
      }).then(res => {
        if (res.code === '00000') {
          // 清理购物车勾选缓存
          // that.$store.commit("clearCartGoods")
          that.submiting = false
          console.log(res.msg);
          this.$router.replace({
            path: '/pay?orderSn=' + res.data.orderSn
          })
        } else {
          that.submiting = false
          this.$message.warning(res.msg)
        }

      }).catch(exp => {
        that.submiting = false
        console.log("异常信息" + exp.msg)
        // this.visibleDetail = false
      })


      // if (this.reqParams.addressId) {
      //   console.log(this.goodsList)
      //   this.reqParams.goodsList = this.goods.map(val => {
      //     return {skuId: val.skuId, count: val.count}
      //   })
      //   subOrder(this.reqParams).then(res => {
      //     this.$router.push({path: '/member/pay', query: {orderId: res.result.id}})
      //   })
      // } else {
      //   this.$message({
      //     message: '亲，请选择收件地址',
      //     type: 'warning'
      //   })
      // }
    }
  },
  filters:{
    currency(value){
      if(value){
        return value / 100
      }else return '0.00'
      
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/styles/variables.less";

.now_position {
  padding: 30px 0;

  /deep/ .el-breadcrumb__inner {
    color: #333;
  }

  /deep/ .is-link {
    color: #666;
    font-weight: 400;

    &:hover {
      color: @xtxColor;
    }
  }
}

.checkout {
  max-width: 1190px;
  margin: auto;
  /* text-align: center; */
  margin-top: 20px;
  background-color: #FFF;
  padding:20px 20px 0;

  .address_title {
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    line-height: 40px;
    border-bottom: 1px solid #f5f5f5;
  }

  .checkout_address {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    // border: 1px solid #f5f5f5;

    .add_address_conent {
      flex: 1;
      line-height: 90px;
      color: #999;
      text-align: center;
      .add-adress{
        cursor: pointer;
        color: #cf4444;
      }
    }

    .add_address_item {
      display: flex;
      flex: 1;
      height: 90px;
      // justify-content: space-between;
      align-items: center;

      .item {
        display: flex;
        // flex-direction: column;
        justify-content: space-around;
        padding-left: 20px;
        flex: 1;
        flex-wrap: wrap;

        .name {
          line-height: 30px;

          span {
            color: #999;
          }
        }
      }

      .update_address {
        padding-right: 50px;
        color: @xtxColor;
        cursor: pointer;
      }
    }

    .add_address {
      width: 420px;
      display: flex;

      &::before {
        content: "";
        border-left: 1px solid #f5f5f5;
        height: 60px;
      }
    }
  }

  .delivery_time {
    display: flex;

    .choice_time {
      width: 228px;
      height: 50px;
      line-height: 50px;
      border: 1px solid #e4e4e4;
      margin: 20px 20px 20px 0;
      text-align: center;

      &.active {
        border: 1px solid @xtxColor;
      }

      &:hover {
        border: 1px solid @xtxColor;
      }

      &.last {
        border: none;
        color: #999;
        text-align: left;
      }
    }
  }

  .money_detail {
    padding: 20px 0;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    justify-content: flex-end;

    .detail {
      display: flex;
      flex-direction: column;

      dl {
        width: 310px;
        display: flex;
        justify-content: space-between;
        padding: 10px 70px 10px 0;

        dt {
          i {
            width: 2em;
            display: inline-block;
          }
        }

        
        .price {
            font-size: 20px;
            color: #cf4444;
          }
      }
    }
  }

  .sub_order {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 70px;

    .el-button {
      width: 180px;
      height: 50px;
      font-size: 16px;
      // border-color: @xtxColor;
      // background: @xtxColor;
      //  border-color: #0c099b;
      // background: #0c099b;
      border-color: #fff;
      background: #fa436a;
      color: #fff;
    }
  }
}
.tip-style{
  padding: 0 !important;
    font-size: 12px !important;
    color: #838080 !important;
    margin-top: -7px !important;
}
</style>
