<template>
  <div class="shop-detail">
    <div class="shop-detail-info">
      <div class="shop-detail-info-img">
        <!-- <img :src="detail.mainpic" class="detail-img"/>
        <div class="detail-imgs">
          <img :src="detail.mainpic" class="detail-imgs-url"/>
        </div> -->
        <zoom v-if="imgList.length" :skuImageList="imgList" ref="zoom"></zoom>
        <div class="detail-imgs">
          <el-image v-for="(item,index) in imgList" fit="scale-down" :src="item" :key="index" class="detail-imgs-url" :class="{'active-img' : currentIndex === index ? true :false }" @click="getIndex(index)"/>
        </div>
      </div>
      <div class="shop-detail-info-con">
        <div class="shop-title">{{ detail.name }}</div>
               
        <div class="shop-money" v-if="$store.state.user.auditStatus == 2 || detail.type == 1">
          <div class="borderClass">
            <div><span style="font-size:12px">￥</span> {{ skuList[activeIndex].price | currency}} </div>
            <div  >
              <span class="m-price" style="font-size:12px;">1{{detail.unit}}</span>
            </div>
          </div>
          <div class="borderClass" v-if="skuList[activeIndex].thirdPrice != null && skuList[activeIndex].thirdPrice > 0">
            <div>
              <span class="price" style="font-size:12px">¥</span>{{skuList[activeIndex].secondPrice | currency }}
            </div>
            <div>
              <span class="m-price"  style="font-size:12px;" >2{{detail.unit}}</span>
            </div>
          </div>
          <div class="borderClass" v-if="skuList[activeIndex].thirdPrice != null && skuList[activeIndex].thirdPrice > 0">
            <div>
              <span class="price" style="font-size:12px">¥</span>{{skuList[activeIndex].thirdPrice | currency }}
            </div>
              <div>
                <span class="m-price"  style="font-size:12px;" >3{{detail.unit}}起</span>
              </div>
          </div>
        </div>
        <div class="shop-money" v-else>￥ 认证可见</div>
        <!-- <span v-if="detail.price != detail.originPrice">~ {{ detail.originPrice | currency }}</span> -->
        <!-- <div class="shop-detail-list">
          <div class="shop-detail-left">
            <div class="shop-detail-item"><span>销量：</span>{{ detail.sales }}</div>
          </div>
          <div class="shop-detail-right">
            <div class="shop-detail-item"><span>库存：</span>{{ detail.standard }}</div>
          </div>
        </div> -->
        <div class="shop-detail-list">
          <div class="detail-content">
            <p>销量：{{ detail.sales }}</p>
            <p>单位：{{ detail.unit }}</p>
            <p>库存：{{ skuList[activeIndex].stockNum }}</p>
            <p>产品名称：{{ detail.name }}</p>
            <p v-for="(item,index) in specList" :key="index">{{item.name}}：<span v-for="(citem,cindex) in item.values" :key="cindex">{{ citem.value }}</span></p>
          </div>
         
        </div>
        <!-- 规格 -->
        <div class="product-gg">
            <div class="product-gg-title" style="width: 100%;">规格</div>
            <div class="product-gg-item" v-for="( item , index ) in skuList" :key="index" v-if="item.stockNum" :class="{'active': activeIndex == index}" @click="activeIndex = index">{{ item.name }}</div>
        </div>
      
        <div class="shop-detail-bottom activies">
          <div class="shop-detail-item shop-detail-item1">
            <span>订购：</span>
            <div class="shop-detail-item-btns">
              <div class="btn-j" @click="increaseBtn"> -</div>
              <el-input v-model="number" class="input-value" size="small"></el-input>
              <div class="btn-j" @click="addBtn"> +</div>
            </div>
          </div>
          <el-button class="btn-jg" type="primary" @click="addToCart">加入购物车</el-button>
        </div>
      </div>
    </div>

    <div class="shop-detail-module">
      <div class="shop-detail-module-left">
        <el-tabs>
          <el-tab-pane label="商品详情">
            <!-- <div v-html="detail.detail"></div> -->
            <div class="shop-detail-list">
              <div class="detail-content">
                <!-- <p>库存：{{ skuList[activeIndex].stockNum }}</p>
                <p>产品名称：{{ detail.name }}</p> -->
                <!-- <p v-for="(item,index) in specList" :key="index">{{item.name}}：<span v-for="(citem,cindex) in item.values" :key="cindex">{{ citem.value }}</span></p> -->
                <div v-html="detail.detail"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="评论">
            <div class="eva-list">
              <div class="eva-box" v-for="(item,index) in plLists" :key="index">
                <img class="portrait" :src="item.avatarUrl" mode="aspectFill" />
                <div class="right">
                  <div class="right-top">
                    <span class="name">{{ item.memberName }}</span>
                    <div class="bot">
                      <span class="attr"></span>
                      <span class="time">{{ item.createTime }}</span>
                    </div>
                  </div>
                  <span class="con">{{ item.comments }}</span>
                  <div class="pj-module">
                    <el-image   class="pj-imgs"
                      v-for="(citem,cindex) in item.commentImg" :key="cindex" :src="citem"  fit="scale-down" 
                      :preview-src-list="item.commentImg">
                    </el-image>
                  </div>
                </div>
              </div>
              <el-pagination v-if="plLists.length" @size-change="handleSizeChange"  @current-change="handleCurrentChange" :current-page.sync="page" :page-size="pageSize" style="text-align: center;" layout="prev, pager, next" :total="totalPl"></el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="hot-product">
        <div class="hot-product-title">热销产品</div>
        <div class="hot-product-list">
          <div class="hot-product-list-item" v-for="(item,index) in dataList" :key="index" v-if="index < 5" @click="goDetail(item)">
            <div class="imgs-style"><img :src="item.picUrl" /></div>
            <div class="hot-product-list-item-right">
              <div class="hot-prd-title">{{ item.name }}</div>
              <div class="hot-prd-price">
                  <div class="hot-prd-price1" v-if="$store.state.user.auditStatus == 2 || item.type == 1"><span>￥</span> {{ item.price | currency }}</div>
                  <div class="hot-prd-price1" v-else>认证可见</div>
                  <div >销量：{{ item.sales }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {spuDetail,addCarts,hotSell,evaluationList} from '@/api'
import Zoom from '@/components/zoom.vue'
export default {
  name: 'productDetail',
  components:{Zoom},
  data() {
    return {
      productId: '',
      detail: {},
      number: 1,
      imgList:[],
      currentIndex:0,
      skuList:[],
      activeIndex:'',
      dataList:[],
      txUrl:require('../../assets/tx.png'),
      evaList:[{},{}],
      auditStatusTit:{
        0:'请先去资质认证',
        1:'资质认证审核中'
      },
      specList:[],
      pageSize:10,
      page:1,
      plLists:[],
      totalPl:0
    }
  },
  mounted() {
    //获取商品的id
    this.productId = this.$route.query.productId;
    this.getDetail();
    this.hotSell()
    this.evaluationList()
  },
  methods: {
    // 热销商品
    hotSell(){
        hotSell().then(res=>{
            if(res.code === '00000'){
                this.dataList = res.data || []
            }
        })
    },
    getIndex(index){
      this.currentIndex = index
      this.$refs.zoom.currentIndex = index
    },
    // 获取商品详情
    getDetail() {
      let that = this;
      spuDetail(this.productId).then(res => {
        if (res.code === '00000') {
          that.detail = res.data.goodsInfo;
        
          that.imgList = res.data.goodsInfo.album || [];
          let skuList = []
          res.data.skuList.forEach(v=>{
              if(v.stockNum){
                skuList.push(v)
              }
          })
          that.skuList = skuList
          if(skuList.length){
            that.activeIndex = 0
          }
          that.specList = res.data.specList
        } else {
          this.$message.warning(res.msg)
        }

      }).catch(exp => {
        console.log("异常信息" + exp.msg)
        // this.visibleDetail = false
      })
    },

    
    increaseBtn() {
      if (this.number < 1) {
        this.$message.warning('不能再减了！')
        return
      }
      this.number -= 1
    },
    addBtn() {
      this.number += 1
    },
    addToCart() {
      if(this.detail.type == 1){
        this.addCarts()
        return
      }
      if(this.$store.state.user.auditStatus != 2){
        this.$message.warning(this.auditStatusTit[this.$store.state.user.auditStatus]||'请先登录')
        return
      }
      if(this.number <= 0){
        this.$message.warning("请添加商品的数量")
        return
      }
      this.addCarts()
    },
    // 添加购物车
    addCarts(){
      addCarts({
        // skuId:this.productId,
        skuId:this.skuList[this.activeIndex].id,
        num:this.number
      }).then(res=>{
        if(res.code === '00000'){
          this.$message.success("添加购物车成功")
        }else{
          this.$message.warning(res.msg)
        }
      })
    },
    goDetail(val){
      const {href} = this.$router.resolve({
        path:'/product',
        query:{
          productId: val.id
        }
      })
      window.open(href,'_blank');
    },
    evaluationList(){
      evaluationList({
        spuId: this.productId,
					page:this.page,
					pageSize:this.pageSize
      }).then(res=>{
        this.plLists = res.data.list || []
        this.totalPl = res.data.total || 0
      })
    },
    handleCurrentChange(e){
      this.page = e
      this.evaluationList()
    },
    handleSizeChange(e){
      this.pageSize = e
      this.evaluationList()
    }
  },
  filters:{
    currency(value){
      if(value){
        return value / 100
      }else return '0.00'
      
    }
  }
}
</script>

<style lang="less" scoped>
.shop-detail {
  
  padding-bottom: 120px;
  background-color: #fff;

  .shop-detail-info {
    display: flex;
    max-width: 1190px;
    margin: 20px auto 0;
    &-img {
      width: 450px;

      .detail-img {
        width: 100%;
        height: 380px;
        border: 1px solid #eee;
      }

      .detail-imgs {
        width: 100%;
        display: flex;
        padding: 12px 20px;
        box-sizing: border-box;

        &-url {
          width: 50px;
          height: 50px;
          border: 1px solid #eee;
          cursor: pointer;
          margin-right: 6px;
        }
        .active-img{
          border: 1px solid rgba(217, 60, 93, 0.32);
        }
      }
    }

    &-con {
      flex: 1;
      padding: 20px 20px 20px 36px;

      .shop-title {
        font-size: 23px;
        font-weight: bold;
      }

      .shop-money {
        font-weight: bold;
        margin-top: 15px;
        cursor: pointer;
        color: #ff3433;
        font-size: 24px;
        display: flex;
        .borderClass{
          width: 150px;
          .m-price{
            color: #333;
          }
        }
      }

      .shop-detail-list {
        // display: flex;
        margin-top: 20px;
        .detail-content{
          div{
            /deep/p{
              margin-bottom: 10px;
              
            }
           
          }
         
        }
        .shop-detail-left {
          width: 300px;
        }

        .shop-detail-right {
          flex: 1;
        }
      }

      .shop-detail-item {
        margin-bottom: 10px;

        span {
          color: #999;
        }

      }

      .activies {
        font-size: 13px;
      }

      .shop-detail-item1 {
        width: 100%;
        display: flex;
        align-items: center;

        .shop-detail-item-btns {
          display: flex;
          color: #333 !important;
          align-items: center;
          border: 1px solid #eee;
          border-radius: 8px;
          text-align: center;
          overflow: hidden;

          .btn-j {
            font-size: 20px;
            width: 40px;
            cursor: pointer;
            height: 32px;
            line-height: 32px;
          }

          .btn-j:hover {
            background: #eee;
          }

          .input-value {
            width: 60px;
            text-align: center;
          }

          /deep/ .el-input__inner {
            border: none;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
            text-align: center;
          }
        }
      }

      .shop-detail-bottom {
        display: flex;
        align-items: center;
        margin-top: 30px;
      }

      .btn-jg {
        margin-right: 130px;
      }
      .product-gg{
        display: flex;
        padding: 10px 20px;
        background-color: #f7f5f5;
        flex-wrap: wrap;
        &-title{
          width: 100%;
          margin-bottom: 10px;
          font-size: 14px;
        }
        &-item{
          border: 1px solid #eee;
          border-radius: 2px;
          line-height: 32px;
          padding: 0 12px;
          margin-right: 10px;
          background-color: #fff;
          cursor: pointer;
          margin-bottom: 6px;
        }
        .active{
          color: #fa436a;
          background-color: #fbebee;
        }
      }
    }
  }

  .shop-detail-module {
    max-width: 1190px;
    margin: auto;
    min-height: 600px;
    display: flex;
    margin-top: 76px;
    &-left{
      border: 1px solid #eee;
      padding: 0 20px;
      margin-right: 20px;
      flex: 5;
      .eva-list{
        .eva-box {
          display: flex;
          padding: 10px 0 20px;

          .portrait {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            border-radius: 100px;
          }

          .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            
            padding-left: 13px;
            &-top{
              display: flex;
              .name{
                margin-right: 20px;
                font-size: 20px;
              }
            }
            .con {
              padding: 10px 0;
            }

            .bot {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #999;
              font-size: 12px;
              span{
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
    .hot-product{
      border: 1px solid #eee;
      flex: 2;
      &-title{
        border-bottom: 1px solid #eee;
        font-size: 14px;
        padding: 8px 20px;
      }
      &-list{
        padding: 0 20px;
        &-item{
          display: flex;
          border-bottom: 1px dashed #eee;
          padding: 10px 0;
          font-size: 14px;
          cursor: pointer;
          .imgs-style{
            height: 100px;
            width: 120px;
            margin-right: 2px;
            text-align: center;
            overflow: hidden;
            img{
              height: 100%;
            }
          }
          &-right{
            flex: 1;
            .hot-prd-title{
              font-weight: bold;
              font-size: 16px;
              margin-top: 8px;
            }
            .hot-prd-price{
              display: flex;
              margin-top: 32px;
              justify-content: space-between;
              .hot-prd-price1{
                color: #ff3433;
                font-size: 16px;
                span{
                  font-size: 12px;
                }
              }
            }
          }
        }
        
      }
    }
  }
}
.pj-module{
		display: flex;
		flex-wrap: wrap;
		.pj-imgs{
			width:80px;
			height: 80px;
      cursor: pointer;
		}
	}
</style>
<style lang="less">
.detail-content{
  img{
      width: 100% !important;
  }
}
</style>
