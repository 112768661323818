<template>
  <div class="home-module">
    <div class="home">
      <div class="container">
        <div class="nav-module">
          <div class="group-list" @mouseleave="mouseleaveBtn">
            <ul class="classify overflow">
              <li v-for="(item,index) in categoryListData" :key="item.id" :class="{'active-class': activeIndex === index}"
                  class="classify-item" @mouseenter="mouseenterbtn(index)" @click="goPage(item)">
                <div class="classify-item-top">
                  <div class="classify-label">{{ item.name }}</div>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="line"></div>

              </li>
            </ul>
            <ul v-if="activeIndex != null" class="classify-one">
              <template v-if="dataList.length > 0">
                <li v-for="(item,index) in dataList" :key="index" class="classify-one-item" @click="goDetail(item.id)">
                  <div style="margin: 20px">
                    {{ item.name }}
                  </div>
                </li>
                <div class="show-more">
                  <el-button type="text" @click="goMoreProduct">查询更多</el-button>
                </div>

              </template>
              <template v-else>
                <el-empty description="没有数据" style="margin:auto"></el-empty>
              </template>

            </ul>
          </div>
          <div style="width:calc(100% - 232px);margin:4px auto 0;height:100%">
            <el-carousel height="452px" :interval="5000" arrow="always">
              <el-carousel-item v-for="(item,index) in imgList" :key="index" style="height:452px">
                <el-image :src="item.imgUrl" style="width: 100%; height: 100%" @click="bannerBtn(item)"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- <div class="login-info">
            <div class="login-info-top">
              <img class="login-info-top-img" :src="user.avatarUrl || avatarImg" />
              <div>{{user.mobile || ''}}</div>
            </div>
            <div class="module-list1">
              <div class="module-list1-item1" >
                余额: <div>0</div>
              </div>
            </div>
            <div class="module-list">
              <div class="module-list-item" @click="goList('order')">
                <div><i class="iconfont icon-dingdanguanli"></i></div>
                订单管理
              </div>
              <div class="module-list-item" @click="goList('addr')">
                <div><i class="iconfont icon-dizhiguanli"></i></div>
                地址管理
              </div>
              <div class="module-list-item" @click="goList('zzrz')">
                <div><i class="iconfont icon-zizhirenzheng"></i></div>
                资质认证
              </div>
              <div class="module-list-item" @click="goJianjie()">
                <div><i class="iconfont icon-gongsijianjie"></i></div>
                公司简介
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 右侧导航 -->
      <div class="floor-wrap" :class="{'floor-wrap2': isShowNav}">
        <a v-for="(item1,index1) in personalList" :key="item1.id" class="anchor-item personal-style" :class="{'active': activeValue == item1.id}" @click="goList(item1.path)">
            <span class="active-span">{{ item1.title }}</span>
        </a>
        <template v-if="isShowNav">
          <a v-for="(item,index) in stepNav" :key="item.id" :href="item.href" class="anchor-item " :class="{'active': activeValue == item.id}" @click.native="jump(index)">
            <span class="active-span">{{ item.title }}</span>
          </a>
          <div class="top" @click="goTop">
              <span class="iconfont icon-fanhuidingbu"></span>
          </div>
        </template>
        
      </div>
      <!-- 右侧领券入口 -->
      <div class="floor-wrap floor-wrap1" @click="huiImgBtn">
        <el-image class="hui-img" :src="huiImg"></el-image>
      </div>

      <!-- 优惠券 -->
      <!-- <div>
        <el-image :src="yhqImg"></el-image>
      </div> -->
      <!-- 招商电话 -->
      <div  class="zsdh"><span class="anima">招商电话：</span>崔合林 15903833377</div>
      <!-- 全部商品 -->
      <template v-if="dataAllLists.length">
        <div class="module-classify" id="ALL">
          <div class="headline">
            <div class="headline-value"><span class="iconfont icon-quanbushangpin"></span>全部商品</div>
            <div class="more-btns" @click="goPage('all')">更多<i class="el-icon-arrow-right"></i></div>
          </div>
          <ul class="list-module ">
            <li v-for="(item,index) in dataAllLists" :key="index" class="list-module-item" @click="goDetail(item.id)">
              <div style="height: 150px;">
                <img :src="item.picUrl" class="images"/>
              </div>
              <div class="title">{{ item.name }}</div>
              <div class="shop-footer shop-footer1">
                <div class="sub-price" v-if="$store.state.user.auditStatus == 2 || item.type == 1">￥<span style="font-size: 16px;">{{ item.price | currency}}</span></div>
                <div class="sub-price" style="font-size: 16px;" v-else>认证可见</div>
                <div>销量：{{ item.sales }}</div>
              </div>
            </li>
          </ul>
        </div>
      </template>

      <!-- 每日热销 -->
      <template v-if="todayNewProduct.length">
       <div class="every-day-hot" id="EVERYDAYHOT">
          <div class="everyHot" >
            <div class="everyHot-title">
              <span class="iconfont icon-huangguan"></span>每日热销
            </div>
            <div class="module-classify hot-style" >
              <!-- <div class="hot-module">
                <div class="hot-icon">每日热销</div>
              </div> -->
              <!-- <ul class="list-module ">
                <li v-for="(item,index) in recommendProduct" :key="index" class="list-module-item" @click="goDetail(item.id)">
                  <div style="height: 150px;">
                    <img :src="item.picUrl" class="images"/>
                  </div>
                  <div class="title">{{ item.name }}</div>
                  <div class="shop-footer shop-footer1">
                    <div class="sub-price">￥<span style="font-size: 16px;">{{ item.price | currency}}</span></div>
                    <div>销量：{{ item.sales }}</div>
                  </div>
                </li>
              </ul> -->
              <div class="hot-shop">
                <ul class="hot-shop-list">
                  <li v-for="(item,index) in todayNewProduct" class="hot-shop-list-item" :key="index" @click="goDetail(item.id)">
                    <div class="hot-img-style">
                      <img :src="item.picUrl" class="shop-images"/>
                    </div>
                    <div class="title ">{{ item.name }}</div>
                    <div class="shop-footer">
                      <div class="sub-price" v-if=" $store.state.user.auditStatus == 2 || item.type == 1">￥<span style="font-size: 16px;">{{ item.price | currency}}</span></div>
                      <div v-else class="sub-price" style="font-size: 16px;">认证可见</div>
                      <div>销量：{{ item.sales }}</div>
                    </div>
                  </li>
                </ul>
                
                <!-- <div class="swiper hot-shop-list">
                  <div class="swiper-wrapper ">
                    <div class="swiper-slide hot-shop-list-item" v-for="(item, index) in todayNewProduct" :key="index"  @click="goDetail(item.id)">
                      <div class="hot-img-style">
                        <img src="https://www.boyaojt.com/minio-api/byft-imgage/2022-11-04-1233e839051b42a0abaa8e8a8c66615a.jpg" class="shop-images"/>
                      </div>
                      <div class="title ">{{ item.name }}</div>
                      <div class="shop-footer">
                        <div class="sub-price">￥<span style="font-size: 16px;">{{ item.price | currency}}</span></div>
                        <div>销量：{{ item.sales }}</div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="line-hot"></div> -->
              </div>
            </div>
        </div>
       </div>
      </template>


      <template v-if="recommendProduct.length">
        <div class="module-classify" id="TUIJIAN">
          <div class="headline">
            <div class="headline-value"><span class="iconfont icon-tuijian1"></span>推荐商品</div>
            <div class="more-btns" @click="goPage('tj')">更多<i class="el-icon-arrow-right"></i></div>
          </div>
          <ul class="list-module ">
            <li v-for="(item,index) in recommendProduct" :key="index" class="list-module-item" @click="goDetail(item.id)">
              <div style="height: 150px;">
                <img :src="item.picUrl" class="images"/>
              </div>
              <div class="title">{{ item.name }}</div>
              <div class="shop-footer shop-footer1">
                <div class="sub-price" v-if="$store.state.user.auditStatus == 2 || item.type == 1">￥<span style="font-size: 16px;">{{ item.price | currency}}</span></div>
                <div class="sub-price" style="font-size: 16px;" v-else>认证可见</div>
                <div>销量：{{ item.sales }}</div>
              </div>
            </li>
          </ul>
        </div>
      </template>

      <template >
        <div class="module-classify" id="HOT">
          <div class="headline">
            <div class="headline-value"><span class="iconfont icon-hothuorererexiao"></span>热销商品</div>
            <div class="more-btns" @click="goPage('hot')">更多<i class="el-icon-arrow-right"></i></div>
          </div>
          <ul class="list-module">
            
            <li v-for="(item,index) in hotSellProduct" :key="index" class="list-module-item" @click="goDetail(item.id)" v-if="index < 10">
              <div style="height: 150px;">
                <img :src="item.picUrl" class="images"/>
              </div>
              <div class="title">{{ item.name }}</div>
              <!-- <div class="sub-price"><span style="font-size: 12px;">￥</span>{{ isLogin ? (item.price | currency) : '登录可见'}}</div> -->
              <div class="shop-footer shop-footer1">
                <div class="sub-price" v-if="$store.state.user.auditStatus == 2 || item.type == 1">￥<span style="font-size: 16px;">{{ item.price | currency}}</span></div>
                <div class="sub-price" style="font-size: 16px;" v-else>认证可见</div>
                <div>销量：{{ item.sales }}</div>
              </div>
            </li>
          </ul>
        </div>
      </template>

      <template >
        <div class="module-classify" id="HGC">
          <div class="headline">
            <div class="headline-value"><span class="iconfont icon-huacha"></span>花罐茶</div>
            <div class="more-btns" @click="goPage({id:'32'})">更多<i class="el-icon-arrow-right"></i></div>
          </div>
          <ul class="list-module">
            
            <li v-for="(item,index) in dataHgcLists" :key="index" class="list-module-item" @click="goDetail(item.id)" v-if="index < 10">
              <div style="height: 150px;">
                <img :src="item.picUrl" class="images"/>
              </div>
              <div class="title">{{ item.name }}</div>
              <!-- <div class="sub-price"><span style="font-size: 12px;">￥</span>{{ isLogin ? (item.price | currency) : '登录可见'}}</div> -->
              <div class="shop-footer shop-footer1">
                <div class="sub-price" v-if="$store.state.user.auditStatus == 2 || item.type == 1">￥<span style="font-size: 16px;">{{ item.price | currency}}</span></div>
                <div class="sub-price" style="font-size: 16px;" v-else>认证可见</div>
                <div>销量：{{ item.sales }}</div>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </div>

    <!-- 领取优惠券 -->
    <coupon-box :visible.sync="visibleCoupon"></coupon-box>
  </div>
</template>

<script>
// import {checkIsLogin, getUserInfo} from '@/utils/loginUser'
import {getBanner,categories,spuList,recommended,hotSell,hotSellByDay} from '@/api'
import Swiper from 'swiper';
import "swiper/swiper-bundle.min.css";
import {mapActions} from "vuex"

import CouponBox from './components/couponBox.vue'
import { localStorage } from '@/utils/storage';
export default {
  components:{CouponBox},
  name: 'home',
  data() {
    return {
      // yhqImg:require('@/assets/yhq1.png') ,
      userInfo: {
        loginName: ''
      },
      isLogin: false,
      imgList: [],
      activeIndex: null,
      dataList: [],
      categoryListData: [],
      todayNewProduct: [],
      isHaveNewPro: false,
      recommendProduct: [],
      isHaverecommendProduct: false,
      hotSellProduct:[],
      hotServer: [1, 2, 3, 4],
      visibleDetail: false,
      pathConfig: {
        'cart': '/shopCart',
        'addr': '/address',
        'order': '/order',
        'zzrz': '/qualification'
      },
      hotImg:require("@/assets/mrhot.jpg"),
      isShowNav:false,
      activeValue:'',
      stepNav:[
        {
          id:1,
          href:'#ALL',
          title:'全部商品'
        },
        {
          id:2,
          href:'#EVERYDAYHOT',
          title:'每日热销'
        },
        {
          id:3,
          href:'#TUIJIAN',
          title:'推荐商品'
        },
        {
          id:4,
          href:'#HOT',
          title:'热销商品'
        },
        {
          id:5,
          href:'#HGC',
          title:'花罐茶'
        }
      ],
      personalList:[
      {
          id:6,
          path:'zzrz',
          title:'资质认证'
        },
        {
          id:4,
          path:'order',
          title:'订单管理'
        },
        {
          id:8,
          path:'quan',
          title:'优惠券'
        },
        {
          id:5,
          path:'addr',
          title:'地址管理'
        },
        {
          id:7,
          path:'cart',
          title:'购物车'
        }
      ],
      user:'',
      avatarImg:require('@/assets/missing-face.png'),
      imgurl:require('@/assets/mrhot.png'),
      huiImg: require('@/assets/hui.png'),
      visibleCoupon:false,
      dataAllLists:[],
      dataHgcLists:[]
    }
  },
  // watch:{
  //   'document.body.scrollTop'(){
  //     let scrollTop = document.body.scrollTop
  //     let height = window.screen.height 
  //     console.log(scrollTop > (height/3)*2,'scrollTop > (height/3)*2')
  //     this.isShowNav = scrollTop > (height/3)*2 ? true : false
  //   }
  // },
  mounted() {
    
    new Swiper('.swiper',{
      direction:'',
      loop:true,
      autoplay:{
        delay:200,
        disableOnInteraction:true
      },
    })
    this.getBanner();
    this.categories();
    this.recommended();
    this.hotSell();
    this.hotSellByDay();
    this.dataAllList();
    this.getHGCList()
    // this.$store.getUserInfo()
    this.$nextTick(function(){
      window.addEventListener('scroll',this.onScroll)
    })
    
    if(this.$store.state.token){
      this.getUserInfo1()
    }
    
    // 
  },
  methods: {
    ...mapActions(['getUserInfo']),
    getUserInfo1(){
      console.log('kkkkkkkk')
      this.getUserInfo().then(res=>{
        if(res.code === '00000'){
          this.user = res.data
        }
        
      })
    },
    goJianjie(){
      window.open('https://www.boyaoft.com','_blank')
    },
    goTop(){
      document.body.scrollTop = document.documentElement.scrollTop = 0 
    },
    jump (index) {
        // 用 class="d_jump" 添加锚点
        let jump = document.querySelectorAll('.module-classify')
        let total = jump[index].offsetTop
        let distance = document.documentElement.scrollTop || document.body.scrollTop
        // 平滑滚动，时长500ms，每10ms一跳，共50跳
        let step = total / 50
        if (total > distance) {
          smoothDown()
        } else {
          let newTotal = distance - total
          step = newTotal / 50
          smoothUp()
        }
        function smoothDown () {
          if (distance < total) {
            distance += step
            document.body.scrollTop = distance
            document.documentElement.scrollTop = distance
            setTimeout(smoothDown, 2000)
          } else {
            document.body.scrollTop = total
            document.documentElement.scrollTop = total
          }
        }
        function smoothUp () {
          if (distance > total) {
            distance -= step
            document.body.scrollTop = distance
            document.documentElement.scrollTop = distance
            setTimeout(smoothUp, 2000)
          } else {
            document.body.scrollTop = total
            document.documentElement.scrollTop = total
          }
       } 
     },
    onScroll(){
      let scrolled = document.documentElement.scrollTop || document.body.scrollTop
      console.log(scrolled,'s')
       // 586、1063分别为第二个和第三个锚点对应的距离 500  800 1200
      if (scrolled >= 1200) {
        this.activeValue = 3
      } else if (scrolled < 1200 && scrolled >= 800) {
        this.activeValue = 2
      } else if (scrolled < 800 && scrolled >= 500) {
        this.activeValue = 1
      } else {
        this.activeValue = ''
      }
      this.isShowNav = scrolled < 500 ? false : true
    },
    // 获取轮播图
    getBanner(){
      getBanner().then(res=>{
        if(res.code === '00000'){
          this.imgList = res.data.list || []
        }
      })
    },
    bannerBtn(val){
      window.open(val.url,'_blank')
    },

    // 获取商品分类
    categories(){
      categories().then(res=>{
        if(res.code === '00000'){
          this.categoryListData = res.data || []
        }
      })
    },

    // 获取商品列表
    spuList(param,type){
      spuList(param).then(res=>{
        if(res.code === '00000'){
          // 花罐茶
          if(type && type === 'hgc'){
            this.dataHgcLists = res.data.list ? res.data.list.list : []
            return
          }
          // 全部商品
          if(type && type === 'all'){
            this.dataAllLists = res.data.list ? res.data.list.list : []
            return
          }
          // 分类商品
          this.dataList = res.data.list ? res.data.list.list : []
        }
      })
    },

    // 推荐商品
    recommended(){
      recommended().then(res=>{
          this.recommendProduct = res.data || []
      })
    },

    // 热销商品
    hotSell(){
      hotSell().then(res=>{
          this.hotSellProduct = res.data || []
      })
    },

    // 每日热销
    hotSellByDay(){
      hotSellByDay().then(res=>{
          this.todayNewProduct = res.data || []
      })
    },
    getRecommendProduct() {
      let that = this;
        

    },

    
   
  
    mouseenterbtn(index) {
     
      this.activeIndex = index
     
      let category = this.categoryListData[index] || {}
      let param = {
        page: 1,
        pageSize: 30,
        categoryId: category.id
      }
      this.spuList(param)
    }
    ,
    mouseleaveBtn() {
      this.activeIndex = null
      this.dataList = []
    }
    ,
    goDetail(productId) {
      // if(!this.isLogin){
      //   this.showLogin()
      //   return
      // }
      this.$router.push({
        path: '/product?productId=' + productId
      })
    }
    ,

    goMoreProduct() {
      let categoryId = this.categoryListData[this.activeIndex].id;
      this.$router.push({
        path: `/productList?id=${categoryId}`
      })
    },
    goList(type) {
      console.log(this.$store,'this.$store')
      if( !this.$store.state.user.id ){
        // this.$message.warning(this.$store.state.user.auditStatus == 0 ? '请先资质认证':'请先登录')
        this.$message.warning('请先登录')
        return
      }
      if(type == 'quan'){
        this.$router.push({
          path:'/coupon'
        })
        return
      }
      console.log('llllllllll')
      // if(!this.isLogin){
      //     this.showLogin()
      //     return
      // }
      this.$router.push({
        path: this.pathConfig[type]
      })
    }
    ,
    showLogin() {
      this.visibleDetail = true
    },

    goPage(item){
      // this.$router.push({
      //   path: `/ysty?id=${item.id ? item.id : ''}`
      // })
      // return
      console.log(item,'kkk')
      if(item == 'tj' || item == 'hot'){
        this.$router.push({
          path: `/productList?type=${item}`
        })
        return false
      }
      this.$router.push({
        path: `/productList?id=${item.id ? item.id : ''}`
      })
    },

    huiImgBtn(){
      if(localStorage.get('userInfo') && localStorage.get('userInfo').memberId){
        this.visibleCoupon = true
      }else{
        this.$message.warning('请先登录！')
      }
      
    },

    // 获取全部商品
    dataAllList(){
      let params = {
        page: 1,
        pageSize: 10,
        keyword: ''
      }
      this.spuList(params,'all')
      
    },

    // 获取花罐茶列表
    getHGCList(){
      let params = {
        page: 1,
        pageSize: 10,
        categoryId: 32
      }
      this.spuList(params,'hgc')
    }
    
  },
  filters:{
    currency(value){
      if(value){
        return value / 100
      }else return '0.00'
      
    }
  }
}
</script>

<style lang="less" scoped>
.home-module{
  background-image: url('@/assets/bg.jpg');
  background-attachment: fixed;
  width: 100%;
  height: auto;
  background-size: 100%; 
}
.home {
  max-width: 1190px;
  margin: auto;
  padding-bottom: 50px;
 
  
  .container {
    position: relative;
    width: 100%;
    height: 456px;

    .nav-module {
      display: flex;
      width: 1190px;
      height: 100%;
      justify-content: space-between;
      margin: 0 auto;

      .group-list {
        position: relative;
        height: 100%;
        background-color: #fff;

        .classify {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 224px;
          max-height: 456px;
          z-index: 2;
          color: #666;
          background-color: #f8f8f8;
          overflow-y: auto;
          
          &-item {
            position: relative;
            font-size: 15px;
            color: #0f0f0f;
            padding: 10px 20px;
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            border-bottom: 1px dashed transparent;
            background-color: #fff;
            box-sizing: border-box;

            &-top {
              display: flex;
              align-items: center;

              .classify-label {
                flex: 1;
                font-weight: 700;
                cursor: pointer;
              }

            }

            .line {
              width: 80%;
              position: absolute;
              bottom: -1px;
              border-bottom: 1px dashed #e2e2e2;
            }

          }

          &-item:hover {
            color: #fa436a;
            // background-color: rgba(20, 99, 158, .1);
            // background-color: rgba(217, 60, 93, 0.1);

            .classify-one {
              display: flex;
            }
          }

          .active-class {
            color: #fa436a ;
            // background-color: rgba(217, 60, 93, 0.72);
          }
        }

        .classify-one {
          width: 930px;
          position: absolute;
          left: 224px;
          top: 0px;
          bottom: 0;
          background-color: #fff;
          border-left: none;
          z-index: 11;
          padding: 8px 16px 0 16px;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          align-content: flex-start;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          box-shadow: inset 2px 0 5px #d8d8d8;

          &-item {
            color: #666;
            font-size: 14px;
            border-bottom: 1px;

            div {
              cursor: pointer;
            }
          }

          &-item:hover {
            color: #fa436a;
          }
          .show-more{
            position:absolute;
            right:24px;
            bottom:26px;
            font-size:12px;
          }
        }

      }

      .login-info {
        width: 200px;
        text-align: center;
        background-color: #fff;
        &-top{
          // display: flex;
          &-img{
            width: 50px;
            margin-top: 30px;
            margin-bottom: 10px;
          }
         
        }
        .head-img {
          width: 60px;
          height: 60px;
          margin: 12px 0 10px;
        }

        .btns {
          margin-top: 20px;
        }

        .module-list {
          display: flex;
          align-items: center;
          margin-top: 20px;
          border-top: 1px dashed #ddd;
          padding: 20px;
          flex-wrap: wrap;

          &-item {
            font-size: 14px;
            width: 49%;
            cursor: pointer;
            margin-bottom: 20px;
            .icon-cart {
              font-size: 22px;
            }

            .icon-order {
              font-size: 20px;
              margin-bottom: 2px;
            }
          }

          &-item:nth-child(2n+1) {
            border-right: 1px solid #ddd;
          }

          &-item:hover {
            color: #fa436a;
          }

        
        }
        .module-list1{
          display: flex;
          width: 80%;
          align-items: center;
          font-size: 14px;
          padding-top: 20px;
          margin-left: 10%;
          &-item1{
            border-right: 1px solid #eee;
            box-sizing: border-box;
            div{
              color: #0716eb;
              font-weight: bold;
              font-size: 16px;
              display: inline-block;
              margin-left: 10px;
            }
          }
          &-item1:last-child{
            border-right: none;
          }
        }
      }
    }
  }
  .floor-wrap {
    position: fixed;
    top: 60%;
    transform: translateY(-180px);
    right: calc(50% - 688px);
    z-index: 11;
    white-space: nowrap;
    .anchor-item{
      display: block;
      width: 64px;
      // height: 64px;
      background-color: #fff;
      color: #666;
      text-align: center;
      span{
        display: inline-block;
        white-space: normal;
        width: 28px;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        word-break: break-all;
        border-bottom: 1px solid #dbdbdb;
        font-size: 12px;
        box-sizing: border-box;
      }
    }
    .personal-style{
      cursor: pointer;
    }
    .active{
      color: #fa436a;
    }
    .anchor-item:hover{
      color: #fa436a;
    }
    .top{
      background: #fff;
      text-align: center;
      font-size: 12px;
      color: #fa436a;
      cursor: pointer;
      padding: 16px 17px 16px;
      .icon-fanhuidingbu{
        font-size: 26px;
      }
      
    }
  }
  .floor-wrap1{
    transform: translateY(276px);
    right: calc(50% - 800px);
    .hui-img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .floor-wrap2{
    top: 40%;
  }
  .every-day-hot{
    background:
            linear-gradient(90deg, #f8da2d, #fa436a, #fc7d7d) repeat-x,
            linear-gradient(90deg, #f8da2d, #fa436a, #fc7d7d) repeat-x,
            linear-gradient(0deg, #f8da2d, #fa436a, #fc7d7d)  repeat-y,
            linear-gradient(0deg, #f8da2d, #fa436a, #fc7d7d)  repeat-y;
          background-size: 100% 3px, 100% 3px, 3px 100%, 3px 100%;
          background-position: 0 0, 0 100%, 0 0, 100% 0;
        animation: linearGradientMove1 3s infinite linear;
        margin-top: 30px;
        padding: 6px;
        background-color: #fff;
        border-radius: 4px;
    .everyHot{
      background: linear-gradient(to right, #ffac30, #fa436a, #f56c6c);
      
      padding-bottom: 20px;
      border-radius: 8px;
      &-title{
        text-align: center;
        padding-top: 20px;
        color: #fff;
        font-size: 26px;
        .icon-huangguan{
          color: #ffac30;
          font-size: 30px;
          margin-right: 10px;
          display: inline-block;
        }
      }
    }
    @keyframes linearGradientMove1 {
          100% {
              background-position: 1182px 0, -520px 100%, 0 -1182px, 100% 520px;
          }
      }
  }
  
  .module-classify {
    margin-top: 30px;

    .headline {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-value {
        font-size: 26px;
        font-weight: bold;
        padding: 4px;
      }
      .icon-tuijian1,.icon-hothuorererexiao,.icon-quanbushangpin,.icon-huacha{
        color: red;
        font-size: 28px;
        margin-right: 6px;
      }
      .icon-hothuorererexiao{
        font-size: 30px;
      }
      .icon-huacha{
        font-size: 32px;
      }
      .icon-quanbushangpin{
        font-size: 24px;
      }
      &-value2 {
        background: linear-gradient(to right, rgba(238, 238, 238, 0), rgba(222, 127, 225, 0.3), rgb(116, 108, 225), rgb(116, 108, 225), rgba(222, 127, 225, 0.3), rgba(238, 238, 238, 0));
      }

      .more-btns{
        cursor: pointer;
      }

    }

    .list-module {
      display: flex;
      margin-top: 12px;
      justify-content: space-between;
      flex-flow: row wrap;

      &-img {
        // margin-right: 10px;

        img {
          display: block;
          border-radius: 8px;

        }
      }

      &-item {
        height: 250px;
        background-color: #fff;
        position: relative;
        text-align: center;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        flex: 0 0 19%;
        margin: 0 0px 8px 0px;

        .images {
          height: 110px;
          margin: auto;
          margin-top: 20px;
        }
        .images:hover{
          animation: weidong .4s linear forwards;
        }
       
        .title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 10px;
        }
       

        .sub-tit {
          color: #999
        }

       
      }
      &-item:hover{
        // box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
        margin-top: -4px;
        border-radius: 4px;
        background:
            linear-gradient(90deg, #f8da2d, #fa436a, #fc7d7d) repeat-x,
            linear-gradient(90deg, #f8da2d, #fa436a, #fc7d7d) repeat-x,
            linear-gradient(0deg, #f8da2d, #fa436a, #fc7d7d)  repeat-y,
            linear-gradient(0deg, #f8da2d, #fa436a, #fc7d7d)  repeat-y;
          background-size: 100% 2px, 100% 2px, 2px 100%, 2px 100%;
          background-position: 0 0, 0 100%, 0 0, 100% 0;
          background-color: #fff;
        animation: linearGradientMove 2s infinite linear;
       
      }
      @keyframes linearGradientMove {
          100% {
              background-position: 226px 0, -250px 100%, 0 -226px, 100% 250px;
          }
      }
      
    }
  }

  .module-classify:last-child{
    margin-bottom: 100px;
  }
  
 
  .sub-price {
          color: red;
        }
    .shop-footer{
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      padding: 0 20px 0 40px;
      margin-top: 10px;
    }
    .shop-footer1{
      margin-top: 40px;
    }
  .hot-style{
    display: flex;
    justify-content: center;
    margin-bottom: 0 !important;
    // .hot-module{
    //   background-image: url(../../assets/mrhot.jpg);
    //   height: 254px;
    //   display: inline-block;
    //   width: 180px;
    //   background-size: 100%;
    //   .hot-icon{
    //     font-size: 26px;
    //     text-align: center;
    //     margin-top: 60px;
    //     color: #fff;
    //   }
    // }
    .hot-shop{
      width: calc(100% - 100px);
      padding: 0px 10px 0 0;
      overflow: hidden;
      position: relative;
      &-list{
        display: flex;
        background-color: #fff;
        // height: 234px;
        flex-wrap: wrap;
        &-item{
          width:20%;
          // text-align: center;
          padding-bottom: 20px;
          cursor: pointer;
        }
        .hot-img-style{
          height: 110px;
          text-align: center;
          padding-top: 25px;
          .shop-images{
            height: 100px;
            margin: auto;
            
          }
         
        }
        .title{
          font-size: 14px;
          padding-left: 40px;
        }
        &-item:hover{
            animation: weidong .4s linear forwards;
        }
      }
      .line-hot{
        // background: linear-gradient(to right, rgb(207, 10, 2),#edeeef);
        background: linear-gradient(to right, #cf0a02, rgba(225,232,242,0));
        width: 40%;
        height: 20px;
        position: absolute;
        bottom: 0;
      }
    }
    
  }

}
@keyframes weidong{
    0%{transform:scaleX(1)}
    50%{transform:scale3d(1.05,1.05,1.05)}
    to{transform:scale3d(1.08,1.08,1.08)}
  }

.icon-dingdanguanli{
  font-size: 24px;
}
.icon-dizhiguanli{
  font-size: 23px;
}
.icon-zizhirenzheng{
  font-size: 25px;
}
.icon-gongsijianjie{
  font-size: 24px;
}
.anima {
  display: inline-block;
  margin-right: 20px;
		animation-name: likes; // 动画名称
		animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
		animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
		animation-delay: 0s; // 动画延迟时间
		animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
		animation-duration: 3s; // 动画完成时间
	}

	@keyframes likes {
		 0%{
		      transform: scale(1.2);
		   }
		   25%{
		      transform: scale(0.95);
		   }
		   50%{
		      transform: scale(0.85);
		   }
		   75%{
		      transform: scale(0.95);
		   }
		   100%{
		      transform: scale(1.2);
		   }
  }
  .zsdh{
    font-size: 22px;
    border: 2px solid yellow;
    text-align: center;
    margin: 20px 30px;
    line-height: 50px;
    border-radius: 25px;
    color: #fff;
    font-weight: bold;
    background: linear-gradient(0, rgba(254, 49, 50, 0.8), #ffa665);
    width: 410px;
  }
</style>
